import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadableContext, RootState } from "store/store";
import { SensitizationAddresses, ReportStats, ReportExportData } from "models/Missions";
import { aggregateAwarenessCampaignStats } from "helpers/stats";

const missionAddressesAdapter = createEntityAdapter<SensitizationAddresses>({
    selectId: address => address.ID,
    sortComparer: (a, b) => {
        const keyA = a.ID || "";
        const keyB = b.ID || "";
        return keyA.localeCompare(keyB);
    },
});

interface MissionAddressState extends LoadableContext {
    aggregatedStats: ReportStats | null;
    selectedRowsIds: string[],
    selectedSortingPointsIds: string[],
    reportsDialogOpen: boolean,
}

const initialState: MissionAddressState = {
    aggregatedStats: null,
    loading: false,
    error: null,
    selectedRowsIds: [],
    selectedSortingPointsIds: [],
    reportsDialogOpen: false,
};

export const missionAddressesSlice = createSlice({
    name: 'mission_addresses',
    initialState: missionAddressesAdapter.getInitialState(initialState),
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
            state.error = null;
        },
        setSelectedRowsIds: (state, action: PayloadAction<string[]>) => {
            state.selectedRowsIds = action.payload;
        },
        clearSelectedRowsIds: (state) => {
            state.selectedRowsIds = [];
        },
        setSelectedSortingPointIds: (state, action: PayloadAction<string[]>) => {
            state.selectedSortingPointsIds = action.payload;
        },
        clearSelectedSortingPointIds: (state) => {
            state.selectedSortingPointsIds = [];
        },
        setList: (state, { payload: addresses }: PayloadAction<SensitizationAddresses[]>) => {
            state.loading = true;
            missionAddressesAdapter.setAll(state, addresses);
            // Compute aggregated stats
            state.aggregatedStats = aggregateAwarenessCampaignStats(addresses);
            state.loading = false;
        },
        addItem: (state, { payload: address }: PayloadAction<SensitizationAddresses>) => {
            state.loading = false;
            missionAddressesAdapter.addOne(state, address);
        },
        addItems: (state, { payload: address }: PayloadAction<SensitizationAddresses[]>) => {
            state.loading = false;
            missionAddressesAdapter.addMany(state, address);
        },
        removeItem: (state, { payload: addressKey }: PayloadAction<string>) => {
            missionAddressesAdapter.removeOne(state, addressKey);
        },
        removeAllItems: (state) => {
            missionAddressesAdapter.removeAll(state);
        },
        updateItem: (state, { payload: { ID, data } }: PayloadAction<{ ID: string, data: Partial<SensitizationAddresses> }>) => {
            missionAddressesAdapter.updateOne(state, {
                id: ID,
                changes: data,
            });
        },
        updateItems: missionAddressesAdapter.updateMany,
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        updateAddress: (state, { payload: address }: PayloadAction<SensitizationAddresses>) => {
            if (state.entities[address.addressKey]) {
                // Address exists, remove it
                missionAddressesAdapter.removeOne(state, address.addressKey);
            } else {
                // Address does not exist, add it with default 'visited' status
                missionAddressesAdapter.addOne(state, { ...address, visited: false });
            }
        },
        openReportsDialog: (state) => {
            state.reportsDialogOpen = true;
        },
        closeReportsDialog: (state) => {
            state.reportsDialogOpen = false;
        },
    }
},);

export const MissionAddressesActions = {
    ...missionAddressesSlice.actions,
};

export const {
    selectAll: selectAllMissionAddresses,
    selectById: selectMissionAddressesById,
    selectIds: selectMissionAddressesIds
} = missionAddressesAdapter.getSelectors((state: RootState) => state.missions.missionAddresses);

// New selector for visited addresses with reports
export const selectVisitedAddressesWithReports = createSelector(
    [selectAllMissionAddresses],
    (addresses: SensitizationAddresses[]): SensitizationAddresses[] =>
        addresses.filter(address => address.visited === true)
);

// Memoized selector for report data with type safety
export const selectAllReportsData = createSelector(
    [selectVisitedAddressesWithReports],
    (visitedAddresses: SensitizationAddresses[]): ReportExportData[] =>
        visitedAddresses.map(address => ({
            ID: address.ID,
            houseNumber: address.address.houseNumber || '',
            street: address.address.street || '',
            city: address.address.city || '',
            visited: address.visited,
            assignedName: address.assigned?.name || '',
            assignedEmail: address.assigned?.email || '',
            inhabitantsSpoken: address.report?.inhabitantsSpoken ?? null,
            communicationMethods: address.report?.communicationMethods || [],
            selectedTopics: address.report?.selectedTopics || [],
            inhabitantsReceptive: address.report?.inhabitantsReceptive || '',
            otherComments: address.report?.otherComments || '',
            additionalInfo: address.report?.additionalInfo || '',
            imageURLs: address.report?.imageURLs || []
        }))
);

const MissionAddressesReducer = missionAddressesSlice.reducer;

export default MissionAddressesReducer;