import { Grid, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { BatchesActions } from "store/reducers/batches/list";
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import { useEffect } from "react";
import { Shortcut } from "store/reducers/preferences/validation_shortcuts";

export default function GoToNextBatch() {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.batches.list.selectedBatch!.loading);

    const key = useAppSelector(state => state.preferences.validation[Shortcut.GO_TO_NEXT_BATCH]);

    const goToNextBatch = () => {
        dispatch(BatchesActions.selectNextBatch());
    }

    /**
     * Bind the "Right arrow" key to go to previous batch
     */
    const handleKeydown = (e: KeyboardEvent) => {
        if (e.key === key) {
            if ((e.target as HTMLElement | null)?.tagName !== "INPUT") { // user not focused on an input
                goToNextBatch();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeydown);

        return () => window.removeEventListener('keydown', handleKeydown);
    }, [key]);

    return (
        <Grid
            item
            xs={2}
            display="flex"
            justifyContent="center"        
            >
            <IconButton
                onClick={goToNextBatch}
                color="secondary"
                disabled={loading}
            >
                <ChevronRightIcon />
            </IconButton>
        </Grid>
    )
}