import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector, useIsAdmin } from "hooks/hooks";
import { selectTruckById } from "store/reducers/trucks/list";
import CardListItem from "../CardListItem";
import urls from "constants/urls";
import Truck, { TruckStatus } from "models/Truck";
import { downloadDataAsFile } from "helpers/urls";

type TruckItemProps = {
    truckID: EntityId;
    onEditClicked: (truck: Truck) => void;
}

export default function TruckItem({ truckID, onEditClicked }: TruckItemProps) {

    const isAdmin = useIsAdmin();

    const truck = useAppSelector(state => selectTruckById(state, truckID)!);

    const downloadCredentials = () => {
        const dataStr = JSON.stringify({
            api_url: urls.API,
            token: truck.tokenID,
            functions_base_url: urls.HTTP_FUNCTIONS.baseURL,
        });
        downloadDataAsFile(new Blob([dataStr], { type: "text/json" }), "credentials.json");
    };

    return (
        <CardListItem
            primary={truck.displayID}
            secondary={truck.make}
            disabled={truck.status === TruckStatus.ARCHIVED}
            onEditClicked={() => onEditClicked(truck)}
            onDownloadClicked={isAdmin ? downloadCredentials : undefined}
        />
    );
}