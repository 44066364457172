import { Box } from '@mui/material';
import SectionLoader from 'components/_include/SectionLoader';
import { useAppSelector } from 'hooks/hooks';
import CollectionDetail from './CollectionDetail';

function CollectionDetailWrapper() {
    const collection = useAppSelector(state => state.collections.selected.data);
    const collectionLoading = useAppSelector(state => state.collections.selected.loading);

    return (
        <Box
            position="relative"
            minHeight={theme => theme.spacing(38)}
            >
            {collectionLoading && ( // selected collection data loading
                <SectionLoader />
            )}
            {collection && ( // collection selected and loaded
                <CollectionDetail />
            )}
        </Box>
    );
}

export default CollectionDetailWrapper;