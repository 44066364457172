import { ButtonBase, Typography } from "@mui/material";

type SwitchLabelProps = {
    label: string;
    color: string;
    selected: boolean;
    onClick: () => void;
}

export default function SwitchLabel({ label, color, selected, onClick }: SwitchLabelProps) {
    return (
        <ButtonBase 
            onClick={onClick} 
            disableRipple
            >
            <Typography
                variant="h6"
                color={color}
                sx={{
                    opacity: selected ? 1 : 0.6,
                }}
            >
                {label}
            </Typography>
        </ButtonBase>
    )
}