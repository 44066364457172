import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import MissionsMethods from 'controllers/mission';
import { Timestamp } from 'firebase/firestore';
import { Namespace } from 'locales/translations';
import { MissionDbData, MissionStatus } from 'models/Missions';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'react-rainbow-components';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { usePartnerID } from 'hooks/partners';
import { showSuccess } from 'store/reducers/snacks';

type CreateMissionModalProps = {
    open: boolean;
    onClose: () => void;
};

const CreateMissionModal: React.FC<CreateMissionModalProps> = ({ open, onClose }) => {
    const [name, setName] = useState<string>('');
    const [deadline, setDeadline] = useState<moment.Moment | undefined>();
    const dispatch = useAppDispatch();
    const partnerID = usePartnerID();
    const validForm = name && deadline && partnerID;
    const saving = useAppSelector(state => state.missions.selectedMission.loading);

    const { t } = useTranslation([Namespace.SNACKS, Namespace.MISSIONS, Namespace.ACTIONS, Namespace.CONTACTS]);

    const handleSave = useCallback(async () => {
        if (!validForm) return;

        const missiondata: MissionDbData = {
            ID: '',
            name: name,
            createdAt: Timestamp.now(),
            deadline: Timestamp.fromDate(deadline.toDate()),
            assigned: {},
            status: MissionStatus.PENDING,
            addressesCount: 0,
            addressesVisitedCount: 0,
        };

        const mission = await dispatch(MissionsMethods.create(missiondata, partnerID));

        if (mission) {
            dispatch(showSuccess(t("missions.create.success", { ns: Namespace.SNACKS, name: mission.name })));
            onClose();
        }
    }, [validForm, name, deadline, dispatch, partnerID, onClose, t]);

    const handleDateChange = useCallback((value: Date) => {
        setDeadline(moment(value));
    }, []);

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    return (
        <Dialog
            open={open}
            >
            <DialogTitle align='center'>
                {t("create_new_mission", { ns: Namespace.MISSIONS })}
            </DialogTitle>

            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        minWidth: "50%",
                        maxWidth: 400,
                        textAlign: 'center',
                        py: 1,
                    }}
                >
                    <TextField
                        label={t("name", { ns: Namespace.CONTACTS })}
                        variant="outlined"
                        fullWidth
                        sx={{ mb: 2 }}
                        value={name}
                        onChange={handleNameChange}
                    />

                    <DatePicker
                        minDate={new Date()}
                        disabled={false}
                        placeholder={t("select_dates.single", { ns: Namespace.ACTIONS })}
                        formatStyle="large"
                        locale="fr"
                        value={deadline?.toDate()}
                        onChange={(value: Date) => handleDateChange(value)}
                        className="datepicker-single"
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="text"
                    color='secondary'
                    sx={{ mr: 2 }}
                    onClick={onClose}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <LoadingButton
                    variant="text"
                    color="success"
                    disabled={!validForm}
                    onClick={handleSave}
                    loading={saving}
                >
                    {t("save", { ns: Namespace.ACTIONS })}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default CreateMissionModal;
