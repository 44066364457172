import { ClickAwayListener, Tooltip, TooltipProps } from "@mui/material";
import { Namespace } from "locales/translations";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/hooks";

type ShortcutTooltipProps = Pick<TooltipProps, "open" | "children"> & {
    onClose: () => void;
};

export default function CustomizeShortcutTooltip({ open, onClose, children }: ShortcutTooltipProps) {
    const { t } = useTranslation([Namespace.PREFERENCES]);

    const error = useAppSelector(state => state.preferences.validation.error);

    const handleClosing = (e: Event | SyntheticEvent<Element, Event>) => {
        if ("key" in e && e.key === "Escape") {
            // user may want to associate "Escape" key to a shortcut
            e.preventDefault();
        }
    };

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Tooltip
                arrow
                open={open}
                title={t(error ?? "recording", { ns: Namespace.PREFERENCES })}
                onClose={handleClosing}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                PopperProps={{
                    disablePortal: true,
                }}
            >
                {children}
            </Tooltip>
        </ClickAwayListener>
    )
}