import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import { SearchParam } from "constants/urls";

type VerifiedSelectProps = {
    parentLoading: boolean;
}

export enum VERIFIED_BATCHES {
    SHOW_BOTH = "0",
    HIDE_VERIFIED = "1",
    ONLY_VERIFIED = "2",
    ONLY_VERIFIED_BY_HUMAN = "3",
    ONLY_VERIFIED_BY_AI = "4",
};

function VerifiedSelect({ parentLoading }: VerifiedSelectProps) {
    const loading = parentLoading;

    const { t } = useTranslation([Namespace.FORMS]);

    const [searchParams, setSearchParams] = useSearchParams();

    const showVerified = searchParams.get(SearchParam.VERIFIED);

    const handleVerifiedSelected = (showVerified: string) => {
        searchParams.set(SearchParam.VERIFIED, showVerified);
        setSearchParams(searchParams);
    };

    const options: Option[] = Object.values(VERIFIED_BATCHES).map(value => ({
        label: t(`manage_batches.show_verified.${value}`, { ns: Namespace.FORMS }),
        value: value,
    }));

    return (
        <DropdownMenu
            id="verified-select-menu"
            title={t(`manage_batches.show_verified.title`, { ns: Namespace.FORMS })}
            loading={loading}
            values={options}
            defaultValue={showVerified || VERIFIED_BATCHES.SHOW_BOTH}
            startIcon={<HowToRegRoundedIcon />}
            onChange={(value) => handleVerifiedSelected(value)}
        />
    );
}

export default VerifiedSelect;