import { useEffect, useMemo, } from "react";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { AppDispatch, RootState } from "store/store";
import { SearchParam } from "constants/urls";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

type SelectProps<T> = {
    /** Id for the DropdownMenu. */
    id: string;
    /** Set to true to enfore loading of the select. */
    parentLoading: boolean;
    /** Selector to retrieve the loading status from the store. */
    loadingSelector: (state: RootState) => boolean;
    /** Selector to retrieve the available options from the store. */
    optionsSelector: (state: RootState) => T[];
    /** Selector to retrieve the selected option from the store. */
    valueSelector: (state: RootState) => T | null;
    /** Reducer method to call when the selected value changes. */
    selectValue: ((value: T | null) => (dispatch: AppDispatch) => void) | ActionCreatorWithPayload<T | null>;
    /** Method to format each item in order to display them as an option. */
    formatOptionLabel: (option: T) => string;
    /** Name of the URL search param to set the selected value. */
    searchParam: SearchParam;
    /** Hook to execute whenever its "deps" parameter changes. Use it to list the options. */
    onInit: {
        effect: () => void;
        deps: any[];
    },
    /** Icon for the DropdownMenu. */
    startIcon?: JSX.Element;
    /** Set to true to force selecting a value. If none is set, the 1st option will be selected. */
    needDefault?: boolean;
    /** Label for the option "Select all". */
    selectAllLabel: string;
}

/**
 * A select that can load its options from the Redux store, 
 * and update it when its value changes, as well as the URL params.
 */
export default function ReducerSelect<T extends { ID: string }>({ id, parentLoading, loadingSelector, needDefault, selectAllLabel, optionsSelector, valueSelector, selectValue, formatOptionLabel, onInit, searchParam, startIcon }: SelectProps<T>) {
    /** True to show a loading indicator on the select. */
    const loading = useAppSelector(loadingSelector) || parentLoading;
    
    /** List of options loaded from the reducer. */
    const selectorOptions = useAppSelector(optionsSelector);

    /** Selected value from the reducer. */
    const selectedValue = useAppSelector(valueSelector);

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.COMMONS]);

    const [searchParams, setSearchParams] = useSearchParams();

    /** Value retrieved from the URL params. */
    const selectedID = searchParams.get(searchParam);

    /** List options upon first load and when deps change. */
    useEffect(onInit.effect, onInit.deps);

    /** Select first option if default is needed */
    useEffect(() => {
        if (needDefault && !selectedID && selectorOptions.length > 0) { // set first option as selected
            handleOptionSelected(selectorOptions[0].ID);
        }
    }, [selectorOptions]);

    /** Select an option by default based on URL params. */
    useEffect(() => {
        if (selectorOptions.length > 0) {
            if (selectedID) { // there's an option to select from URL params
                const optionToSelect = selectorOptions.find(t => t.ID === selectedID);
                if (optionToSelect) { // select option          
                    dispatch(selectValue(optionToSelect));
                }
                else { // option to select doesn't exist anymore (e.g doesn't belong to newly selected partner)
                    handleOptionSelected("");
                }
            }
            else if (selectedValue) { // no option to select: unselect selected option
                dispatch(selectValue(null));
            }
        }
    }, [selectorOptions, selectedID]);

    /** Options for the dropdown menu. */
    const selectOptions: Option[] = useMemo(() => {
        let options: Option[] = [];

        if (needDefault) {
            if (!selectedValue) { // display loading until an option is selected
                options.push({
                    label: t("loading", { ns: Namespace.COMMONS }),
                    value: "",
                });
            }
        }
        else { // option to select all
            options.push({
                label: selectAllLabel,
                value: "",
            });
        }

        selectorOptions.forEach(option => { // add option for each possible value
            options.push({
                label: formatOptionLabel(option),
                value: option.ID,
            });
        });

        return options;
    }, [needDefault, selectedValue, selectorOptions]);

    /** Callback when a different option is selected. */
    const handleOptionSelected = (id: string) => {
        if (id) {
            searchParams.set(searchParam, id);
            setSearchParams(searchParams);
        }
        else {
            searchParams.delete(searchParam);
            setSearchParams(searchParams);
        }
    };

    const defaultValue = selectedID || selectOptions[0].value;
    const selectedOptionLabel = selectedValue ? formatOptionLabel(selectedValue) : selectOptions[0].label;
    const title = needDefault ? selectedOptionLabel : selectAllLabel;

    return (
        <DropdownMenu
            id={id}
            title={title}
            loading={loading}
            values={selectOptions}
            defaultValue={defaultValue}
            startIcon={startIcon}
            onChange={handleOptionSelected}
        />
    );
}