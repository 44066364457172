import { Collapse, Divider } from "@mui/material";
import { TrashType } from "constants/trash"
import { useAppSelector } from "hooks/hooks";
import BoundingBoxDetailsList from "./BoundingBoxDetailsList";
import WasteTypeDrawerHeader from "./WasteTypeDrawerHeader";

type DrawerProps = {
    wasteType: TrashType;
    canEditResults?: boolean;
}

/**
 * A single drawer for a class of waste.
 * It has an expandable header for the sum of all of the instances
 * and a nested item for each bounding box of this type of waste.
 */
export default function WasteTypeDrawer({ wasteType, canEditResults }: DrawerProps) {
    const open = useAppSelector(state => state.batches.wastesInputs[wasteType].open);

    return (
        <>
            <WasteTypeDrawerHeader 
                wasteType={wasteType}
                canEditResults={canEditResults}
                />
            <Collapse in={open} timeout="auto">
                <BoundingBoxDetailsList
                    wasteType={wasteType}
                    canEditResults={canEditResults}
                />
            </Collapse>
            <Divider component="li" />
        </>
    )
}