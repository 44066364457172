import { useAppDispatch } from "hooks/hooks";
import { usePartnerID } from 'hooks/partners';
import CollaboratorsController from "controllers/collaborators";
import Collaborator, { NewCollaborator } from "models/Collaborator";
import CollaboratorDialog from "../CollaboratorDialog";

type EditCollaboratorDialogProps = {
    open: boolean;
    onClose: () => void;
    collaborator: Collaborator;
}

export function EditCollaboratorDialog({ open, onClose, collaborator }: EditCollaboratorDialogProps) {
    const dispatch = useAppDispatch();
    const partnerID = usePartnerID();

    const handleSubmit = (data: Collaborator | NewCollaborator) => {
        if (!partnerID) return;
        dispatch(CollaboratorsController.update(partnerID, collaborator.ID, data))
            .then(() => {
                onClose();
            });
    };

    return (
        <CollaboratorDialog
            open={open}
            onClose={onClose}
            initialData={collaborator}
            onSubmit={handleSubmit}
        />
    );
}
