import { Box, Collapse } from '@mui/material';
import { ReactNode } from 'react';
import LessCommonWastesHeader from './LessCommonWastesHeader';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { MediaOverlayActions } from 'store/reducers/batches/media_overlay';

type DrawerProps = {
    children: ReactNode;
}

export default function LessCommonWastesDrawer(props: DrawerProps) {
    const expanded = useAppSelector(state => state.batches.mediaOverlay.lessCommonWastesDrawerOpen);

    const dispatch = useAppDispatch();

    const toggleExpanded = () => {
        dispatch(MediaOverlayActions.toggleLessCommonWastesDrawer(!expanded));
    }

    return (
        <Box
            gap={2}
            display="flex"
            flexDirection="column"
        >
            <LessCommonWastesHeader
                expanded={expanded}
                onClick={toggleExpanded}
            />

            <Collapse in={expanded} {...props} />
        </Box>
    );
}