import { Paper, Slide, Typography } from "@mui/material";
import { TRASH_TYPES_ICONS, TrashType } from "constants/trash";
import { Namespace } from "locales/translations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/hooks"
import { MediaOverlayActions } from "store/reducers/batches/media_overlay";

type IndicatorProps = {
    container: HTMLElement;
}

export default function DrawingBoundingBoxIndicator({ container }: IndicatorProps) {
    const { t } = useTranslation([Namespace.DIALOGS, Namespace.WASTES]);

    const [drawingType, setDrawingType] = useState(TrashType.RECYCLE_WASTE);
    
    const selectedType = useAppSelector(state => state.batches.mediaOverlay.selectedTrashType);

    useEffect(() => {
        if (selectedType) setDrawingType(selectedType);
    }, [selectedType]);

    const dispatch = useAppDispatch();

    const stopDrawing = () => {
        dispatch(MediaOverlayActions.selectTrashType(null));
    }

    return (
        <Slide
            in={Boolean(selectedType)}
            container={container}
            direction="left"
        >
            <Paper
                onClick={stopDrawing}
                sx={{
                    cursor: "pointer",
                    position: "absolute",
                    right: theme => theme.spacing(3),
                    top: theme => theme.spacing(3),
                    px: 2,
                    py: 1.5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography 
                    color="primary"
                    variant="h6"
                    >
                    {t("bounding_boxes.click_to_stop_drawing", { ns: Namespace.DIALOGS })}
                </Typography>
                <Typography>
                    {TRASH_TYPES_ICONS[drawingType]} {t(drawingType, { ns: Namespace.WASTES, context: "small" })}
                </Typography>
            </Paper>
        </Slide>
    )
}