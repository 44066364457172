import { Chip } from "@mui/material"

type ChipProps = {
    label: string;
    title?: string;
}

export default function CollaboratorPermissionChip({ label, title }: ChipProps) {
    return (
        <Chip
            key={label}
            label={label}
            size="small"
            variant="outlined"
            title={title}
            sx={{
                borderRadius: '10px',
                fontWeight: 'medium',
                padding: '4px 8px',
                borderWidth: '2px',
                color: 'grey.700',
                borderColor: 'grey.400',
                mb: '4px'
            }}
        />
    )
}