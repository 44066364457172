import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import { Locale, Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-data-grid/locales";
import { NoResultOverlay } from "./NoResultOverlay";
import DataGridToolbar, { DataGridToolbarProps } from "./DataGridToolbar";


type GridProps = DataGridProps & DataGridToolbarProps & {
    useDefaultStyling?: boolean;
};

const defaultStyling = {
    backgroundColor: '#ffffff',
    '& .MuiDataGrid-cell': {
        display: 'flex',
        alignItems: 'center',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell:focus-within': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell:focus-visible': {
        outline: 'none',
    },
    '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: 'inherit',
    },
    '& .MuiDataGrid-row.Mui-selected:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus-visible': {
        outline: 'none',
    },
    '& .MuiDataGrid-columnHeader.Mui-focusVisible': {
        backgroundColor: 'inherit',
    },
    '--DataGrid-overlayHeight': '300px',
};


export function FichaDataGrid({ disableQuickSearch, disableExport, slots, slotProps, sx,
    useDefaultStyling = true,
    disableColumnFilter = true,
    ...props }: GridProps) {

    const { t, i18n: { language } } = useTranslation([Namespace.ACTIONS, Namespace.COMMONS, Namespace.GLOSSARY]);
    const finalSx = useDefaultStyling
        ? { ...defaultStyling, ...(sx || {}) }
        : sx;

    return (
        <DataGrid
            sx={finalSx}
            // // Default props
            ignoreDiacritics
            disableRowSelectionOnClick
            disableColumnSelector
            disableColumnFilter={disableColumnFilter}
            slots={{
                toolbar: () => (
                    <DataGridToolbar
                        disableQuickSearch={disableQuickSearch}
                        disableExport={disableExport}
                        csvOptions={{
                            delimiter: language === Locale.ENGLISH ? "," : ";",
                            utf8WithBom: true,
                        }}
                        quickFilterProps={{
                            placeholder: t("quick_search", { ns: Namespace.ACTIONS }),
                        }}
                    />
                ),
                noRowsOverlay: NoResultOverlay,
                noResultsOverlay: NoResultOverlay,
                ...slots,
            }}
            slotProps={{
                columnHeaders: {
                    color: "#f00",
                    style: { background: "#f00" },
                },
                ...slotProps,
            }}
            // translate to French if selected
            {...(language === Locale.FRENCH && { localeText: frFR.components.MuiDataGrid.defaultProps.localeText })}
            {...props}
        />
    )
}