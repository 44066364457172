import { Box, Container, Grid } from '@mui/material';
import PageTitle from 'components/_include/PageTitle';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import PartnersGrid from './PartnersGrid';

type PartnersPageProps = {

}

function PartnersPage(props: PartnersPageProps) {
    const { } = props;

    // const x = useAppSelector(state => state);
    // const dispatch = useAppDispatch();

    return (
        <Container 
            sx={{
                display: "flex", 
                flexDirection: "column"
            }}
            >
            <PageTitle />

            <PartnersGrid />
        </Container>
    );
}

export default PartnersPage;