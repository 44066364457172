import { ListItemButton, ListItemText, ListItemIcon, useTheme, Box, } from "@mui/material";
import NewTag from "components/_include/NewTag";
import { AppIcon } from "constants/pages";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";

export type NavigationMenuItemProps = {
    link?: string;
    label: string;
    Icon: AppIcon;
    isNew?: boolean;
    onClick?: () => void;
}

function NavigationMenuItem({ link, label, Icon, isNew, onClick }: NavigationMenuItemProps) {

    const { t } = useTranslation([Namespace.TITLES]);

    const { search } = useLocation();

    const theme = useTheme();

    return (
        <NavLink
            to={link + search}
            style={({ isActive }) => ({
                ...(isActive && { // style button when active
                    backgroundColor: theme.palette.secondary.main,
                })
            })}
        >
            <ListItemButton
                sx={{
                    position: "relative",
                    color: (theme) => theme.palette.common.white,
                    backgroundColor: "inherit",
                    py: 1,
                    px: "10%",
                    textDecoration: 'none',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    },
                }}
                onClick={onClick}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 'auto',
                        marginRight: 1.5,
                    }}>
                    {<Icon />}
                </ListItemIcon>

                <Box position="relative">
                    <ListItemText
                        primary={t(label, { ns: Namespace.TITLES })}
                        primaryTypographyProps={{
                            fontSize: "0.9rem",
                            lineHeight: "1rem",
                        }}
                    />

                    {isNew && (
                        <Box
                            position="absolute"
                            right={-28}
                            top={-4}
                        >
                            <NewTag />
                        </Box>
                    )}
                </Box>
            </ListItemButton>
        </NavLink>
    );
}

export default NavigationMenuItem;