import ActionButton from "components/_include/Buttons/ActionButton";
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { useAppSelector } from "hooks/hooks";

type NewPartnerSaveButtonProps = {
    disabled: boolean;
    onClick: () => void;
}

export default function NewPartnerSaveButton({ disabled, onClick }: NewPartnerSaveButtonProps) {
    const { t } = useTranslation([Namespace.ACTIONS]);

    const loading = useAppSelector(state => state.partners.selected.loading || state.user.creating);

    return (
        <ActionButton
            color="secondary"
            onClick={onClick}
            disabled={disabled}
            loading={loading}
        >
            {t("save", { ns: Namespace.ACTIONS })}
        </ActionButton>
    )
}