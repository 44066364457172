import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { selectPartnerById } from 'store/reducers/partners/list';
import PartnerNameInput from './PartnerNameInput';
import PartnerNameText from './PartnerNameText';

type PartnerNameProps = {
    partnerID: string | null;
    onChange?: (name: string | undefined) => void;
}

function PartnerName({ partnerID, onChange }: PartnerNameProps) {
    const [editing, setEditing] = useState(false);
    // const dispatch = useAppDispatch();

    return (
        <Box mb={2}>
            {editing || !partnerID ? ( // currently editing, or new partner
                <PartnerNameInput
                    partnerID={partnerID}
                    onClose={() => setEditing(false)}
                    onChange={onChange}
                />
            ) : (
                <PartnerNameText
                    partnerID={partnerID}
                    onStartEdit={() => setEditing(true)}
                />
            )}
        </Box>
    );
}

export default PartnerName;