import { Grid, TextField } from "@mui/material";
import { TextInput } from "helpers/forms";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";

type Inputs = {
    firstName: TextInput;
    lastName: TextInput;
    email: TextInput;
    role: TextInput;
}

type CollaboratorFormFieldsProps = {
    inputs: Inputs;
    onInputChange: (field: keyof Inputs, value: string) => void;
    loading: boolean;
}

export function CollaboratorFormFields({ inputs, onInputChange, loading }: CollaboratorFormFieldsProps) {
    const { t } = useTranslation([Namespace.CONTACTS, Namespace.FORMS]);

    return (
        <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item xs={12} sm={6}>
                <TextField
                    label={t("first name", { ns: Namespace.CONTACTS })}
                    value={inputs.firstName.value}
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => onInputChange("firstName", e.target.value)}
                    disabled={loading}
                    error={!!inputs.firstName.error}
                    helperText={inputs.firstName.error || ""}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label={t("last name", { ns: Namespace.CONTACTS })}
                    value={inputs.lastName.value}
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => onInputChange("lastName", e.target.value)}
                    disabled={loading}
                    error={!!inputs.lastName.error}
                    helperText={inputs.lastName.error || ""}
                />
            </Grid>
            <Grid item xs={12} sm={7}>
                <TextField
                    label={t("email", { ns: Namespace.CONTACTS })}
                    value={inputs.email.value}
                    margin="normal"
                    variant="outlined"
                    type="email"
                    onChange={(e) => onInputChange("email", e.target.value.trim().toLowerCase())}
                    disabled={loading}
                    error={!!inputs.email.error}
                    helperText={inputs.email.error || ""}
                />
            </Grid>
        </Grid>
    );
}