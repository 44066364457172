import { Box, Container, Typography, TypographyProps } from "@mui/material";
import ActionButton from "components/_include/Buttons/ActionButton";
import PageTitle from "components/_include/PageTitle";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useMemo } from "react";
import { Section } from "constants/pages";
import { useAppSelector } from "hooks/hooks";

const TextWrapper = (props: TypographyProps) => <Typography textAlign="center" variant="h4" color="rgba(0,0,0,0.36)" {...props} />

export default function NoPartnerPage() {

    const { t } = useTranslation([Namespace.PARTNERS, Namespace.TITLES]);

    const userEmail = useAppSelector(state => state.user.email);

    const mailtoLink = useMemo(() => {
        const subjectString = t("missing_partner.email_subject", { ns: Namespace.PARTNERS, });
        const bodyString = t("missing_partner.email_content", {
            ns: Namespace.PARTNERS,
            email: userEmail,
            dashboardURL: `${window.location.origin}/${Section.CONTACTS}`,
        });
        return `mailto:?subject=${encodeURIComponent(subjectString)}&body=${encodeURIComponent(bodyString)}`
    }, [t, userEmail]);

    return (
        <Container>
            <PageTitle title={t("onboarding", { ns: Namespace.TITLES })} />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                pt={15}
                px={25}
                gap={6}
            >
                <TextWrapper>
                    {t("missing_partner.1", { ns: Namespace.PARTNERS })}
                </TextWrapper>

                <TextWrapper>
                    {t("missing_partner.2", { ns: Namespace.PARTNERS })}
                </TextWrapper>

                <ActionButton
                    color="secondary"
                    startIcon={<SendRoundedIcon />}
                    href={mailtoLink}
                >
                    {t("missing_partner.request_invitation", { ns: Namespace.PARTNERS })}
                </ActionButton>

                <TextWrapper>
                    {t("missing_partner.3", { ns: Namespace.PARTNERS })}
                </TextWrapper>
            </Box>
        </Container>
    )
}