import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector, useMapCamera } from "hooks/hooks";
import { getBoundsZoomLevel, getPointsBoundsAndCenter, mvcArrayToCoords } from "helpers/geo";
import { useTheme } from "@mui/material";
import { CenterAndZoom } from "constants/types";
import { SelectedAreaActions } from "store/reducers/areas/selected";
import _ from "lodash";
import { SAVE_AREA_BUTTON_ID } from "../AreasControls/AreasControls";
import { selectAreasIds } from "store/reducers/areas/list";
import { AreasMapActions } from "store/reducers/areas/map";
import { OsmAreasActions } from "store/reducers/areas/osm";
import { APILoadingStatus, useApiIsLoaded, useApiLoadingStatus, useMap } from "@vis.gl/react-google-maps";
import { showError } from "store/reducers/snacks";
import { MapWrapper } from "components/_include/Maps/MapWrapper";


type GoogleApi = { map: google.maps.Map, maps: typeof google.maps };

export default function AreasMap() {

    const areasIDs = useAppSelector(selectAreasIds);

    const defaultCenter = useAppSelector(state => state.areas.map.defaultCenter);
    const defaultBounds = useAppSelector(state => state.areas.map.defaultBounds);
    const defaultZoom = useAppSelector(state => state.areas.map.defaultZoom);

    const selectedOsmArea = useAppSelector(state => state.areas.osm.selected);

    const dispatch = useAppDispatch();

    // handle map
    const [{ center }, setMapState] = useState<CenterAndZoom>({
        zoom: defaultZoom,
        center: defaultCenter,
    });

    const selectedArea = useAppSelector(state => state.areas.selected.data);
    const editing = useAppSelector(state => state.areas.selected.editing);
    const newPath = useAppSelector(state => state.areas.selected.newPath);

    const INITIAL_CAMERA = {
        center: defaultCenter,
        zoom: defaultZoom
    };

    const { cameraProps, handleCameraChange } = useMapCamera(INITIAL_CAMERA);


    const status = useApiLoadingStatus();

    useEffect(() => {
        setMapState({ center, zoom: defaultZoom });
    }, [center, defaultZoom]);

    useEffect(() => {
        if (defaultBounds) {
            const mapDims = {
                width: window.innerWidth,
                height: window.innerHeight,
            };
            let newZoom = getBoundsZoomLevel(defaultBounds, mapDims);

            setMapState({
                zoom: newZoom,
                center: defaultCenter,
            });
        }
    }, [defaultBounds, defaultCenter]);

    useEffect(() => {
        // reset selected area when areas change (change of partner)
        dispatch(SelectedAreaActions.selectArea({ area: null }));
    }, [areasIDs, dispatch]);

    const theme = useTheme();

    const [googleApi, setGoogleApi] = useState<GoogleApi>();

    // use ref to access latest state from listeners
    const googleApiRef = useRef<GoogleApi>();

    // store the latest googleApi in a ref
    useEffect(() => {
        googleApiRef.current = googleApi;
    }, [googleApi]);

    const [lassoPolyline, setLassoPolyline] = useState<google.maps.Polyline>();

    const [editingPolyline, setEditingPolyline] = useState(false);
    const [polylineEvents, setPolylineEvents] = useState<google.maps.MapsEventListener[]>([]);

    const noSelectedArea = !selectedArea;

    // Unified effect for setting up or removing polylines
    useEffect(() => {
        if (!googleApi) return;

        if (status === APILoadingStatus.FAILED) {
            showError("Google Maps failed to load")
            return;
        }

        if (editing && noSelectedArea) {
            // Set up a new polyline for editing
            setUpPolyline();
        } else if (!editing && lassoPolyline) {
            // User has just clicked on "Cancel"
            removePolyline(true);
        }
    }, [editing, noSelectedArea, googleApi, status]);

    useEffect(() => {
        const linePath = lassoPolyline?.getPath();
        if (newPath && linePath && !_.isEqual(newPath, mvcArrayToCoords(linePath))) {
            // set points one by one to keep events on polyline path
            linePath.clear();
            for (let point of newPath) {
                linePath.push(new google.maps.LatLng(point));
            }
        }
    }, [newPath, lassoPolyline]);

    const [areaPolygon, setAreaPolygon] = useState<google.maps.Polygon>();
    const [polygonEvents, setPolygonEvents] = useState<google.maps.MapsEventListener[]>([]);

    /**
     * Draw a new Polygon on the map. 
     * Erase the previously drawn polygon or editing polyline.
     * @param path List of Coords representing the Polygon to draw
     * @param editable If set to true, allow edition of the polygon
     */
    const drawPolygon = (path: google.maps.LatLngLiteral[], editable: boolean) => {
        if (!googleApi) return;

        // setLassoPolyline(polyline);
        const polygon = new googleApi.maps.Polygon({
            paths: path,
            strokeOpacity: 0.7,
            strokeColor: theme.palette.secondary.main,
            fillOpacity: 0.2,
            fillColor: theme.palette.secondary.main,
            editable: editable,
        });

        removePolyline(!editable);

        polygon.setMap(googleApi.map);

        const handlePathChanged = (e: google.maps.MapMouseEvent) => {
            dispatch(SelectedAreaActions.setNewPath(mvcArrayToCoords(polygon.getPath())));
        }

        setPolygonEvents([
            polygon.getPath().addListener("set_at", handlePathChanged),
            polygon.getPath().addListener("insert_at", handlePathChanged),
            polygon.getPath().addListener("remove_at", handlePathChanged),
        ]);

        setAreaPolygon(polygon);
    }

    const removePolygon = () => {
        for (let event of polygonEvents) { // remove polygon events listeners
            event.remove();
        }

        if (areaPolygon) { // erase previous polygon
            areaPolygon.setMap(null);
        }
    }

    /**
     * Erase the polyline currently drawn
     * @param stopEditing If set to true, stop "editing" a new area
     */
    const removePolyline = (stopEditing: boolean) => {
        for (let event of polylineEvents) { // remove polyline events listeners
            event.remove();
        }

        lassoPolyline?.setMap(null);
        setLassoPolyline(undefined);

        if (selectedOsmArea) dispatch(OsmAreasActions.setSelected(null)); // clear selected OSM area

        if (stopEditing) dispatch(SelectedAreaActions.setNewPath(null));
    }

    const setUpPolyline = () => {
        if (!googleApi) return;

        // prepare lasso polyline
        const polyline: google.maps.Polyline = new googleApi.maps.Polyline({
            strokeColor: theme.palette.secondary.main,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            editable: true,
        });
        polyline.setMap(googleApi.map);

        // handle drag, insert and click interactions
        const handlePathChanged = (e: google.maps.MapMouseEvent) => {
            dispatch(SelectedAreaActions.setNewPath(mvcArrayToCoords(polyline.getPath())));
        }

        setPolylineEvents([
            polyline.addListener("mousedown", (e: google.maps.MapMouseEvent) => {
                setEditingPolyline(true);
            }),
            polyline.getPath().addListener("set_at", handlePathChanged), // drag node
            polyline.getPath().addListener("insert_at", handlePathChanged), // drag middle of segment
            polyline.addListener("click", handlePolylineClose), // click on polyline to close 
        ]);

        setLassoPolyline(polyline);
    }

    const handlePolylineClose = (e: google.maps.PolyMouseEvent) => {
        if (e.latLng) {
            addPointToPolyline(e.latLng);

            if (e.vertex === 0) { // click on the first point
                // trigger click on "Save button"
                document.getElementById(SAVE_AREA_BUTTON_ID)?.click();
            }
        }
    }

    /**
     * Existing area just selected by user
     */
    useEffect(() => {
        if (!googleApi?.map) return;
        // erase previous polygon
        removePolygon();

        // different area selected
        if (selectedArea && googleApi) {

            // draw selected polygon
            drawPolygon(selectedArea.path, false);
        }
    }, [selectedArea?.ID, googleApi]);

    /**
     * OpenStreetMap result just selected by user
     */
    useEffect(() => {
        if (!googleApi?.map) return;
        // erase previous polygon
        removePolygon();

        if (selectedOsmArea) {
            const areaPath = selectedOsmArea.path.map(point => ({ lat: point[1], lng: point[0] })); // convert from [lng, lat] to { lat, lng }

            // draw selected OSM polygon
            // drawPolygon(areaPath, true);
            dispatch(SelectedAreaActions.setNewPath(areaPath));

            dispatch(AreasMapActions.setMapBounds(getPointsBoundsAndCenter(areaPath)));
        }
    }, [selectedOsmArea, googleApi]);

    const addPointToPolyline = (point: google.maps.LatLng) => {
        if (editingPolyline) { // editing existing point -> don't add new one
            setEditingPolyline(false);
        }
        else { // add new point to path
            dispatch(SelectedAreaActions.addPointToNewPath({ lat: point.lat(), lng: point.lng() }));
        }
    }

    // Change cursor to crosshair while creating a new area
    useEffect(() => {
        if (editing && googleApi?.map) {
            googleApi.map.setOptions({ draggableCursor: "crosshair" });
        } else if (googleApi?.map) {
            googleApi.map.setOptions({ draggableCursor: "" }); // revert to default
        }
    }, [editing, googleApi]);

    const apiIsLoaded = useApiIsLoaded();
    const map = useMap();

    const [panoramaInstance, setPanoramaInstance] = useState<google.maps.StreetViewPanorama | null>(null);

    useEffect(() => {
        if (map) {
            const panorama = map.getStreetView();
            setPanoramaInstance(panorama);
        }
    }, [map]);

    // Manage Street View visibility and position
    useEffect(() => {
        if (panoramaInstance) {
            panoramaInstance.setOptions({
                addressControl: true, // Ensure the address control is enabled
                addressControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                },
            });
        }
    }, [panoramaInstance]);


    useEffect(() => {
        if (!apiIsLoaded || !map) return;

        const maps = window.google.maps;

        if (!googleApi || (googleApi && googleApi.map !== map)) {
            setGoogleApi({ map, maps });
        }
        if (map && defaultBounds) {
            try {
                const newZoom = getBoundsZoomLevel(defaultBounds, {
                    width: window.innerWidth,
                    height: window.innerHeight,
                });

                map.fitBounds(defaultBounds);
                setMapState({
                    zoom: newZoom,
                    center: defaultCenter,
                });
            } catch (error) {
                console.error("Error converting bounds:", error);
            }
        }
    }, [apiIsLoaded, map, googleApi, defaultBounds, defaultCenter]);


    return (
        apiIsLoaded ? (
            <MapWrapper
                mapId="defaultMap"
                initialCamera={cameraProps}
                onCameraChanged={handleCameraChange}
                mapTypeControlOptions={{
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.BOTTOM_CENTER,
                    mapTypeIds: [
                        google.maps.MapTypeId.ROADMAP,
                        google.maps.MapTypeId.SATELLITE,
                    ],
                }
                } onClick={(e) => {
                    if (!editing) return;
                    addPointToPolyline(new google.maps.LatLng(e.detail.latLng!))
                }} />
        ) : null
    );
}