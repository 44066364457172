import { DialogContent, DialogContentText, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useAppSelector } from "hooks/hooks";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { selectAllTrucks } from 'store/reducers/trucks/list';
import { selectAllRoutes } from 'store/reducers/routes/list';
import { Namespace } from "locales/translations";
import _ from "lodash";
import ScheduleCalendarSection from "./ScheduleCalendar/ScheduleCalendarSection";
import { Calendar, Weekday } from "models/CollectionSchedule";
import SaveScheduleActions from "./SaveScheduleActions";
import ScheduleTruckSelect from "./ScheduleTruckSelect";
import ScheduleRouteSelect from "./ScheduleRouteSelect";

type FormProps = {
    onClose: () => void;
}

export default function ScheduleForm(props: FormProps) {
    const { onClose, } = props;

    const { t } = useTranslation([Namespace.FORMS]);

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    {t("onboarding.schedules.description", { ns: Namespace.FORMS })}
                </DialogContentText>
                <Grid container my={0} spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <ScheduleTruckSelect />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <ScheduleRouteSelect />
                    </Grid>

                    <ScheduleCalendarSection />
                </Grid>
            </DialogContent>

            <SaveScheduleActions
                onClose={onClose}
                />
        </>
    )
}