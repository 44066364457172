import ActionButton from "components/_include/Buttons/ActionButton";
import { useSearchParams } from "react-router-dom";
import { DatesRangeType, getStartAndEndFromURLParams, isSingleDay } from 'helpers/dates';
import { Timestamp } from '@firebase/firestore';
import { useAppSelector, useSortingRules, useAppDispatch } from 'hooks/hooks';
import { usePartnerID } from 'hooks/partners';
import BatchesController from 'controllers/batches';
import { TrashType } from 'constants/trash';
import { SearchParam } from 'constants/urls';
import { useEffect, useMemo } from "react";
import moment from "moment";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { selectAllBatches } from "store/reducers/batches/list";

type ButtonProps = {
    loadingLimit: number;
    loading: boolean;
    listBatchesAction: typeof BatchesController.list | typeof BatchesController.listByAddresses;
    listOlderBatchesAction: typeof BatchesController.listOlder | typeof BatchesController.listOlderByAddresses;
}

export function SearchBatchesButton({ loadingLimit, loading, listBatchesAction, listOlderBatchesAction }: ButtonProps) {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.DATES]);

    const { displayedErrors } = useSortingRules();

    const [searchParams] = useSearchParams();
    const truckID = searchParams.get(SearchParam.TRUCK_ID) ?? undefined;
    const areaID = searchParams.get(SearchParam.AREA_ID) ?? undefined;
    const routeID = searchParams.get(SearchParam.ROUTE_ID) ?? undefined;
    const dateSelect = searchParams.get(SearchParam.DATES_RANGE_TYPE_SELECT);
    const startDate = searchParams.get(SearchParam.START_DATE);
    const endDate = searchParams.get(SearchParam.END_DATE);
    const startTime = searchParams.get(SearchParam.START_TIME);
    const endTime = searchParams.get(SearchParam.END_TIME);
    const errors = searchParams.getAll(SearchParam.SELECTED_ERRORS)
        .filter(e => displayedErrors
            .includes(e as TrashType)) as TrashType[];

    const partnerID = usePartnerID();

    const wasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);

    const clusterBy = useAppSelector(state => state.batches.byAddresses.clusterBy);

    const dispatch = useAppDispatch();

    /**
     * Is only a single day selected? 
     * (then we display the Times range select)
     */
    const singleDaySelected = useMemo(() => {
        if (!dateSelect) return false;
        return isSingleDay(dateSelect as DatesRangeType, moment(startDate), moment(endDate));
    }, [dateSelect, startDate, endDate]);

    const { start, end } = useMemo(() => {
        // dates filter
        if (!dateSelect) return { start: undefined, end: undefined };
        const { start, end } = getStartAndEndFromURLParams(dateSelect as DatesRangeType, startDate, endDate, singleDaySelected ? startTime : undefined, singleDaySelected ? endTime : undefined)
        return { start, end };
    }, [dateSelect, startDate, startTime, endDate, endTime, singleDaySelected]);

    const getBatches = () => {
        if (!partnerID || !wasteStream) return;

        let filters: Parameters<typeof BatchesController.list>[1] = [
            { fieldPath: "collectionType", opStr: "==", value: wasteStream, },
            { fieldPath: "display", opStr: "==", value: true },
            { fieldPath: "verified", opStr: "==", value: true },
        ];

        if (truckID) {
            filters.push({
                fieldPath: "truckID",
                opStr: "==",
                value: truckID?.toString(),
            });
        }

        if (areaID) {
            filters.push({
                fieldPath: "areasIDs",
                opStr: "array-contains",
                value: areaID?.toString(),
            });
        }

        if (routeID) {
            filters.push({
                fieldPath: "routeID",
                opStr: "==",
                value: routeID?.toString(),
            });
        }

        if (!start || !end) return;

        filters.push(
            {
                fieldPath: "timestamp",
                opStr: ">=",
                value: Timestamp.fromDate(start.toDate()),
            },
            {
                fieldPath: "timestamp",
                opStr: "<=",
                value: Timestamp.fromDate(end.toDate()),
            }
        );

        dispatch(listBatchesAction(partnerID, filters, errors, { fieldPath: "timestamp", directionStr: "asc" }, loadingLimit, clusterBy));
    };

    const nextQuery = useAppSelector(state => state.batches.list.next);

    const loadedFrom = useAppSelector(state => {
        const firstBatch = selectAllBatches(state).at(0)?.timestamp;
        return firstBatch ? moment(firstBatch) : undefined;
    });
    const loadedUntil = nextQuery ? moment(nextQuery.startAfterValue) : undefined;

    useEffect(() => {
        console.log("load more batches", nextQuery);
        if (nextQuery) {
            dispatch(listOlderBatchesAction());
        }
    }, [nextQuery]);

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
            <ActionButton
                color="secondary"
                loading={loading}
                disabled={loading || !start || !end}
                onClick={getBatches}
            >
                {t("search", { ns: Namespace.ACTIONS })}
            </ActionButton>

            {nextQuery && (
                <Typography 
                    variant="caption" 
                    color="rgba(0, 0, 0, 0.4)"
                    sx={{ 
                        mt: 1,
                        whiteSpace: "nowrap"
                    }}
                >
                    {t("loaded_from_to", { from: loadedFrom, to: loadedUntil, ns: Namespace.DATES })}
                </Typography>
            )}
        </Box>
    )
}