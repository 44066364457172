import { Backdrop, Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HowItWorksDialog from './HowItWorksDialog/HowItWorksDialog';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import HowToCreateDialog from './HowToCreateDialog/HowToCreateDialog';

type AreasHelpSpeedDialProps = {

}

function AreasHelpSpeedDial({ }: AreasHelpSpeedDialProps) {
    const { t } = useTranslation([Namespace.AREAS, Namespace.COMMONS]);

    const [speedDialOpen, toggleSpeedDial] = useState(false);
    const [howItWorksDialogOpen, toggleHowItWorksDialog] = useState(false);
    const [howToCreateDialogOpen, toggleHowToCreateDialog] = useState(false);

    const handleCloseSpeedDial = () => {
        // only close speed dial if no sub-dialog was open
        if (!howItWorksDialogOpen && !howToCreateDialogOpen) toggleSpeedDial(false);
    }

    useEffect(() => {
        // close speed dial when sub-dialog is closed
        handleCloseSpeedDial();
    }, [howItWorksDialogOpen, howToCreateDialogOpen]);

    return (
        <Box 
            position="absolute"
            zIndex={1}
            >
            <Backdrop open={speedDialOpen ?? howItWorksDialogOpen} />
            <SpeedDial
                sx={{
                    position: "relative"
                }}
                ariaLabel={t("help", { ns: Namespace.COMMONS })}
                FabProps={{
                    color: "white"
                }}
                icon={
                    <SpeedDialIcon 
                        icon={<HelpRoundedIcon />}
                        openIcon={<CloseRoundedIcon />}
                        />
                }
                onClose={handleCloseSpeedDial}
                onOpen={() => toggleSpeedDial(true)}
                open={speedDialOpen}
                direction='down'
            >
                {/* What are areas */}
                <SpeedDialAction
                    key="what-are-areas"
                    icon={<InfoRoundedIcon />}
                    tooltipTitle={t("what_are_areas.label", { ns: Namespace.AREAS })}
                    tooltipOpen
                    onClick={() => toggleHowItWorksDialog(true)}
                />
                {/* How to create an area */}
                <SpeedDialAction
                    key="how-to-create"
                    icon={<AddCircleRoundedIcon />}
                    tooltipTitle={t("how_to_create.label", { ns: Namespace.AREAS })}
                    tooltipOpen
                    onClick={() => toggleHowToCreateDialog(true)}
                />
            </SpeedDial>

            <HowItWorksDialog 
                open={howItWorksDialogOpen}
                onClose={() => toggleHowItWorksDialog(false)}
                />

            <HowToCreateDialog 
                open={howToCreateDialogOpen}
                onClose={() => toggleHowToCreateDialog(false)}
                />
        </Box>
    );
}

export default AreasHelpSpeedDial;