import React from 'react';
import AddressReportDialogWrapper from './AddressReportDialogWrapper';
import TopicsWithCheckboxes from './TopicsWithCheckboxes';
import CommentSection from './CommentSection';
import AddressReportImageGrid from './AddressReportImageGrid';
import YesNoQuestion from './YesNoQuestion';
import CommunicationMethods from './CommunicationMethods';
import MoodSelector from './MoodSelector';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import IPadBackGround from './IPadBackGround';
import { ReportFormData } from 'models/Missions';
import { TOPICS } from 'constants/address_report';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { SelectedMissionAddressActions } from 'store/reducers/missions/addresses/selected';
import CloseIcon from '@mui/icons-material/CloseRounded';

const AddressReport: React.FC = () => {
    const { t } = useTranslation([Namespace.MISSION_REPORT, Namespace.ACTIONS]);

    const address = useAppSelector(state => state.missions.selectedAddress.address);
    const report = useAppSelector(state => state.missions.selectedAddress.report);

    const dispatch = useAppDispatch();

    const closeDialog = () => {
        dispatch(SelectedMissionAddressActions.setSelected({ address: null, report: undefined }));
    }

    const finalReportData: ReportFormData = {
        inhabitantsSpoken: null,
        communicationMethods: [],
        selectedTopics: [],
        otherComments: '',
        inhabitantsReceptive: null,
        additionalInfo: '',
        imageURLs: [],
        ...report,
    };


    return (
        <AddressReportDialogWrapper open={Boolean(address)} onClose={closeDialog}>
            <IPadBackGround>
                <Typography variant='h3'>
                    {t('intervention.question', { ns: Namespace.MISSION_REPORT, address: address })}
                </Typography>
                <YesNoQuestion
                    question="communication.inhabitants"
                    value={finalReportData?.inhabitantsSpoken}
                />
                <CommunicationMethods
                    communicationMethods={finalReportData?.communicationMethods}
                />
                <TopicsWithCheckboxes
                    topics={TOPICS}
                    selectedTopics={finalReportData?.selectedTopics}
                />
                <CommentSection rows={1} nameSpace={[Namespace.MISSION_REPORT, Namespace.FORMS]} placeholder='sign_up.partner_type.other' value={finalReportData?.otherComments} wordLimit={100} />
                <MoodSelector
                    question="reception.question"
                    value={finalReportData?.inhabitantsReceptive}
                />
                <CommentSection rows={4} nameSpace={[Namespace.MISSION_REPORT, Namespace.MISSION_REPORT]} label="comments.other" placeholder='comments.additional_info' value={finalReportData?.additionalInfo} wordLimit={400} />
                <AddressReportImageGrid images={finalReportData?.imageURLs} />
            </IPadBackGround>
            
            <IconButton
                id="test-button"
                sx={{ 
                    position: "absolute",
                    top: theme => theme.spacing(-5),
                    right: "5%",
                }}
                color='white'
                onClick={closeDialog}
                >
                <CloseIcon sx={{ fontSize: 36 }} />
            </IconButton>
        </AddressReportDialogWrapper>
    );
};

export default AddressReport;
