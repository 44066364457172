import { Divider, List } from "@mui/material"
import { TrashType, VALIDATION_WASTES_COLORS } from "constants/trash";
import { useAppSelector } from "hooks/hooks";
import { selectAllResultsBboxes } from "store/reducers/batches/media_overlay";
import BoundingBoxDetailsItem from "./BoundingBoxDetailsItem";
import { Fragment } from "react";
import { hexToRgba } from "helpers/draw";

type ListProps = {
    wasteType: TrashType;
    canEditResults?: boolean;
}

/**
 * List of all the details about the bounding boxes for a give class of waste
 */
export default function BoundingBoxDetailsList({ wasteType, canEditResults }: ListProps) {

    const bboxes = useAppSelector(state => selectAllResultsBboxes(state));
    // Sort bounding boxes by createdAt time if available
    bboxes.sort((a, b) => {
        const createdAtA = a.createdAt || 0;
        const createdAtB = b.createdAt || 0;
        return createdAtB - createdAtA;
    });
    
    return (
        <List 
            disablePadding
            sx={{
                ml: 3,
                // Background color is the classe's associated color with 10% opacity
                bgcolor: hexToRgba(VALIDATION_WASTES_COLORS[wasteType], 0.1),
            }}
            >
            {bboxes.filter(box => box.class === wasteType).map(bbox => (
                <Fragment key={bbox.ID}>
                    <BoundingBoxDetailsItem
                        bbox={bbox}
                        canEditResults={canEditResults}
                    />
                    <Divider />
                </Fragment>
            ))}
        </List>
    )
}