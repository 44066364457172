import React from 'react';
import { Box } from '@mui/material';
import { useAppSelector, useCanWriteCampaigns } from 'hooks/hooks';
import { usePartnerID } from 'hooks/partners';
import { MissionStatus } from 'models/Missions';
import ConfirmDeleteMissionDialog from '../ConfirmDeleteMissionDialog';
import ConfirmMarkAsDoneDialog from '../ConfirmMarkAsDoneDialog';
import DownloadCampaignReportsButton from 'components/_include/Buttons/DownloadCampaignReportsButton';

interface MissionActionsProps {
}

const MissionActions: React.FC<MissionActionsProps> = () => {
    const missionStatus = useAppSelector(state => state.missions.selectedMission.data?.status);

    const partnerID = usePartnerID();
    const canDelete = useCanWriteCampaigns(partnerID);

    return (
        <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
            <DownloadCampaignReportsButton />
            {canDelete && (
                <ConfirmDeleteMissionDialog />
            )}
            {missionStatus !== MissionStatus.COMPLETED && (
                <ConfirmMarkAsDoneDialog />
            )}
        </Box>
    );
};

export default MissionActions;