import { useCallback, useEffect, } from "react";
import { formatAddress, getBoundsZoomLevel, GRENOBLE_COORDINATES } from "helpers/geo";
import AddressMarker from "./AddressMarker";
import { PositionedNamedErrorsByAddress } from "constants/types";
import { useAppSelector, useMapCamera } from "hooks/hooks";
import { APILoadingStatus, useApiIsLoaded, useApiLoadingStatus } from "@vis.gl/react-google-maps";
import { showError } from "store/reducers/snacks";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { MapWrapper } from "components/_include/Maps/MapWrapper";

type WorstAddressesMapProps = {
    width: number;
    height: number;
    points: PositionedNamedErrorsByAddress[];
}

export default function WorstAddressesMap({ width, height, points }: WorstAddressesMapProps) {

    const INITIAL_CAMERA = {
        center: GRENOBLE_COORDINATES,
        zoom: 15
    };

    const MARKER_CLICK_ZOOM_LEVEL = 17;

    const { cameraProps, handleCameraChange, setCameraProps } = useMapCamera(INITIAL_CAMERA);

    const { t } = useTranslation([Namespace.COMMONS]);
    const defaultCenter = useAppSelector(state => state.batches.sortingMap.defaultCenter);
    const defaultZoom = useAppSelector(state => state.batches.sortingMap.defaultZoom);

    // Utilize the provided hooks to monitor API loading status
    const apiIsLoaded = useApiIsLoaded();
    const status = useApiLoadingStatus();

    useEffect(() => {
        if (status === APILoadingStatus.FAILED) {
            console.error("Google Maps API failed to load.");
            showError("Google Maps failed to load")
            return;
        }
        if (!apiIsLoaded) return;

        if (points.length > 0) {
            // calculate map bounds and zoom to view all batches
            let minLat = 999, minLng = 999, maxLat = -999, maxLng = -999;
            for (let { lat, lng } of points) {
                if (lat < minLat) minLat = lat;
                if (lat > maxLat) maxLat = lat;
                if (lng < minLng) minLng = lng;
                if (lng > maxLng) maxLng = lng;
            }

            const bounds = {
                north: maxLat,
                south: minLat,
                east: maxLng,
                west: minLng,
            };

            const zoom = getBoundsZoomLevel(bounds, { width, height });

            const center = { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2, };

            setCameraProps({
                zoom: zoom,
                center: center,
            });
        }
        else { // focus on default partner's center
            setCameraProps({
                center: defaultCenter,
                zoom: defaultZoom,
            });
        }
    }, [points, defaultCenter, defaultZoom, width, height, status, apiIsLoaded, setCameraProps]);

    const handleMarkerClick = useCallback((lat: number, lng: number) => {
        setCameraProps({
            center: { lat, lng },
            zoom: MARKER_CLICK_ZOOM_LEVEL,
        });
    }, []);

    if (status === APILoadingStatus.FAILED) {
        return <Typography variant="body1">{t("map_loading_failed", { ns: Namespace.COMMONS })}</Typography>;
    }

    if (!apiIsLoaded) {
        return <Typography variant="body1">{t("map_loading", { ns: Namespace.COMMONS })}</Typography>;
    }

    return (
        <MapWrapper mapId="defaultMap" initialCamera={cameraProps} onCameraChanged={handleCameraChange}>
            {points.map((point) => (
                <AddressMarker
                    key={point.addressKey}
                    lat={point.lat}
                    lng={point.lng}
                    addressKey={point.addressKey}
                    address={formatAddress(point.address)}
                    onClick={handleMarkerClick}
                />
            ))}
        </MapWrapper>
    );
}