import { Box, IconButton, ListItem, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import KeyIcon from "@mui/icons-material/KeyRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

type CardListItemProps = {
    primary: string;
    secondary?: string;
    disabled?: boolean;
    onEditClicked?: () => void;
    onDeleteClicked?: () => void;
    onDownloadClicked?: () => void;
}

export default function CardListItem({ primary, secondary, disabled, onEditClicked, onDeleteClicked, onDownloadClicked }: CardListItemProps) {
    return (
        <ListItem
            divider
            sx={{
                py: 2,
            }}
            secondaryAction={disabled ? undefined :
                <Box>
                    {onEditClicked && (
                        <IconButton edge="start" aria-label="edit" onClick={() => onEditClicked()} color="secondary">
                            <EditIcon/>
                        </IconButton>
                    )}
                    {onDeleteClicked && (
                        <IconButton edge="end" aria-label="delete" onClick={() => onDeleteClicked()}>
                            <DeleteIcon />
                        </IconButton>
                    )}
                    {onDownloadClicked && (
                        <IconButton edge="end" aria-label="download" onClick={() => onDownloadClicked()} color="secondary" title="credentials.json">
                            <KeyIcon />
                        </IconButton>
                    )}
                    {!onEditClicked && !onDeleteClicked && !onDownloadClicked && (
                        <CheckCircleRoundedIcon color="success" />
                    )}
                </Box>
            }
        >
            <ListItemText
                sx={{
                    ...(disabled && { opacity: 0.36}),
                }}
                primary={primary}
                primaryTypographyProps={{
                    fontSize: 20,
                    fontFamily: "CircularStd",
                    fontWeight: "bold"
                }}
                secondary={secondary}
                secondaryTypographyProps={{
                    whiteSpace: "pre-wrap"
                }}
            />
        </ListItem>
    );
}