import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, } from "@mui/material";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { ImagesToAnnotateActions, selectNumberOfBatchesToDownload } from "store/reducers/batches/to_download";
import DownloadImagesSnippet from "./DownloadImagesSnippet";
import MarkDownloadedImages from "./MarkDownloadedImages";

type DialogProps = {
    open: boolean;
    onClose: () => void;
}

function DownloadImagesToAnnotateDialog(props: DialogProps) {
    const { open, onClose, } = props;

    const numberOfImagesToDowmload = useAppSelector(selectNumberOfBatchesToDownload);

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.IMAGES]);

    const [searchParams] = useSearchParams();

    // const handleDownloadClicked = (includeAnnotations: boolean) => {
    //     // get dates range for archive
    //     const range = searchParams.get("dateSelect") as DATE_SELECT_TYPES | null;

    //     let start: moment.Moment | undefined;
    //     let end: moment.Moment | undefined;

    //     if (range === DATE_SELECT_TYPES.CUSTOM) { // custom range
    //         start = searchParams.has("startDate") ? moment(searchParams.get("startDate")) : undefined;
    //         end = searchParams.has("endDate") ? moment(searchParams.get("endDate")).endOf("day") : undefined;
    //     }
    //     else { // predefined range (eg "last month"...)
    //         const dates = getDatesFromDateSelectType(range || undefined);
    //         start = dates.start;
    //         end = dates.end;
    //     }
    //     if (start && end) {
    //         const toAnnotateOnly = Number(searchParams.get("toAnnotate")) === 1;
    //         const trashType = searchParams.get("trashType") as TrashTypes | undefined;

    //         dispatch(ImagesToAnnotateArchive.generate(start, end, includeAnnotations, toAnnotateOnly, trashType));
    //     }
    // }

    const handleClose = () => {
        onClose();
    }

    // let dialogTitleKey = "include_annotations";
    // if (downloadingImagesToAnnotate) dialogTitleKey = "generating";
    // if (linkToImagesToAnnotate) dialogTitleKey = "success";

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: (theme) => theme.spacing(18)
                }
            }}
        >
            <DialogTitle>
                {t(`to_annotate.dialog.title.download_images_count`, { ns: Namespace.IMAGES, count: numberOfImagesToDowmload })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText mb={2}>
                    {t("to_annotate.dialog.body.instructions", { ns: Namespace.IMAGES })}
                </DialogContentText>
                
                <MarkDownloadedImages />

                <DownloadImagesSnippet />
            </DialogContent>
            {/* {downloadingImagesToAnnotate ? (    // generating images
                <DialogContent sx={{ 
                    minHeight: (theme) => theme.spacing(20),  
                    position: "relative",
                }}>
                    <SectionLoader />
                </DialogContent>
            ) : (linkToImagesToAnnotate ? (     // link to download
                <DialogActions sx={{
                    justifyContent: "center",
                }}>
                    <ActionButton
                        color='gradient'
                        startIcon={<DownloadIcon />}
                        href={linkToImagesToAnnotate}
                    >
                        {t("download", { ns: Namespaces.actions })}
                    </ActionButton>
                </DialogActions>
            ) : (                               // ask to include LabelMe annotations
                <DialogActions sx={{
                    justifyContent: "space-evenly",
                }}>
                    <Button
                        color="warning"
                        sx={{ fontSize: "1rem" }}
                        onClick={() => handleDownloadClicked(false)}
                    >
                        {t("do_not_include", { ns: Namespaces.actions })}
                    </Button>
                    <Button
                        color="info"
                        sx={{ fontSize: "1rem" }}
                        onClick={() => handleDownloadClicked(true)}
                    >
                        {t("include", { ns: Namespaces.actions })}
                    </Button>
                </DialogActions>
            ))} */}
        </Dialog >
    );
}

export default DownloadImagesToAnnotateDialog;