import { Box, Typography } from '@mui/material';
import { getGradient } from 'helpers/draw';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { OnboardingCardType } from './OnboardingSetupCard';
import SignpostIcon from '@mui/icons-material/SignpostOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

type OnboardingDisabledHeaderProps = {
    type: OnboardingCardType;
}

function OnboardingDisabledHeader(props: OnboardingDisabledHeaderProps) {
    const { type } = props;

    const { t } = useTranslation([Namespace.FORMS]);

    const Icon = type === "routes" ? SignpostIcon : CalendarMonthIcon;

    // const x = useAppSelector(state => state);
    // const dispatch = useAppDispatch();

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{
            }}
            >
            <Icon 
                sx={{
                    fontSize: theme => theme.spacing(6),
                    color: "rgba(0,0,0,0.4)",
                    mr: 3,
                }}
            />
            <Typography variant="body1" color="rgba(0,0,0,0.4)">
                {t(`onboarding.instructions.${type}`, { ns: Namespace.FORMS })}
            </Typography>
        </Box>
    );
}

export default OnboardingDisabledHeader;