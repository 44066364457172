import { Box, Divider, List } from '@mui/material';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { BatchesActions } from 'store/reducers/batches/list';
import { SortingMapActions } from 'store/reducers/batches/sorting_map';
import PlaceListItem from './PlaceListItem';


export default function PlacesListResults() {
    const placesIDs = useAppSelector(state => state.batches.sortingMap.filteredAddressesIDs);

    const dispatch = useAppDispatch();

    const handleItemClick = useCallback((placeID: string) => {
        dispatch(BatchesActions.unselectBatch());
        dispatch(SortingMapActions.selectAddressKey(placeID));
    }, [dispatch]);

    const handleMouseEnter = useCallback((placeID: string) => {
        dispatch(SortingMapActions.hoverAddressKey(placeID));
    }, [dispatch]);

    const handleMouseLeave = useCallback((placeID: string) => {
        dispatch(SortingMapActions.leavePlaceID(placeID));
    }, [dispatch]);


    return (
        <List
            sx={{
                height: (theme) => `calc(100% - ${theme.spacing(14)})`, // add padding at the bottom to fully display last item
                overflowY: "scroll",
                position: "relative",
            }}
        >
            {placesIDs.map((placeID, index) => (
                <Box key={placeID}>
                    <PlaceListItem
                        placeID={placeID}
                        onClick={handleItemClick}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    />

                    {index < placesIDs.length - 1 && (
                        <Divider
                            variant="middle"
                        />
                    )}
                </Box>
            ))}
        </List>
    );
}