// missionSpreadsheetSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { convertStringRefusalReasons } from "helpers/spreadsheet";

export interface SpreadsheetPreviewRow {
    id: number;
    houseNumber: string | null;
    street: string | null;
    city: string | null;
    postalCode: string | null;
    refusalReason: { [date: string]: string[] } | null;
    refusalReasonStr: string;
    lat: number | null;
    lng: number | null;
}

interface MissionSpreadsheetState {
    loadingSpreadsheet: boolean;
    spreadsheetDialogOpen: boolean;
    spreadsheetPreviewRows: SpreadsheetPreviewRow[];
    selectedSpreadsheetRows: SpreadsheetPreviewRow[];
    error?: string | null;
}

const initialSpreadsheetState: MissionSpreadsheetState = {
    loadingSpreadsheet: false,
    spreadsheetDialogOpen: false,
    spreadsheetPreviewRows: [],
    selectedSpreadsheetRows: []
};

export const missionSpreadsheetSlice = createSlice({
    name: 'mission_spreadsheet',
    initialState: initialSpreadsheetState,
    reducers: {
        startLoadingSpreadsheet: (state) => {
            state.loadingSpreadsheet = true;
            state.spreadsheetDialogOpen = true;
            state.error = null;
        },
        openSpreadsheetDialog: (state) => {
            state.spreadsheetDialogOpen = true;
            state.error = null;
        },
        closeSpreadsheetDialog: (state) => {
            state.spreadsheetDialogOpen = false;
            state.error = null;
        },
        stopLoadingSpreadsheet: (state) => {
            state.loadingSpreadsheet = false;
            state.error = null;
        },
        setSpreadsheetPreviewData: (state, action: PayloadAction<SpreadsheetPreviewRow[]>) => {
            state.spreadsheetPreviewRows = action.payload;
            state.loadingSpreadsheet = false;
        },
        setSelectedSpreadsheetRows: (state, action: PayloadAction<SpreadsheetPreviewRow[]>) => {
            state.selectedSpreadsheetRows = action.payload;
        },
        selectAllSpreadsheetRows: (state) => {
            state.selectedSpreadsheetRows = [...state.spreadsheetPreviewRows];
            state.loadingSpreadsheet = false;
        },
        clearSpreadsheetData: (state) => {
            state.spreadsheetPreviewRows = [];
            state.selectedSpreadsheetRows = [];
            state.loadingSpreadsheet = false;
        },
        updateSpreadsheetRow: (
            state,
            action: PayloadAction<SpreadsheetPreviewRow>
        ) => {
            let updatedRow = action.payload;
            updatedRow.refusalReason = convertStringRefusalReasons(updatedRow.refusalReasonStr);

            // Update in preview rows
            const index = state.spreadsheetPreviewRows.findIndex(row => row.id === updatedRow.id);
            if (index !== -1) {
                state.spreadsheetPreviewRows[index] = updatedRow;
            }
            // Also update in selected rows if it exists there
            const selectedIndex = state.selectedSpreadsheetRows.findIndex(row => row.id === updatedRow.id);
            if (selectedIndex !== -1) {
                state.selectedSpreadsheetRows[selectedIndex] = updatedRow;
            }
        }
    }
});

export const MissionSpreadsheetActions = missionSpreadsheetSlice.actions;

export default missionSpreadsheetSlice.reducer;
