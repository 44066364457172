import { Box, Grid } from "@mui/material";
import SectionLoader from "components/_include/SectionLoader";
import { QueryFilter } from "constants/types";
import { BatchDbData } from "models/Batch";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { usePartnerID } from 'hooks/partners';
import DemoBatchImage from "./DemoBatchImage";
import BatchesController from "controllers/batches";

type BatchDemoDetailProps = {

}

function BatchDemoDetail({ }: BatchDemoDetailProps) {
    const partnerID = usePartnerID();

    const imageURL = useAppSelector(state => state.batches.selected.data?.imageURL);

    const [loadingInProgress, setLoadingInProgress] = useState(false);

    const dispatch = useAppDispatch();

    /**
     * Load last batch upon page load, then every 10s
     */
    useEffect(() => {
        if (!partnerID) return;

        const loadLastBatch = async () => {
            if (loadingInProgress) return;

            setLoadingInProgress(true);
            let filters: QueryFilter<BatchDbData>[] = [];

            // if (batch) filters.push({ fieldPath: "timestamp", opStr: ">", value: Timestamp.fromMillis(batch.timestamp) });

            await dispatch(BatchesController.getLast(partnerID, filters));

            setLoadingInProgress(false);
        };

        loadLastBatch();

        const intervalID = setInterval(loadLastBatch, 5000);

        return () => clearInterval(intervalID);
    }, [partnerID]);

    if (!imageURL) return <SectionLoader />;

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            margin="auto"
            zIndex={9}
        >
            {/* <Paper
                sx={{
                    flex: 1,
                    margin: 1.25,
                    border: theme => `1px solid ${theme.palette.secondary.main}`,
                    overflow: "hidden",
                }}
            > */}
                <Grid container>
                    {/* <Grid item xs={12}>
                        <Grid
                            container
                            spacing={2}
                            px={3}
                            py={2}
                            sx={{
                                backgroundColor: (theme) => theme.palette.secondary.main,
                                color: "#ffffff",
                                fontFamily: "CircularStd",
                                fontSize: "1.05rem"
                            }}
                        >
                            <Grid item xs={12}>
                                {t("datetime_full_seconds", { ns: Namespaces.dates, datetime: batch.timestamp })}
                            </Grid>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={12} md={12}
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                        <DemoBatchImage
                            imageURL={imageURL}
                        />
                    </Grid>
                </Grid>
            {/* </Paper> */}
        </Box>
    );
}

export default BatchDemoDetail;