import { Box, IconButton, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector, useCanWrite } from "hooks/hooks";
import { usePartnerID } from 'hooks/partners';
import { selectCollectionById } from "store/reducers/collections/list";
import EditIcon from "@mui/icons-material/EditRounded";
import { useCallback, useState } from "react";
import CollectionScheduleRouteMenu from "./CollectionScheduleRouteMenu";
import Route from "models/Route";
import CollectionsController from "controllers/collections";
import AddRouteDialog from "components/Onboarding/OnboardingSetupCard/Routes/AddRouteDialog";

type LabelProps = {
    collectionID: string;
}

type EditState = {
    menuAnchor?: HTMLElement;
    addRouteDialogOpen: boolean;
}

export default function CollectionScheduleLabel({ collectionID }: LabelProps) {

    const partnerID = usePartnerID();
    const canEdit = useCanWrite(partnerID);

    const collection = useAppSelector(state => selectCollectionById(state, collectionID));
    const selectedRouteID = useAppSelector(state => selectCollectionById(state, collectionID)?.schedule.route.ID);

    const [editState, setEditState] = useState<EditState>({
        addRouteDialogOpen: false,
    });

    /** Toggle routes menu when "edit" button is clicked. */
    const handleEditClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        setEditState({
            ...editState,
            menuAnchor: e.currentTarget
        });
    }

    const dispatch = useAppDispatch();

    /** Update the collection with the selected itinerary. */
    const handleRouteSelected = useCallback((route: Route) => {
        if (!collection || route.ID === collection.schedule.route.ID) return;

        dispatch(CollectionsController.update(collection, {
            schedule: {
                ...collection.schedule,
                route,
                label: `${collection.schedule.truck.identificationNumber} - ${route.label}`,
            },
        }))
            .then(() => {
                setEditState({ // close menu
                    menuAnchor: undefined,
                    addRouteDialogOpen: false,
                });
            });
    }, [collection, dispatch, setEditState]);

    return (
        <Box
            display="flex"
            gap={.5}
            alignItems="center"
        >
            <Typography
                fontSize={14}
                sx={{
                    opacity: 0.9,
                }}
            >
                {collection?.schedule.label || "Unknown Truck - Unknown Route"}
            </Typography>

            {canEdit && (
                <>
                    <IconButton
                        color="white"
                        onClick={handleEditClicked}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>

                    <CollectionScheduleRouteMenu
                        collectionID={collectionID}
                        anchorEl={editState.menuAnchor}
                        onClose={() => setEditState({ ...editState, menuAnchor: undefined })}
                        selectedRouteID={selectedRouteID ?? ""}
                        onRouteSelected={handleRouteSelected}
                        onAddRouteClicked={() => setEditState({ menuAnchor: undefined, addRouteDialogOpen: true })}
                    />

                    <AddRouteDialog
                        open={editState.addRouteDialogOpen}
                        onClose={() => setEditState({ ...editState, addRouteDialogOpen: false })}
                        onRouteSaved={handleRouteSelected}
                    />
                </>
            )}
        </Box>
    )
}