import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Namespace } from "locales/translations";
import CollectionSchedule from "models/CollectionSchedule";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SelectedCollectionScheduleActions } from "store/reducers/collections_schedules/selected";
import { selectAllRoutes } from "store/reducers/routes/list";

export default function ScheduleRouteSelect() {
    const { t } = useTranslation([Namespace.GLOSSARY]);

    const routes = useAppSelector(state => selectAllRoutes(state));
    const route = useAppSelector(state => state.collectionsSchedules.selected.editingRoute);

    const dispatch = useAppDispatch();

    const selectRoute = (routeID: string) => {
        const route = routes.find(r => r.ID === routeID);
        if (route) {
            const routeData: CollectionSchedule["route"] = {
                ID: route.ID,
                type: route.type,
                label: route.label,
            };
            dispatch(SelectedCollectionScheduleActions.setRoute(routeData));
        }
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="route-select-label">
                {t("route", { ns: Namespace.GLOSSARY })}
            </InputLabel>
            <Select
                labelId="route-select-label"
                value={route?.ID ?? ""}
                label={t("route", { ns: Namespace.GLOSSARY })}
                onChange={(e) => selectRoute(e.target.value)}
            >
                {routes.map(route => (
                    <MenuItem key={route.ID} value={route.ID}>
                        {route.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}