import { LoadingButton } from "@mui/lab";
import CollectionsController from "controllers/collections";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/hooks";

export default function ConfirmUploadRFIDButton() {
    const { t } = useTranslation([Namespace.ACTIONS]);

    const collection = useAppSelector(state => state.collections.selected.data);
    const loading = useAppSelector(state => state.collections.rfid.loading);
    const selectedDatetimeColumn = useAppSelector(state => state.collections.rfid.selectedColumns.datetime);
    const selectedRFIDColumn = useAppSelector(state => state.collections.rfid.selectedColumns.rfid);
    const datetimeFormat = useAppSelector(state => state.collections.rfid.datetimeFormat);

    const dispatch = useAppDispatch();

    const uploadRFID = () => {
        if (collection && selectedDatetimeColumn && selectedRFIDColumn && datetimeFormat) {
            dispatch(CollectionsController.setRFID(collection, selectedDatetimeColumn, selectedRFIDColumn, datetimeFormat));
        }
    }

    return (
        <LoadingButton
            color="success"
            sx={{
            }}
            onClick={uploadRFID}
            loading={loading}
            disabled={!selectedDatetimeColumn || !selectedRFIDColumn}
        >
            <span>{t("confirm", { ns: Namespace.ACTIONS })}</span>
        </LoadingButton>
    );
}