import { Checkbox } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { BatchesActions, selectBatchById } from "store/reducers/batches/list";

type ActionProps = {
    batchID: string;
}

export default function BatchImageItemAction({ batchID }: ActionProps) {
    const selectable = useAppSelector(state => state.batches.list.hidingMany);

    const selected = useAppSelector(state => Boolean(selectBatchById(state, batchID)?.selected));
    
    const dispatch = useAppDispatch();
    
    const toggleCheck = (checked: boolean) => {
        if (checked) dispatch(BatchesActions.selectMany(batchID)); // select all batches up to this one
        else dispatch(BatchesActions.unselectOne(batchID)); // unselect this single batch
    }
    
    if (!selectable) return null;

    return (
        <Checkbox
            color="white"
            checked={selected}
            sx={{
                color: "rgba(255, 255, 255, 0.5)"
            }}
            onChange={(e) => toggleCheck(e.target.checked)} 
            inputProps={{ 'aria-label': 'controlled' }}
            />
    )
}