import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import { AddressesClusteringLevel, selectAllBatchesByAddresses } from "store/reducers/batches/by_addresses";
import { useAppSelector } from "hooks/hooks";
import { BinType } from "models/Batch";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { upperFirst } from "lodash";
import { TFunction } from "i18next";
import ColumnHeaderWithTooltip, { ColumnTooltip } from "components/_include/DataGrid/ColumnHeaderWithTooltip";

export enum AddressesExplorerColumn {
    HOUSE_NUMBER = "house_number",
    STREET = "street",
    CITY = "city",
    // RFID = "rfid",
    COLLECTED = "collected",
    COLLECTED_WITH_ERRORS = "collected_with_errors",
    RECURRENCE = "recurrence",
    SMALL_BINS = "small_bins",
    LARGE_BINS = "large_bins",
    ERRORS_COUNT = "number_of_errors",
    ERRORS = "errors",
    AREAS = "areas",
    ACTIONS = "actions"
}

type AddressesExplorerRow = {
    id: string;
    [AddressesExplorerColumn.HOUSE_NUMBER]: string | undefined;
    [AddressesExplorerColumn.STREET]: string;
    [AddressesExplorerColumn.CITY]: string;
    [AddressesExplorerColumn.COLLECTED]: number;
    [AddressesExplorerColumn.COLLECTED_WITH_ERRORS]: number;
    [AddressesExplorerColumn.RECURRENCE]: number;
    [AddressesExplorerColumn.SMALL_BINS]: number;
    [AddressesExplorerColumn.LARGE_BINS]: number;
    [AddressesExplorerColumn.ERRORS_COUNT]: number;
    [AddressesExplorerColumn.ERRORS]: string;
};

export type AddressesExplorerGridColDef = GridColDef & ColumnTooltip;

const COLUMNS_CONFIG: AddressesExplorerGridColDef[] = [
    {
        field: AddressesExplorerColumn.HOUSE_NUMBER,
        width: 84,
        type: 'string',
    },
    {
        field: AddressesExplorerColumn.STREET,
        minWidth: 220,
        flex: 1,
        type: 'string',
    },
    {
        field: AddressesExplorerColumn.CITY,
        minWidth: 120,
        type: 'string',
    },
    {
        field: AddressesExplorerColumn.COLLECTED,
        width: 140,
        type: 'number',
    },
    {
        field: AddressesExplorerColumn.COLLECTED_WITH_ERRORS,
        minWidth: 180,
        type: 'number',
    },
    {
        field: AddressesExplorerColumn.RECURRENCE,
        minWidth: 124,
        type: 'number',
        valueFormatter: (value: number) => `${(value * 100).toFixed(0)}%`,
    },

    {
        field: AddressesExplorerColumn.SMALL_BINS,
        minWidth: 124,
        type: 'number',
    },

    {
        field: AddressesExplorerColumn.LARGE_BINS,
        minWidth: 124,
        type: 'number',
    },

    {
        field: AddressesExplorerColumn.ERRORS_COUNT,
        minWidth: 156,
        type: 'number',
    },
    {
        field: AddressesExplorerColumn.ERRORS,
        minWidth: 200,
        flex: 2,
        type: 'string',
    },
    {
        field: AddressesExplorerColumn.AREAS,
        minWidth: 108,
        type: 'string',
    },
    {
        field: AddressesExplorerColumn.ACTIONS,
        minWidth: 120,
        type: 'actions',
    },
];


const getAddressesExplorerColumn = (column: AddressesExplorerGridColDef, context: { t: TFunction }) => {
    const { t } = context;

    const c: AddressesExplorerGridColDef = {
        ...column,
        headerName: upperFirst(t(column.field, {
            ns: [Namespace.EXPLORER],
            count: 2, // make headers plural
        })),
        ...(column.field === AddressesExplorerColumn.ACTIONS && {
            getActions: (params: GridRowParams<AddressesExplorerRow>) => [
                // <Link
                //     key="view-image"
                //     target="_blank"
                //     to={{
                //         pathname: `/${Section.SINGLE_IMAGE}`,
                //         search: `?partnerID=${params.row.partnerID}&imageID=${params.id}`,
                //     }}
                // >
                //     <ActionButton
                //         color="secondary"
                //         size="compact"
                //     >
                //         {t("view", { ns: Namespace.ACTIONS })}
                //     </ActionButton>
                // </Link>
            ],
        }),
        renderHeader: (params) => (
            <ColumnHeaderWithTooltip
                {...params}
                tooltip={t(`${column.field}_tooltip`, { ns: Namespace.EXPLORER })}
            />
        ),
    };
    return c;
}

/**
 * Returns the columns for the addresses explorer table.
 * 
 * If the clustering level is set to "street", the house number column is hidden.
 */
export const useAddressesExplorerColumns = (): GridColDef[] => {
    const { t } = useTranslation([Namespace.EXPLORER]);

    const clusteringLevel = useAppSelector(state => state.batches.byAddresses.clusterBy);

    let columns = [...COLUMNS_CONFIG];

    if (clusteringLevel === AddressesClusteringLevel.STREET) {
        columns = columns.filter(column => column.field !== AddressesExplorerColumn.HOUSE_NUMBER);
    }

    return useMemo(() => columns.map(column => getAddressesExplorerColumn(column, { t })), [t, clusteringLevel]);
}

/**
 * Returns the data (rows) for the addresses explorer table.
 * 
 * If the clustering level is set to "street", the house number column is hidden.
 */
export const useAddressesExplorerData = (): AddressesExplorerRow[] => {
    const { t } = useTranslation([Namespace.MISSIONS, Namespace.ACTIONS]);

    const batchesByAddresses = useAppSelector(selectAllBatchesByAddresses);

    return useMemo(() => batchesByAddresses.map(batchesByAddress => {
        const displayedErrorsStr = Object.entries(batchesByAddress.errors)
            .filter(([_, count]) => count > 0)
            .map(([trashType, count]) =>
                t(`${trashType}_count`, { ns: Namespace.WASTES, context: 'small', count: count })
            ).join(', ');

        if (batchesByAddress.addressKey === "66-76 Av. Voltaire, 93190 Livry-Gargan") {
            console.log(batchesByAddress.recurrence);
        }

        const largeBinsCount = batchesByAddress.binsCounts[BinType.LARGE] ?? 0;
        const smallBinsCount = Object.entries(batchesByAddress.binsCounts).reduce((acc, [binType, count]) => {
            if (binType !== BinType.LARGE) acc += count; // count all bins that are not "large" as "small"
            return acc;
        }, 0);

        return {
            id: batchesByAddress.addressKey,
            [AddressesExplorerColumn.HOUSE_NUMBER]: batchesByAddress.address.houseNumber,
            [AddressesExplorerColumn.STREET]: batchesByAddress.address.street,
            [AddressesExplorerColumn.CITY]: batchesByAddress.address.city,
            [AddressesExplorerColumn.COLLECTED]: batchesByAddress.collected,
            [AddressesExplorerColumn.COLLECTED_WITH_ERRORS]: batchesByAddress.collectedWithErrors,
            [AddressesExplorerColumn.RECURRENCE]: batchesByAddress.recurrence,
            [AddressesExplorerColumn.SMALL_BINS]: smallBinsCount,
            [AddressesExplorerColumn.LARGE_BINS]: largeBinsCount,
            [AddressesExplorerColumn.ERRORS_COUNT]: batchesByAddress.errorsCount,
            [AddressesExplorerColumn.ERRORS]: displayedErrorsStr,
            [AddressesExplorerColumn.AREAS]: batchesByAddress.areas.join(', '),
        };
    }), [t, batchesByAddresses]);
}; 