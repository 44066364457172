import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useAppSelector } from "hooks/hooks";

type HiddenSelectProps = {
    parentLoading: boolean;
}

export enum DISPLAY_BATCHES {
    HIDE_HIDDEN = "0",
    SHOW_BOTH = "1",
    ONLY_HIDDEN = "2",
};

function HiddenSelect(props: HiddenSelectProps) {
    const { parentLoading } = props;

    const loading = parentLoading;

    const { t } = useTranslation([Namespace.FORMS]);

    const [searchParams, setSearchParams] = useSearchParams();

    const showHidden = searchParams.get("hidden");

    const handleHiddenSelected = (showHidden: string) => {
        searchParams.set("hidden", showHidden);
        setSearchParams(searchParams);
    };

    const options: Option[] = Object.values(DISPLAY_BATCHES).map(value => ({
        label: t(`manage_batches.show_hidden.${value}`, { ns: Namespace.FORMS }),
        value: value,
    }));

    return (
        <DropdownMenu
            id="display-select-menu"
            title={t(`manage_batches.show_hidden.title`, { ns: Namespace.FORMS })}
            loading={loading}
            values={options}
            defaultValue={showHidden || DISPLAY_BATCHES.HIDE_HIDDEN}
            startIcon={<VisibilityOffIcon />}
            onChange={(value) => handleHiddenSelected(value)}
        />
    );
}

export default HiddenSelect;