import Collaborator, { migrateRoleToPermissions } from "models/Collaborator";
import { LoadableContext } from "store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CollaboratorContext = LoadableContext & {
    data: Collaborator | null;
};

const initialState: CollaboratorContext = {
    loading: false,
    error: null,
    data: null,
};

export const selectedCollaboratorSlice = createSlice({
    name: 'selected_collaborator',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        setSelected: (state, { payload: collaborator }: PayloadAction<Collaborator | null>) => {
            state.loading = false;
            if (collaborator) {
                // Apply role to permissions migration if needed
                const migratedCollaborator = migrateRoleToPermissions(collaborator) as Collaborator;
                state.data = migratedCollaborator;
            } else {
                state.data = null;
            }
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedCollaboratorActions = selectedCollaboratorSlice.actions;

const SelectedCollaboratorReducer = selectedCollaboratorSlice.reducer;

export default SelectedCollaboratorReducer;