import Batch, { BinsCounts } from "models/Batch";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { TrashCount } from "constants/trash";

/** Util type to manipulate batches stats aggregated by address, in order to display them in a DataGrid. */
export type BatchesByAddress = {
    /** Unique identifier for this address. */
    addressKey: string;
    /** Components of the address (house number, street, city...). */
    address: NonNullable<Batch["address"]>;
    /** Areas this address belongs to. */
    areas: string[];
    /** Number of different days this address was collected. */
    collected: number;
    /** Number of different days this address was collected and had at least 1 error. */
    collectedWithErrors: number;
    /** 
     * Indicate each day at least 1 bin was collected at this address (keys), and whether it contained an error (values).
     * @example { "2025-03-10": true } // indicates there was at least 1 error collected on 10/03/2025 
     */
    daysCollectedWithError: { [date: string]: boolean };
    /** Number of bins of each type collected at this address. */
    binsCounts: BinsCounts;
    /** Portion of days this address was collected with at least 1 error. */
    recurrence: number;
    /** Total number of errors collected at this address. */
    errorsCount: number;
    /** Object detailing which errors were found at this address. */
    errors: Partial<TrashCount>;
}

const batchesByAddressesAdapter = createEntityAdapter<BatchesByAddress>({
    selectId: ({ addressKey }) => addressKey,
    // sort by collectedWithErrors of errors desc
    sortComparer: (address1, address2) => address2.collectedWithErrors - address1.collectedWithErrors, 
});

export enum AddressesClusteringLevel {
    HOUSE_NUMBER = "houseNumber",
    STREET = "street",
}

type BatchesByAddressContext = {
    loading: boolean;
    error: string | null;
    clusterBy: AddressesClusteringLevel;
};

const initialState: BatchesByAddressContext = {
    loading: false,
    error: null,
    clusterBy: AddressesClusteringLevel.HOUSE_NUMBER,
};

export const batchesByAddressesSlice = createSlice({
    name: 'batches_by_addresses',
    initialState: batchesByAddressesAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            batchesByAddressesAdapter.removeAll(state);
            state.loading = true;
            state.error = null;
        },
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        setList: (state, { payload: { batches } }: PayloadAction<{ batches: BatchesByAddress[] }>) => {
            state.loading = false;
            batchesByAddressesAdapter.setAll(state, batches);
        },
        addToList: (state, { payload: { batches } }: PayloadAction<{ batches: BatchesByAddress[] }>) => {
            batchesByAddressesAdapter.addMany(state, batches);
        },
        resetList: (state) => {
            batchesByAddressesAdapter.removeAll(state);
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        setClusteringLevel: (state, { payload: clusteringLevel }: PayloadAction<AddressesClusteringLevel>) => {
            state.clusterBy = clusteringLevel;
        }
    },
});

export const BatchesByAddressesActions = batchesByAddressesSlice.actions;

export const {
    selectAll: selectAllBatchesByAddresses,
    selectById: selectBatchesByAddress,
    selectIds: selectBatchesByAddressesIds,
} = batchesByAddressesAdapter.getSelectors((state: RootState) => state.batches.byAddresses)

const BatchesByAddressesReducer = batchesByAddressesSlice.reducer;

export default BatchesByAddressesReducer;