import moment from "moment";
import { useEffect, useState } from "react";
import { Namespace } from "locales/translations";
import { DatesInterval, DatesRangeType, adjustToDefaultTimezone } from "helpers/dates";
import SectionLoader from "components/_include/SectionLoader";
import { Box, Button, Menu, MenuItem, useTheme, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DatePicker } from 'react-rainbow-components';
import { useAppSelector } from "hooks/hooks";
import { selectAllCollectionsSchedules } from "store/reducers/collections_schedules/list";
import { Weekday } from "models/CollectionSchedule";

type DatesSelectProps = {
    parentLoading: boolean;
    defaultValue?: DatesRangeType;
}

function DatesSelect(props: DatesSelectProps) {
    const { parentLoading, defaultValue, } = props;

    const loading = parentLoading;
    const selectedTruck = useAppSelector(state => state.trucks.selected.data);

    const firstCollection: Date | undefined = selectedTruck?.firstCollection ? moment(selectedTruck.firstCollection).toDate() : undefined;
    const lastCollection: Date | undefined = selectedTruck?.lastCollection ? moment(selectedTruck.lastCollection).toDate() : undefined;

    const [searchParams, setSearchParams] = useSearchParams();

    const theme = useTheme();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.COMMONS,]);

    let defaultDateSelectType = defaultValue || DatesRangeType.CUSTOM;
    let defaultStartDate: moment.Moment | null = null;
    let defaultEndDate: moment.Moment | null = null;
    const dateSelect = searchParams.get("dateSelect");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    if (dateSelect) {
        if (dateSelect === DatesRangeType.CUSTOM) {
            if (startDate && endDate) {
                const tmpStart = moment(startDate);
                const tmpEnd = moment(endDate);

                if (tmpStart.isValid() && tmpEnd.isValid()) {
                    defaultDateSelectType = DatesRangeType.CUSTOM;
                    defaultStartDate = tmpStart;
                    defaultEndDate = tmpEnd;
                }
            }
        }
        // /*else */if (dateSelect === DATE_SELECT_TYPES.SINCE_INSTALLATION) {
        //     if (startDate) {
        //         const tmpStart = moment(startDate);

        //         if (tmpStart.isValid()) {
        //             defaultDateSelectType = DATE_SELECT_TYPES.SINCE_INSTALLATION;
        //             defaultStartDate = tmpStart;
        //         }
        //     }
        // }
        // else {
        for (let dateSelectType of Object.values(DatesRangeType)) {
            if (dateSelectType === dateSelect) {
                defaultDateSelectType = dateSelectType;
            }
        }
        // }
    }

    const [dates, setDates] = useState<DatesInterval>({
        start: defaultStartDate,
        end: defaultEndDate,
    });
    const { start, end } = dates;

    const [dateSelectType, setDateSelectType] = useState(defaultDateSelectType);

    useEffect(() => {
        if (start && end) {
            const startDay = start.format("YYYY-MM-DD");
            const endDay = end.format("YYYY-MM-DD")

            if (dateSelectType === DatesRangeType.CUSTOM) {
                searchParams.set("dateSelect", dateSelectType);
                searchParams.set("startDate", startDay);
                searchParams.set("endDate", endDay);
                setSearchParams(searchParams);
            }
        }
    }, [dates]);

    useEffect(() => {
        searchParams.set("dateSelect", dateSelectType);
        setSearchParams(searchParams);
    }, [dateSelectType]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);

    // close menu every time a new date is selected
    useEffect(() => {
        handleClose();
    }, [dateSelectType]);

    const handleCustomDateSelected = (value: Date | Date[]) => {        
        if (Array.isArray(value)) {            
            // need to adjust to Europe/Paris in case the user's computer is on a different timezone
            const start = adjustToDefaultTimezone(moment(value[0]));
            const end = adjustToDefaultTimezone(moment(value[1]));

            if (!dates.end && value.length == 1 && start.unix() === dates.start?.unix()) {
                // same start and end day
                setDates({
                    ...dates,
                    end: start,
                });
                document.getElementById("modal-close-button")?.click(); // close datepicker
            }
            else if (value.length == 1) { // start date selected
                setDates({
                    start: start,
                    end: null,
                });
            }
            else if (value.length == 2) { // end date selected
                setDates({
                    ...dates,
                    end: end,
                });
            }
        }
    }
    
    // convert values back to user's timezone
    const rangeValue = start 
        ? (
            end ? [
                adjustToDefaultTimezone(start, true).toDate(), 
                adjustToDefaultTimezone(end, true).toDate()
            ] : [
                adjustToDefaultTimezone(start).toDate()
            ]
        ) : [];

    const collectionsSchedules = useAppSelector(state => selectAllCollectionsSchedules(state));

    useEffect(() => {
        // mark sortable days on calendar
        let truckSchedules = [...collectionsSchedules];
        if (selectedTruck) truckSchedules = truckSchedules.filter(schedule => schedule.truck.ID === selectedTruck.ID);

        const STYLE_BLOCK_ID = "sortableCollectionDaysCalendarStyling";
        let styleBlock = document.getElementById(STYLE_BLOCK_ID);
        if (!styleBlock) {
            document.head.insertAdjacentHTML("beforeend", `<style id="${STYLE_BLOCK_ID}"></style>`);
            styleBlock = document.getElementById(STYLE_BLOCK_ID);
        }
        let sortableCollectionsWeekdays: Weekday[] = [];
        for (let schedule of truckSchedules) {
            for (let weekday of Object.keys(schedule.calendar)) {
                if (!sortableCollectionsWeekdays.includes(weekday as Weekday)) {
                    sortableCollectionsWeekdays.push(weekday as Weekday);
                }
            }
        }
        let sortableCollectionsDaysStyling = "";
        for (let weekday of sortableCollectionsWeekdays) {
            sortableCollectionsDaysStyling += `tbody tr td:nth-child(${(Number(weekday) + 1) % 7}) button {
                border: 1px solid ${theme.palette.success.main};
            }
            `;
            styleBlock!.innerHTML = sortableCollectionsDaysStyling;
        }
    }, [selectedTruck, collectionsSchedules]);

    return (
        <Box
            // textAlign="center"
            position="relative"
            sx={{
                pointerEvents: "all",
            }}
        >
            <Box
                display="inline-block"
                position="relative"
            >
                {loading && (
                    <Box
                        position="absolute"
                        sx={{
                            left: 22,
                            top: "50%",
                        }}
                    >
                        <SectionLoader size={22} />
                    </Box>
                )}

                <Button
                    id="open-dates-button"
                    disabled={loading}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    aria-controls="select-dates-menu"
                    aria-haspopup="true"
                    aria-expanded={isOpen ? 'true' : undefined}
                    variant="contained"
                    startIcon={<AccessTimeIcon />}
                    endIcon={<ArrowDropDownIcon />}
                    color="white"
                    sx={{
                        fontWeight: 400,
                    }}  
                >
                    {t(dateSelectType || "date", { ns: Namespace.COMMONS })}
                </Button>
                <Menu
                    id="select-dates-menu"
                    anchorEl={anchorEl}
                    open={isOpen}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'open-dates-button',
                    }}
                >
                    {Object.values(DatesRangeType).map((type: string) => (
                        <MenuItem
                            key={type}
                            data-value={type}
                            onClick={(e) => setDateSelectType(e.currentTarget.dataset.value as DatesRangeType || defaultDateSelectType)}
                        >
                            {t(type, { ns: Namespace.COMMONS })}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
            <Box
                // textAlign="center"
                position="relative"
                mt={1}
            >
                {dateSelectType === DatesRangeType.CUSTOM &&
                    <Box>
                        <DatePicker
                            placeholder={t("select_dates.range", { ns: Namespace.ACTIONS })}
                            selectionType="range"
                            formatStyle="medium"
                            variant="single"
                            locale="fr"
                            value={rangeValue}
                            onChange={(value: Date | Date[]) => handleCustomDateSelected(value)}
                            className="datepicker-range"
                            minDate={firstCollection}
                            maxDate={lastCollection}
                            icon={<></>} // no icon
                            style={{
                                minWidth: 208,
                            }}
                        />
                    </Box>
                }
            </Box>
        </Box >
    );
}

export default DatesSelect;