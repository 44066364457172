import SearchRounded from '@mui/icons-material/SearchRounded';
import { OutlinedInput } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { SortingMapActions } from 'store/reducers/batches/sorting_map';

type PlacesSearchBarProps = {

}

export default function PlacesSearchBar({ }: PlacesSearchBarProps) {
    const value = useAppSelector(state => state.batches.sortingMap.filterString);
    
    const dispatch = useAppDispatch();
    const onChange = (value: string) => dispatch(SortingMapActions.setFilterString(value));
    
    const { t } = useTranslation([Namespace.MAPS]);

    return (
        <OutlinedInput 
            startAdornment={<SearchRounded sx={{ color: "rgba(0,0,0,.54)" }} />}
            placeholder={t("search_address", { ns: Namespace.MAPS })}
            size='small'
            value={value}
            onChange={(e) => onChange(e.target.value)}
            sx={{
                pl: 1,
                fontSize: '0.875rem',
            }}
            />
    );
}