import { Box, Container } from "@mui/material";
import PageTitle from "components/_include/PageTitle";
import { AddressesExplorerDataGrid } from "./AddressesExplorerDataGrid";
import AddressesExplorerFiltersAndActions from "./FiltersAndActions/AddressesExplorerFiltersAndActions";
import { useAppDispatch } from "hooks/hooks";
import { BatchesActions } from "store/reducers/batches/list";
import { useOnMount } from "hooks/layout";
import { BatchesByAddressesActions } from "store/reducers/batches/by_addresses";
import { useOnPartnerChange } from "hooks/partners";

/**
 * Page presenting raw data to the user, with possibility to filter, search and export.
 */
function AddressesExplorerPage() {

    const dispatch = useAppDispatch();

    const cleanupData = () => {
        dispatch(BatchesActions.resetList());
        dispatch(BatchesByAddressesActions.resetList());
    };

    /**
     * Cleanup batches when the page is mounted and unmounted.
     */
    useOnMount(cleanupData, cleanupData);

    /**
     * Cleanup batches when the selected partner changes.
     */
    useOnPartnerChange(cleanupData);

    return (
        <Container
            maxWidth="xl"
        >
            <PageTitle />

            <AddressesExplorerFiltersAndActions />

            <Box
                sx={{
                    height: theme => `calc(100vh - ${theme.spacing(8)})`,
                }}
            >
                <AddressesExplorerDataGrid />
            </Box>
        </Container>
    );
};

export default AddressesExplorerPage;
