import { Tooltip, useTheme } from "@mui/material";
import MarkerIcon from "components/_include/Icons/MarkerIcon";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useEffect } from "react";
import { SortingMapActions } from "store/reducers/batches/sorting_map";
import { slugify } from "helpers/strings";
import { AdvancedMarker } from "@vis.gl/react-google-maps";

type AddressMarkerProps = {
    lat: number;
    lng: number;
    addressKey: string;
    address: string;
    onClick: (lat: number, lng: number) => void;
}

export default function AddressMarker({ lat, lng, addressKey, address, onClick }: AddressMarkerProps) {
    const theme = useTheme();

    const dispatch = useAppDispatch();

    const hovered = useAppSelector(state => state.batches.sortingMap.hoveredAddressKey === addressKey);
    const markerSize = hovered ? 40 : 28;

    const addressClicked = useAppSelector(state => state.batches.sortingMap.selectedAddressKey === addressKey);
    useEffect(() => {
        dispatch(SortingMapActions.selectAddressKey(null)); // remove selected place ID to only trigger once
        if (addressClicked) onClick(lat, lng); // zoom on marker when address clicked in list
    }, [addressClicked]);

    const slugedAddressKey = slugify(addressKey);

    return (
        <AdvancedMarker
        position={{ lat, lng }}
        onClick={() => onClick(lat, lng)}
        >
            <svg width={0} height={0}>
                <linearGradient id={`linearColors-${slugedAddressKey}`} x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor={theme.palette[hovered ? "secondary" : "error"].light} />
                    <stop offset={1} stopColor={theme.palette[hovered ? "secondary" : "error"].dark} />
                </linearGradient>
            </svg>
            <Tooltip
                title={address}
                placement="top"
                arrow
            >
                <MarkerIcon
                    sx={{
                        mt: `${-markerSize}px`,
                        ml: `${-markerSize / 2}px`,
                        filter: `drop-shadow(2px 2px 2px rgb(0, 0, 0, .2))`,
                        fill: `url(#linearColors-${slugedAddressKey})`,
                        cursor: "pointer",
                        fontSize: markerSize,
                    }}
                />
            </Tooltip>
        </AdvancedMarker>
    )
}