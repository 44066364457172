import { Timestamp } from "firebase/firestore";
import { Permission, UserRole, ROLE_TO_PERMISSIONS_MAP } from "./User";

/**
 * Database representation of a collaborator
 */
export type CollaboratorDbData = {
    /** First name of the user. */
    firstName: string;
    /** Last name of the user. */
    lastName: string;
    /** Email address of the user. */
    email: string;
    /**
     * Permissions assigned to the collaborator for the partner.
     */
    permissions: Permission[];
    /** Date of adding the creator to the partner. */
    addedAt: Timestamp;
    /** @deprecated Legacy role field, maintained for backward compatibility */
    role?: Exclude<UserRole, UserRole.ADMIN>;
}

export type NewCollaborator = Omit<CollaboratorDbData, "addedAt" | "role"> & {
    /** @deprecated Legacy role field, will be converted to permissions */
    role?: Exclude<UserRole, UserRole.ADMIN>;
};

/**
 * Model to manipulate Firebase user's data.
 */
export type Collaborator = Omit<CollaboratorDbData, "addedAt" | "role"> & {
    /** Firebase Auth ID of the user. */
    ID: string;
    /** Date of adding the creator to the partner as milliseconds. */
    addedAt: number;
    /** @deprecated Legacy role field, maintained for backward compatibility */
    role?: Exclude<UserRole, UserRole.ADMIN>;
}

export type APICollaborator = Omit<Collaborator, "addedAt"> & {
    /** Date of adding the creator to the partner as a formatted string. */
    addedAt: string;
}

/**
 * @deprecated Helper function to migrate from role to permissions
 * @param collaborator Collaborator data that may contain a role
 * @returns Collaborator with permissions derived from role if needed
 */
export function migrateRoleToPermissions(collaborator: Partial<Collaborator>): Partial<Collaborator> {
    const result = { ...collaborator };

    // If role exists but no permissions, convert role to permissions
    if (result.role && (!result.permissions || result.permissions.length === 0)) {
        result.permissions = ROLE_TO_PERMISSIONS_MAP[result.role];
    }

    return result;
}

export default Collaborator;