import { useAppSelector } from "hooks/hooks";
import { selectAllMissionAddresses } from "store/reducers/missions/addresses/list";
import MissionMarker from "./MissionMarker";

type MissionMarkerProps = {
    onClick: (addressKey: string) => void;
};

/**
 * List of markers for each address added to a mission.
 */
export default function MissionAddressMarkers(props: MissionMarkerProps) {
    const { onClick } = props;
    const missionAddresses = useAppSelector(selectAllMissionAddresses);

    return (
        <>
            {missionAddresses.map((address) => (
                <MissionMarker key={address.ID} onClick={onClick} address={address} />
            ))}
        </>
    );
}
