import { ComputedSerie, DatumValue, LineProps, Point, ResponsiveLine } from "@nivo/line";
import SectionLoader from 'components/_include/SectionLoader';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import _ from 'lodash';
import moment from 'moment';
import ChartTitle from './ChartTitle';
import ChartTooltip from './ChartTooltip';
import { API_TIMESTAMP_FORMAT } from 'constants/dates';
import { useAppSelector } from "hooks/hooks";

type ChartProps = {
    dataKey: string;
    translationKey: string;
    color: string;
    axisLeftFormat: string;
    dataSelector: (state: any) => any[];
} & Pick<LineProps, "curve" | "yFormat" | "enableArea">;

type PointType = {
    x: DatumValue;
    y: number;
};

type LineType = {
    id: string;
    color: string;
    data: PointType[];
};

const TICK_WIDTH = 92;

function LineChart({
    dataKey,
    translationKey,
    color,
    axisLeftFormat,
    dataSelector,
    ...lineProps
}: ChartProps) {
    // Fetching data from the Redux store using the provided dataSelector
    const dataFromStore = useAppSelector(dataSelector);
    const loading = useAppSelector(state => state.trucks.list.loading || state.areas.list.loading || state.stats.loading || state.collections.list.loading);
    const { t } = useTranslation([Namespace.CHARTS, Namespace.DATES]);

    const data: LineType[] = useMemo(() => {
        let evolution: PointType[] = [];

        // If data exists, format it into chart-friendly format
        if (dataFromStore) {
            dataFromStore.forEach(item => {
                evolution.push({
                    x: moment(item.date, API_TIMESTAMP_FORMAT).toDate(),
                    y: item[dataKey],
                });
            });
        }
        return [{
            id: dataKey,
            color: color,
            data: evolution,
        }];
    }, [dataFromStore, dataKey]);

    // Adapt bottom axis ticks to chart width
    const wrapperRef = useRef<HTMLDivElement>();
    const wrapperWidth = wrapperRef.current?.offsetWidth;
    const [bottomTickValues, setBottomTickValues] = useState<DatumValue[]>([]);

    // Dynamically adjust bottom axis ticks based on the container width
    useEffect(() => {
        if (!wrapperWidth) setBottomTickValues([]);
        else {
            let xValues = _.uniq(data.map((d) => d.data.map((x) => x.x)).reduce((acc, data) => acc.concat(data), []));
            let gridWidth = Math.ceil(wrapperWidth / xValues.length);
            let tickDistance = Math.floor(TICK_WIDTH / gridWidth);
            setBottomTickValues(tickDistance === 0 ? xValues : xValues.filter((_, i) => i % tickDistance === 0));
        }
    }, [TICK_WIDTH, wrapperWidth, data]);

    return (
        <Box justifyContent="center" my={2}>
            <ChartTitle>{t(`${translationKey}.title`, { ns: Namespace.CHARTS })}</ChartTitle>

            <Box ref={wrapperRef} sx={{ width: "100%", height: (theme) => theme.spacing(38), position: "relative" }}>
                {loading ? (
                    <SectionLoader />
                ) : (
                    <ResponsiveLine
                        data={data}
                        colors={(datum) => datum.color}
                        {...lineProps}
                        margin={{ top: 24, right: 32, bottom: 40, left: 48 }}
                        xScale={{ type: 'point' }}
                        yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickValues: bottomTickValues,
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: t(`${translationKey}.bottomAxis`, { ns: Namespace.CHARTS }),
                            legendOffset: 36,
                            legendPosition: 'middle',
                            format: (value) => t("date_day", { ns: Namespace.DATES, date: moment(value) }),
                        }}
                        axisLeft={{
                            format: axisLeftFormat,
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: t(`${translationKey}.leftAxis`, { ns: Namespace.CHARTS }),
                            legendOffset: -40,
                            legendPosition: 'middle',
                        }}
                        pointSize={10}
                        pointColor={(serie: ComputedSerie) => "#FFFFFF"}
                        pointBorderWidth={2}
                        pointBorderColor={(point: Point) => point.serieColor}
                        pointLabelYOffset={0}
                        useMesh={true}
                        enableSlices="x"
                        sliceTooltip={({ slice }) => (
                            <ChartTooltip slice={slice} translationKey={translationKey} />
                        )}
                    />
                )}
            </Box>
        </Box>
    );
}

export default LineChart;
