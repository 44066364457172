import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import theme from 'constants/theme';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { selectAllBatches } from 'store/reducers/batches/list';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { selectAllBatchesToDownload } from 'store/reducers/batches/to_download';

type DownloadImagesSnippetProps = {

}

function DownloadImagesSnippet({ }: DownloadImagesSnippetProps) {
    const imagesURLs = useAppSelector(selectAllBatchesToDownload);

    const { t } = useTranslation([Namespace.ACTIONS]);

    /**
     * Generate a single gsutil command to download all of the selected batches
     */
    const downloadString = useMemo(() => {
        let command = "gsutil -m cp";
        for (let batchData of imagesURLs) {
            command += ` \\\n\t"gs://${process.env.REACT_APP_PROJECT_ID}-batches/${batchData.imageURL}"`;
        }
        command += " \\\n\t."; // final dot
        return command;
    }, [imagesURLs]);

    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(downloadString);
        setCopied(true);
    }

    return (
        <Box
            whiteSpace="pre-wrap"
            bgcolor="#eee"
            px={3}
            py={3}
            position="relative"
        >
            <Tooltip 
                title={t(copied ? "copied" : "copy", { ns: Namespace.ACTIONS })}
                onOpen={() => { setCopied(false); }}
                >
                <IconButton
                    sx={{ 
                        position: "absolute",
                        top: theme => theme.spacing(2),
                        right: theme => theme.spacing(2),
                    }}
                    onClick={() => copyToClipboard()}
                >
                    <ContentCopyRoundedIcon />
                </IconButton>
            </Tooltip>
            
            <Typography fontFamily="Roboto Mono" fontSize="0.8rem">
                {downloadString}
            </Typography>
        </Box>
    );
}

export default DownloadImagesSnippet;