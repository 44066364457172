import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "hooks/hooks";
import { selectCollectionScheduleByID } from "store/reducers/collections_schedules/list";
import moment from "moment";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import CardListItem from "../CardListItem";
import CollectionSchedule from "models/CollectionSchedule";

type ScheduleItemProps = {
    collectionScheduleID: EntityId;
    onEditClicked: (collectionSchedule: CollectionSchedule) => void;
    onDeleteClicked: (collectionScheduleID: CollectionSchedule) => void;
}

export default function ScheduleItem(props: ScheduleItemProps) {
    const { collectionScheduleID, onEditClicked, onDeleteClicked } = props;

    const { t } = useTranslation([Namespace.STATS]);

    const collectionSchedule = useAppSelector(state => selectCollectionScheduleByID(state, collectionScheduleID)!);

    const weekdays = Object.entries(collectionSchedule.calendar).map(([weekday, times]) => {
        let [hours, minutes] = times.start.split(":");
        let date = moment();
        date.day(weekday).hours(Number(hours)).minutes(Number(minutes));
        return `${t("weekday", { ns: Namespace.DATES, date: date })}: ${t("hours_and_minutes", { ns: Namespace.DATES, time: date })}`;
    });

    return (
        <CardListItem
            primary={`${collectionSchedule.truck.identificationNumber} - ${collectionSchedule.route.label}`}
            secondary={weekdays.join("\n")}
            onEditClicked={() => onEditClicked(collectionSchedule)}
            onDeleteClicked={() => onDeleteClicked(collectionSchedule)}
            />
    );
}