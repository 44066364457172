import { useMemo } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppDispatch } from 'hooks/hooks';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import MissionDataGrid from '../../DataTable/MissionDataGrid';
import { TrashCount } from 'constants/trash';
import { SortingMapActions } from 'store/reducers/batches/sorting_map';
import { MissionAddressesActions } from 'store/reducers/missions/addresses/list';

type AddressRowData = {
    addressKey: string;
    house: string | undefined;
    street: string;
    city: string;
    errorsCount: number;
    errorDetail: Partial<TrashCount>;
}

type TableProps = {
    rows: AddressRowData[];
}

function SortingPointDataTable({ rows }: TableProps) {
    const { t } = useTranslation([Namespace.GLOSSARY,Namespace.MISSIONS]);

    const dispatch = useAppDispatch();

    /** Dispatch action to highlight the hovered address on the map. */
    const handleRowMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        const rowId = event.currentTarget.getAttribute('data-id');
        dispatch(SortingMapActions.hoverAddressKey(rowId));
    };

    /** Dispatch action to stop highlighting the hovered address on the map. */
    const handleRowMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
        const rowId = event.currentTarget.getAttribute('data-id');
        dispatch(SortingMapActions.leavePlaceID(rowId));
    };

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'house',
            headerName: t('house_number', { ns: Namespace.GLOSSARY }),
            width: 70,
        },
        {
            field: 'street',
            headerName: t('street', { ns: Namespace.GLOSSARY }),
            minWidth: 160,
        },
        {
            field: 'city',
            headerName: t('city', { ns: Namespace.GLOSSARY }),
            minWidth: 120,
        },
        {
            field: 'errorsCount',
            headerName: t('errors_count', { ns: Namespace.MISSIONS }),
            type: 'number',
            width: 70,
        },
        {
            field: 'errorDetail',
            headerName: t('error_detail', { ns: Namespace.MISSIONS }),
            minWidth: 240,
            flex: 1,
            renderCell: (params: GridRenderCellParams<Partial<TrashCount>, AddressRowData>) => {
                const errors = params.value || {};

                if (typeof errors !== 'object') {
                    return;
                }

                return (
                    Object.entries(errors).map(([trashType, count], index) =>
                        t(`${trashType}_count`, { ns: Namespace.WASTES, context: 'small', count: count })
                    ).join(', ')
                );
            },
        },
    ], [t]);

    return (
        <MissionDataGrid
            rows={rows}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) =>
                dispatch(MissionAddressesActions.setSelectedSortingPointIds(newSelection as string[]))
            }
            slotProps={{
                row: {
                    onMouseEnter: handleRowMouseEnter,
                    onMouseLeave: handleRowMouseLeave,
                },
            }}
            getRowId={(row) => row.addressKey}
        />
    );
}

export default SortingPointDataTable;
