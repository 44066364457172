import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { FichaDataGrid } from 'components/_include/DataGrid/FichaDataGrid';
import { MissionSpreadsheetActions, SpreadsheetPreviewRow } from 'store/reducers/missions/addresses/spreadsheet';

const AddressDataGrid: React.FC = () => {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.MISSIONS]);
    const dispatch = useAppDispatch();
    const spreadsheetRows = useAppSelector(state => state.missions.spreadSheetAddress.spreadsheetPreviewRows);
    const selectedRows = useAppSelector(state => state.missions.spreadSheetAddress.selectedSpreadsheetRows);
    const spreadsheetLoading = useAppSelector(state => state.missions.spreadSheetAddress.loadingSpreadsheet);

    // Column definitions for the analyzed data grid
    const analyzedDataColumns: GridColDef[] = [
        {
            field: 'houseNumber',
            headerName: t('house_number', { ns: Namespace.GLOSSARY }),
            flex: 0.5,
            minWidth: 80,
            editable: true,
        },
        {
            field: 'street',
            headerName: t('street', { ns: Namespace.GLOSSARY }),
            flex: 1,
            minWidth: 150,
            editable: true,
        },
        {
            field: 'city',
            headerName: t('city', { ns: Namespace.GLOSSARY }),
            flex: 1,
            minWidth: 200,
            editable: true,
        },
        {
            field: 'refusalReasonStr',
            headerName: t('refusal_reason', { ns: Namespace.MISSIONS }),
            flex: 1,
            minWidth: 200,
            editable: true,
            renderCell: (params: GridRenderCellParams<SpreadsheetPreviewRow, string>) => {
                const refusal = params.row.refusalReason;
                if (!refusal || Object.keys(refusal).length === 0) {
                    return <Typography variant="body2">-</Typography>;
                }
                return (
                    <ul>
                        {Object.entries(refusal).map(([date, reasons]) => (
                            <li key={date}>
                                <Typography
                                    variant="body2"
                                    sx={{ whiteSpace: 'pre-line', padding: '5px' }}
                                >
                                    {date}: {reasons.join(', ')}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                );
            },
        }
    ];

    const handleSelectionChange = useCallback(
        (rowSelectionModel: GridRowSelectionModel) => {
            // rowSelectionModel is a readonly array, so we convert it to a normal array if needed.
            const selectedIds = Array.from(rowSelectionModel);
            const selectedRowsData = spreadsheetRows.filter(row => selectedIds.includes(row.id));
            dispatch(MissionSpreadsheetActions.setSelectedSpreadsheetRows(selectedRowsData));
        },
        [dispatch, spreadsheetRows]
    );

    const onCellEdited = useCallback((newRow: SpreadsheetPreviewRow) => {
        dispatch(MissionSpreadsheetActions.updateSpreadsheetRow(newRow));
        return newRow;
    }, [dispatch]);

    return (
        <Box sx={{ width: '100%', height: '100%', maxHeight: 'calc(100vh - 300px)' }}>
            <FichaDataGrid
                disableRowSelectionOnClick
                disableExport
                disableQuickSearch
                rows={spreadsheetRows}
                columns={analyzedDataColumns}
                getRowId={(row) => row.id}
                checkboxSelection
                rowSelectionModel={selectedRows.map(row => row.id)}
                showCellVerticalBorder
                showColumnVerticalBorder
                onRowSelectionModelChange={handleSelectionChange}
                processRowUpdate={onCellEdited}
                loading={spreadsheetLoading}
                slotProps={{
                    loadingOverlay: {
                        variant: 'skeleton',
                        noRowsVariant: 'skeleton',
                    },
                    baseCheckbox: {
                        color: 'secondary',
                    },
                }}
                getRowHeight={() => 'auto'}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 }
                    }
                }}
            />
        </Box>
    );
};

export default AddressDataGrid;