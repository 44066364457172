import { useEffect, useMemo, } from "react";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { usePartnerID } from 'hooks/partners';
import { selectAllAreas } from "store/reducers/areas/list";
import { SelectedAreaActions } from "store/reducers/areas/selected";
import AreasMethods from "controllers/areas";
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { SearchParam } from "constants/urls";

type AreaSelectProps = {
    parentLoading: boolean;
    needDefault?: boolean;
}

function AreaSelect(props: AreaSelectProps) {
    const { parentLoading, needDefault } = props;

    const loading = useAppSelector(state => state.areas.list.loading) || parentLoading;
    const partnerID = usePartnerID();
    const areas = useAppSelector(state => selectAllAreas(state));
    const selectedArea = useAppSelector(state => state.areas.selected.data);

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.COMMONS, Namespace.FORMS, Namespace.GLOSSARY]);

    const [searchParams, setSearchParams] = useSearchParams();

    const selectedAreaID = searchParams.get(SearchParam.AREA_ID);

    /** List areas upon first load and when partner changes */
    useEffect(() => {
        if (partnerID) {
            dispatch(AreasMethods.list(partnerID, []));
        }
    }, [partnerID]);

    /** Select first area if default is needed */
    useEffect(() => {
        if (needDefault && !selectedAreaID && areas.length > 0) {
            handleAreaSelected(areas[0].ID);
        }
    }, [areas]);
    
    /** Select area based on URL params */
    useEffect(() => {
        if (areas.length > 0) {
            if (selectedAreaID) { // there's an area to select from URL params
                const areaToSelect = areas.find(t => t.ID === selectedAreaID);
                if (areaToSelect) { // select area
                    dispatch(SelectedAreaActions.selectArea({ area: areaToSelect }));
                }
            }
            else if (selectedArea) { // no area to select: unselect selected area
                dispatch(SelectedAreaActions.selectArea({
                    area: null,
                }));
            }
        }
    }, [areas, selectedAreaID]);

    const areasOptions = useMemo(() => {
        let options: Option[] = [];
        if (needDefault) {
            if (!selectedArea) {
                options.push({
                    label: t("loading", { ns: Namespace.COMMONS }),
                    value: "",
                });
            }
        }
        else {
            options.push({
                label: t("select.all_areas", { ns: Namespace.FORMS }),
                value: "",
            });
        }
        areas.forEach(area => {
            options.push({
                label: area.name,
                value: area.ID,
            });
        });

        return options;
    }, [needDefault, selectedArea, areas]);

    const handleAreaSelected = (id: string) => {
        if (id) {
            searchParams.set(SearchParam.AREA_ID, id);
            setSearchParams(searchParams);
        }
        else {
            searchParams.delete(SearchParam.AREA_ID);
            setSearchParams(searchParams);
        }
    };

    const defaultValue = selectedAreaID || areasOptions[0].value;
    const title = needDefault ? (selectedArea?.name || areasOptions[0].label) : t("area", { ns: Namespace.GLOSSARY });

    return (
        <DropdownMenu
            id="area-select-menu"
            title={title}
            loading={loading}
            values={areasOptions}
            defaultValue={defaultValue}
            startIcon={<ZoomInMapIcon />}
            onChange={(areaID) => handleAreaSelected(areaID)}
        />
    );
}

export default AreaSelect;