import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from "@mui/material";
import CollectionsController from "controllers/collections";
import { Namespace } from "locales/translations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { selectCollectionById } from "store/reducers/collections/list";

type DialogProps = {
    collectionID: string;
    open: boolean;
    onClose: () => void;
}

export default function MarkCollectionAsDoneDialog({ collectionID, open, onClose }: DialogProps) {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.DIALOGS]);

    const collection = useAppSelector(state => selectCollectionById(state, collectionID));
    const processed = collection?.processed;
    const loading = collection?.loading;

    const dispatch = useAppDispatch();

    const markCollectionAsDone = () => {
        if (collection) dispatch(CollectionsController.update(collection, { processed: !processed }));
        onClose();
    }

    const [outlierVerificationChecked, setOutlierVerificationChecked] = useState(false);
    
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle
                sx={{
                    py: 1,
                    px: 4,
                }}
            >
                {t(`mark_collection_as.${processed ? "undo" : "done"}.title`, { ns: Namespace.DIALOGS })}
            </DialogTitle>
            <DialogContent
                sx={{
                    px: 4,
                }}
            >
                <DialogContentText>
                    {t(`mark_collection_as.${processed ? "undo" : "done"}.body`, { ns: Namespace.DIALOGS })}
                </DialogContentText>

                {!processed && (
                    <Box mt={2} >
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    required
                                    checked={outlierVerificationChecked}
                                    onChange={(e) => setOutlierVerificationChecked(e.target.checked)}
                                    />
                            } 
                            label={t("mark_collection_as.done.outlier_verification", { ns: Namespace.DIALOGS })}
                            />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={onClose}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <LoadingButton
                    color="success"
                    onClick={markCollectionAsDone}
                    loading={loading}
                    disabled={(!processed && !outlierVerificationChecked) || loading}
                >
                    <span>{t(processed ? "undo" : "done", { ns: Namespace.ACTIONS })}</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}