import { ButtonBase, Grid, IconButton, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { BatchDbData, BatchResults } from "models/Batch";
import SaveIcon from '@mui/icons-material/Save';
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import { TrashType } from "constants/trash";
import { BatchesActions } from "store/reducers/batches/list";
import ActionButton from "components/_include/Buttons/ActionButton";
import { selectAllResultsBboxes } from "store/reducers/batches/media_overlay";
import { pick } from "lodash";
import BatchesController from "controllers/batches";
import { Timestamp } from "firebase/firestore";
import { Shortcut } from "store/reducers/preferences/validation_shortcuts";

type SaveResultsButtonProps = {
    /** Set to true to move to next batch, false to close modal */
    goToNext: boolean;

    /** Set to true for a vertical button covering the whole height of the screen (on the side of Batch modal) */
    fullHeight?: boolean;
}

export default function SaveResultsButton({ goToNext, fullHeight, }: SaveResultsButtonProps) {

    const { t } = useTranslation([Namespace.ACTIONS]);

    const saveAndGoToNextKey = useAppSelector(state => state.preferences.validation[Shortcut.SAVE_AND_GO_TO_NEXT_BATCH]);
    const saveKey = useAppSelector(state => state.preferences.validation[Shortcut.SAVE]);
    
    const dispatch = useAppDispatch();
    
    const partnerID = useAppSelector(state => state.batches.list.selectedBatch!.partnerID);
    const loading = useAppSelector(state => state.batches.list.selectedBatch!.loading);
    
    /**
     * We have to use React references for the variables below 
     * to be able to call them from Event listeners
     * (otherwise it only takes initial values)
     */
    const batchID = useAppSelector(state => state.batches.list.selectedBatch!.ID);
    const batchIDRef = useRef(batchID);
    useEffect(() => {
        batchIDRef.current = batchID;
    }, [batchID]); 
    
    const inputs = useAppSelector(state => state.batches.wastesInputs);
    const inputsRef = useRef(inputs);
    useEffect(() => {
        inputsRef.current = inputs;
    }, [inputs]);

    const bboxes = useAppSelector(selectAllResultsBboxes);
    const bboxesRef = useRef(bboxes);
    useEffect(() => {
        bboxesRef.current = bboxes;
    }, [bboxes]);

    /**
     * Handle button clicked by updating the batch's results in the database
     */
    const handleSavePressed = useCallback(() => {
        let results: Partial<BatchResults> = Object.entries(inputsRef.current).reduce((acc, [wasteType, { count }]) => ({
            ...acc,
            [wasteType as TrashType]: count,
        }), {});
        const data: Partial<BatchDbData> = {
            verified: true,
            results: results,
            bboxes: bboxesRef.current.map(bbox => ({ ...pick(bbox, ["class", "score"]), box: JSON.stringify(bbox.box), createdAt: Timestamp.now().toMillis()})),
        };

        dispatch(BatchesController.update(partnerID, batchIDRef.current, data))
            .then((success) => {
                if (success) { // successful update
                    if (goToNext) { // select next batch in list
                        dispatch(BatchesActions.selectNextBatch());
                    }
                    else { // close modal
                        dispatch(BatchesActions.unselectBatch());
                    }
                }
            });
    }, [inputs]);

    /**
     * Bind the "Return" key to go to previous batch
     */
    const handleKeydown = (e: KeyboardEvent) => {
        switch (e.key) {
            case saveAndGoToNextKey:
                if (goToNext) {
                    e.preventDefault();
                    handleSavePressed();
                }
                break;

            case saveKey:
                if (!goToNext) {
                    e.preventDefault();
                    handleSavePressed();
                }
                break;
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeydown);
        return () => window.removeEventListener('keydown', handleKeydown);
    }, [goToNext, saveAndGoToNextKey, saveKey]);


    if (goToNext) {
        if (fullHeight) {
            return (
                <ButtonBase
                    onClick={handleSavePressed}
                    sx={{ 
                        width: theme => theme.spacing(8),
                        color: "#fff",
                        backgroundColor: theme => theme.palette.success.main,
                        transition: "background-color .3s",
                        "&:hover": {
                            backgroundColor: theme => theme.palette.success.light,
                        },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <SaveIcon />
                    <Typography
                        variant="button"
                    >
                        {t("next", { ns: Namespace.ACTIONS })}
                    </Typography>
                </ButtonBase>
            )
        }

        return (
            <Grid
                item
                xs={6}
                textAlign="center"
            >
                <ActionButton
                    onClick={handleSavePressed}
                    color="success"
                    loading={loading}
                    disabled={loading}
                    startIcon={<SaveIcon />}
                >
                    {t("next", { ns: Namespace.ACTIONS })}
                </ActionButton>
            </Grid>
        )
    }

    return (
        <Grid
            item
            xs={4}
            textAlign="center"
        >
            <IconButton
                onClick={handleSavePressed}
                color="secondary"
                disabled={loading}
            >
                <SaveIcon />
            </IconButton>
        </Grid>    
    )
}