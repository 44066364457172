import { Box, IconButton } from '@mui/material';
import SectionLoader from 'components/_include/SectionLoader';
import { isSortingError } from 'helpers/trash';
import { useEffect, useRef, useState } from 'react';
import { cleanCanvas, drawRectangle } from 'helpers/draw';
import { useAppDispatch, useAppSelector, useSortingRules } from 'hooks/hooks';
import { WASTES_COLORS } from 'constants/trash';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import FullScreenIcon from '@mui/icons-material/FullscreenRounded';
import { BatchesActions } from 'store/reducers/batches/list';
import FullScreenBatchPictureModal from './FullScreenBatchPicture/FullScreenBatchPictureModal';
import moment from 'moment';

type BatchPictureAndMasksProps = {
    pictureURL: string | null;
}

function BatchPictureAndMasks({ pictureURL }: BatchPictureAndMasksProps) {

    const dispatch = useAppDispatch();

    const { errorsClasses, mergingMapping } = useSortingRules();

    const batchDate = useAppSelector(state => moment(state.batches.list.selectedBatch?.timestamp));
    const aiResults = useAppSelector(state => state.batches.list.selectedBatch!.bboxes);
    const loading = useAppSelector(state => state.batches.list.selectedBatch!.loading);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const imageRef = useRef<HTMLImageElement | null>(null);
    const naturalWidth = imageRef.current?.naturalWidth;
    const naturalHeight = imageRef.current?.naturalHeight;

    const [imageLoaded, setImageLoaded] = useState(false);

    const [fullScreenPicture, setFullScreenPicture] = useState(false);

    /**
     * Draw bounding boxes on canvas
     */
    useEffect(() => {
        const ctx = canvasRef.current?.getContext('2d');
        if (canvasRef.current && naturalWidth && naturalHeight && ctx) {
            cleanCanvas(ctx, canvasRef.current.width, canvasRef.current.height);

            if (aiResults) {

                const resultsToDraw = aiResults
                    .filter(aiResult => isSortingError(errorsClasses, aiResult.class));

                for (let aiResult of resultsToDraw) {
                    const classToDraw = mergingMapping[aiResult.class] ?? aiResult.class; // display "parent" class if merged
                    
                    drawRectangle(
                        ctx, 
                        aiResult.box[0], 
                        aiResult.box[1], 
                        WASTES_COLORS[classToDraw], 
                        10
                    );
                }
            }
        }
    }, [canvasRef.current, naturalWidth, naturalHeight, aiResults]);

    const handleBackClick = () => {
        dispatch(BatchesActions.unselectBatch());
    }

    return (
        <Box
            width="100%"
            height={200}
            position="relative"
        >
            <img
                alt=""
                onLoad={() => { setImageLoaded(true) }}
                ref={imageRef}
                width="100%"
                height="100%"
                src={pictureURL || undefined}
                style={{
                    objectFit: "cover",
                }}
            />

            {imageLoaded && (
                <canvas
                    ref={canvasRef}
                    width={naturalWidth}
                    height={naturalHeight}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: '100%',
                        maxHeight: '100%',
                        objectFit: "contain",
                    }} />
            )}


            <Box
                position="absolute" left={0} right={0} bottom={0} top={0}
                sx={{
                    ...(imageLoaded && {
                        boxShadow: "0px -30px 20px 0px rgba(0, 0, 0, 0.50) inset, 0px 30px 20px 0px rgba(0, 0, 0, 0.50) inset",
                    }),
                }}
                >
                {/** Back button */}
                <IconButton
                    color="white"
                    sx={{
                        position: "absolute",
                        top: (theme) => theme.spacing(1),
                        left: (theme) => theme.spacing(1),
                        // color: "#ffffff",
                    }}
                    onClick={handleBackClick}
                >
                    <ArrowBackIcon />
                </IconButton>

                {/** Full screen button */}
                <IconButton
                    color="white"
                    sx={{
                        position: "absolute",
                        bottom: (theme) => theme.spacing(1),
                        right: (theme) => theme.spacing(1),
                    }}
                    onClick={() => setFullScreenPicture(true)}
                >
                    <FullScreenIcon />
                </IconButton>
            </Box>

            {loading && (
                <SectionLoader />
            )}

            {pictureURL && imageRef.current && (
                <FullScreenBatchPictureModal
                    pictureDate={batchDate}
                    pictureURL={pictureURL}
                    open={fullScreenPicture}
                    onClose={() => setFullScreenPicture(false)}
                    bboxes={aiResults ?? []} 
                    />
            )}

        </Box>
    );
}

export default BatchPictureAndMasks;