import { ListItemButton, ListItemText, useTheme } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import { formatAddress } from "helpers/geo";
import { Namespace } from "locales/translations";
import _ from "lodash";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/hooks";
import { selectSortingPointByAddressKey } from "store/reducers/batches/sorting_map";

type PlaceListItemProps = {
    placeID: EntityId;
    onClick: (placeID: string) => void;
    onMouseEnter: (placeID: string) => void;
    onMouseLeave: (placeID: string) => void;
}

function PlaceListItem(props: PlaceListItemProps) {
    const { placeID, onClick, onMouseEnter, onMouseLeave } = props;

    const { t } = useTranslation([Namespace.GLOSSARY]);

    const theme = useTheme();

    const selected = useAppSelector(state => state.batches.sortingMap.selectedAddressKey === placeID);

    const sortingPoint = useAppSelector(state => selectSortingPointByAddressKey(state, placeID || ""))!;

    const address = formatAddress(sortingPoint.properties.address);

    const errorsCount = useAppSelector(state => selectSortingPointByAddressKey(state, placeID)?.properties.errorsCount ?? 0);
    const batchesCount = useAppSelector(state => selectSortingPointByAddressKey(state, placeID)?.properties.batchesCount ?? 0);

    const filterString = useAppSelector(state => state.batches.sortingMap.filterString);

    const filteredAddressComponents: [string] | [string, string, string] = useMemo(() => {
        if (!filterString) return [address];
        const startIndex = address.toLowerCase().indexOf(filterString.toLowerCase());
        return [address.slice(0, startIndex), address.slice(startIndex, startIndex + filterString.length), address.slice(startIndex + filterString.length)];
    }, [address, filterString]);

    return (
        <ListItemButton
            onClick={() => onClick(placeID.toString())}
            onMouseEnter={() => onMouseEnter(placeID.toString())}
            onMouseLeave={() => onMouseLeave(placeID.toString())}
            sx={{
                bgcolor: selected ? "#ddeeff" : "#fff",
            }}
            autoFocus={selected}
        >
            <ListItemText
                primary={filteredAddressComponents.map((component, index) => (
                    <span
                        key={component}
                        {...(index === 1 && { // highlight matching string
                            style: {
                                backgroundColor: theme.palette.primary.light,
                            }
                        })}
                    >
                        {component}
                    </span>
                ))}
                secondary={`${t("errors_count", { ns: Namespace.GLOSSARY, count: errorsCount })} / ${t("images_count", { ns: Namespace.GLOSSARY, count: batchesCount })}`}
                primaryTypographyProps={{
                    variant: "body2",
                }}
                secondaryTypographyProps={{
                    variant: "button",
                }}
            />
        </ListItemButton>
    )
}

export default memo(PlaceListItem, (props1, props2) => _.isEqual(props1, props2));