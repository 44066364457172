import AddIcon from '@mui/icons-material/AddRounded';
import { ButtonBase, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateMissionModal from './CreateMissionModal';

type NewPartnerButtonProps = {

}

export default function NewMissionButton({ }: NewPartnerButtonProps) {
    const [dialogOpen, setDialogOpen] = useState(false);

    const { t } = useTranslation([Namespace.MISSIONS]);

    return (
        <>
            <ButtonBase
                sx={{
                    backgroundColor: "#fff",
                    width: "100%",
                    height: "100%",
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "0px 1px 2px rgba(0,0,0,.4)",
                    padding: 4,
                }}
                onClick={() => setDialogOpen(true)}
            >
                <AddIcon
                    sx={{
                        fontSize: 40,
                        color: theme => theme.palette.secondary.main,
                    }}
                />
                <Typography
                    fontSize={22}
                    fontWeight={600}
                    sx={{
                        color: theme => theme.palette.secondary.main,
                    }}
                >
                    {t("add_mission", { ns: Namespace.MISSIONS })}
                </Typography>
            </ButtonBase>

            <CreateMissionModal open={dialogOpen} onClose={() => setDialogOpen(false)} />
        </>
    );
}