import { Box } from "@mui/material";
import { useAppSelector } from "hooks/hooks";
import BatchesController from "controllers/batches";
import AddressesExplorerFilters from "./AddressesExplorerFilters";
import { SearchBatchesButton } from "components/Explorer/FiltersAndActions/SearchBatchesButton";
import { useState, useEffect } from "react";

export const LOADING_LIMIT = 9999;

export default function AddressesExplorerFiltersAndActions() {

    const byAddressesLoading = useAppSelector(state => state.batches.byAddresses.loading);
    const doneLoading = useAppSelector(state => !state.batches.byAddresses.loading && !state.batches.list.next);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (byAddressesLoading) setLoading(true);
    }, [byAddressesLoading]);

    useEffect(() => {
        if (doneLoading) setLoading(false);
    }, [doneLoading]);

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            mb={2}
        >
            <AddressesExplorerFilters />

            <SearchBatchesButton
                loadingLimit={LOADING_LIMIT}
                loading={loading}
                listBatchesAction={BatchesController.listByAddresses}
                listOlderBatchesAction={BatchesController.listOlderByAddresses}
            />
        </Box>
    )
}