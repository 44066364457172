import { DbCollection } from "constants/db";
import { DangerLevel } from "constants/stats";
import { CenterAndBounds, DbOrder, QueryFilter } from "constants/types";
import urls from "constants/urls";
import { DocumentData, Query, QueryDocumentSnapshot, Timestamp, collection, collectionGroup, doc, documentId, getDoc, getDocs, getFirestore, limit, or, orderBy, query, startAfter, updateDoc, where, writeBatch } from "firebase/firestore";
import { getDocumentReference, listDocs } from "helpers/db";
import { AddressStatsPointFeature, BatchPoint, formatAddress, getCenterAndBounds } from "helpers/geo";
import { getDisplayedErrors, getErrorsCount, getMergingWasteMapping, getSortingErrorClasses, sumTrashCounts, unmergeTrashType } from "helpers/trash";
import i18next from "i18next";
import { Namespace } from "locales/translations";
import { chunk, cloneDeep, pick, sum } from "lodash";
import Batch, { BatchDbData, BatchUpdatableFields, NextBatchesQuery, PositionedBatch } from "models/Batch";
import BatchAIResult, { BatchAIResultDb } from "models/BatchAIResult";
import { deserializeBoundingBox } from "models/BoundingBox";
import { stringifyUrl } from "query-string";
import { BatchesActions, selectAllBatches } from "store/reducers/batches/list";
import { BatchesMapActions } from "store/reducers/batches/map";
import { PlacedBatchesActions, getBatchKey } from "store/reducers/batches/places";
import { SelectedBatchActions } from "store/reducers/batches/selected";
import { SortingMapActions } from "store/reducers/batches/sorting_map";
import { showSuccess } from "store/reducers/snacks";
import { AppDispatch, getStoreState } from "store/store";
import { fetchAPI, handleAPIError } from "./actions";
import { MergingWasteMapping, TrashType } from "constants/trash";
import { AddressesClusteringLevel, BatchesByAddress, BatchesByAddressesActions } from "store/reducers/batches/by_addresses";
import moment from "moment";
import { DATE_FORMAT } from "constants/dates";
import { sumObjects } from "helpers/objects";
import Area from "models/Area";
import { Dictionary } from "@reduxjs/toolkit";

type BatchesMapType = "collection" | "sorting";

/**
 * Get a reference to a Firestore document for a given partner ID and batch ID
 */
function getRef(partnerID: string, batchID: string) {
    return doc(getFirestore(), DbCollection.PARTNERS, partnerID, DbCollection.BATCHES, batchID);
}

/**
 * Get a reference to a Firestore document for a given batch
 */
function getBatchRef(batch: Batch) {
    return getRef(batch.partnerID, batch.ID);
}

/** Default state in which user can see the entire image. */
export const DEFAULT_ZOOM = { zoom: 1, transform: { x: 0, y: 0 } };

/**
 * Build a Batch object from its Firestore document
 */
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): Batch {
    const data: BatchDbData = dbDoc.data() as BatchDbData;

    return {
        ID: dbDoc.id,
        partnerID: dbDoc.ref.parent.parent!.id,
        ...data,
        bboxes: deserializeBoundingBox(dbDoc.id, data.bboxes),
        timestamp: data.timestamp.toMillis(),
        loading: false,
        imageZoom: DEFAULT_ZOOM,
    };
}

/**
 * Format the Firestore query to list all the batches
 * matching some filters and selected types of sorting errors
 */
const formatListQuery = (collectionRef: Query, filters: QueryFilter<BatchDbData>[], selectedErrors: TrashType[], order: DbOrder<BatchDbData> = { fieldPath: "timestamp" }, limitCount?: number) => {
    let q = query(collectionRef);

    filters.forEach(filter => {
        if (filter.fieldPath === "timestamp" && typeof filter.value === "number") { // convert filter value back to Timestamp
            q = query(q, where(filter.fieldPath, filter.opStr, Timestamp.fromMillis(filter.value)));
        }
        else { // normal filter
            q = query(q, where(filter.fieldPath, filter.opStr, filter.value));
        }
    });

    const mergingWasteMapping = getMergingWasteMapping();

    // Reference: Firestore has a limitation of 30 disjunctions (OR queries).
    // See: https://firebase.google.com/docs/firestore/query-data/queries#limits_on_or_queries
    q = query(
        q,
        or(...selectedErrors
            .map(errorType => unmergeTrashType(mergingWasteMapping, errorType)
                .map(subErrorType => where(`has.${subErrorType}`, "==", true))
            ).flat()
        )
    );

    // apply sorting
    q = query(q, orderBy(order.fieldPath, order.directionStr));

    // apply pagination
    if (limitCount) q = query(q, limit(limitCount));

    return q;
}

/**
 * Get the formatted query to list older batches.
 */
function getListOlderQuery(nextQuery: NextBatchesQuery) {
    const { isCollectionGroup, collectionPath, startAfterValue, filters, selectedErrors: selectedTrashTypes, order, limit: limitCount, } = nextQuery;

    const db = getFirestore();

    let collectionRef: Query;

    if (isCollectionGroup) { // all batches
        collectionRef = collectionGroup(db, collectionPath);
    }
    else { // specific partner's batches
        collectionRef = collection(db, collectionPath);
    }

    let q = formatListQuery(collectionRef, filters, selectedTrashTypes, order, limitCount);

    const startAfterVal = order.fieldPath === "timestamp" ? Timestamp.fromMillis(startAfterValue) : startAfterValue;
    q = query(q, startAfter(startAfterVal));

    return q;
}

/**
 * Get the next query to list older batches.
 */
function createNextQuery(batches: Batch[], partnerID: string | null, filters: QueryFilter<BatchDbData>[], selectedTrashTypes: TrashType[], order: DbOrder<BatchDbData>, limitCount?: number): NextBatchesQuery | null {
    return batches.length === limitCount ? {
        isCollectionGroup: !partnerID, // set to true if partnerID is not present
        collectionPath: partnerID ? [DbCollection.PARTNERS, partnerID, DbCollection.BATCHES].join("/") : DbCollection.BATCHES,
        startAfterValue: batches[batches.length - 1][order.fieldPath],
        filters: filters.map(filter => {
            // Serialize Timestamp filters
            if (filter.value instanceof Timestamp) return { ...filter, value: filter.value.toMillis(), };
            return filter;
        }),
        selectedErrors: selectedTrashTypes,
        order: order,
        limit: limitCount,
    } : null;
};

/**
 * Get the next query to list older batches.
 */
function getNextQuery(batches: Batch[], nextQuery: NextBatchesQuery): NextBatchesQuery | null {
    return batches.length === nextQuery.limit ? {
        ...nextQuery,
        startAfterValue: batches[batches.length - 1][nextQuery.order.fieldPath],
    } : null;
};

/**
 * List all the batches from a given partner matching all the selected filters,
 * in order to display them on a map
 */
const listPartnerBatchesForMap = (partnerID: string, filters: QueryFilter<BatchDbData>[], selectedErrors: TrashType[], mapType: BatchesMapType, dangerLevel?: DangerLevel) => async (dispatch: AppDispatch) => {
    const errorsClasses = getSortingErrorClasses();
    const mergingMapping = getMergingWasteMapping();

    if (mapType === "collection") dispatch(BatchesActions.startLoadingList());
    else dispatch(SortingMapActions.startLoading());

    const db = getFirestore();
    const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.BATCHES].join("/");

    const q = formatListQuery(collection(db, collectionPath), filters, selectedErrors);

    try {
        const querySnapshot = await getDocs(q);

        let batches: Batch[];

        if (mapType === "collection") { // batches map in single collection details
            const { points, center, bounds, ...res } = formatBatchesForMap(querySnapshot.docs, "collection");
            batches = res.batches;
            if (batches.length > 0) { // resize map to view all batches at once
                dispatch(BatchesMapActions.setMapPoints({ center, bounds, points }));
            }
            else { // no matching batch
                dispatch(BatchesMapActions.removeMapPoints());
            }

            dispatch(PlacedBatchesActions.setList({ batches, errorsClasses, mergingMapping }));
        }
        else { // sorting map where batches are clustered by address
            let { points, center, bounds, ...res } = formatBatchesForMap(querySnapshot.docs, mapType);
            batches = res.batches;
            let totalBatchesCount = batches.length;
            if (dangerLevel) {
                let filteredData = filterAddressesByDangerLevel(batches, dangerLevel);
                points = filteredData.points;
                center = filteredData.center;
                bounds = filteredData.bounds;
                totalBatchesCount = filteredData.totalBatchesCount;
            }

            dispatch(SortingMapActions.setMapPoints({ points, center, bounds, totalBatchesCount }));
        }

        // add to list of batches
        dispatch(BatchesActions.addToList({ batches: batches, next: null }));
    }
    catch (e) {
        dispatch(handleAPIError(e, "loading batches", BatchesActions.setError));
    }
}

/**
 * Start loading paginated list of batches
 */
const list = (partnerID: string | null, filters: QueryFilter<BatchDbData>[], selectedTrashTypes: TrashType[], order: DbOrder<BatchDbData> = { fieldPath: "timestamp" }, limitCount?: number) => async (dispatch: AppDispatch) => {

    dispatch(BatchesActions.startLoadingList());

    const db = getFirestore();
    let collectionRef;
    if (partnerID) {
        const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.BATCHES].join("/");
        collectionRef = collection(db, collectionPath);
    } else {
        collectionRef = collectionGroup(db, DbCollection.BATCHES);
    }

    let mainQuery = formatListQuery(collectionRef, filters, selectedTrashTypes, order, limitCount);

    try {
        const querySnapshot = await getDocs(mainQuery);

        let batches: Batch[] = querySnapshot.docs.map(doc => fromDbDoc(doc));

        // prepare for next request in pagination if there may be more objects to load 
        let next: NextBatchesQuery | null = createNextQuery(batches, partnerID, filters, selectedTrashTypes, order, limitCount);

        dispatch(BatchesActions.addToList({ batches: batches, next: next }));
        return batches;
    } catch (e) {
        dispatch(handleAPIError(e, "loading batches", BatchesActions.setError));
        return [];
    }
}

/**
 * Load older batches to append to the ones already loaded
 */
const listOlder = () => async (dispatch: AppDispatch) => {
    
    const nextQuery = getStoreState().batches.list.next;
    if (!nextQuery) return []; // no more batches to list

    dispatch(BatchesActions.startLoading());

    const q = getListOlderQuery(nextQuery);

    try {
        const querySnapshot = await getDocs(q);

        const batches: Batch[] = querySnapshot.docs.map(doc => fromDbDoc(doc));

        const next = getNextQuery(batches, nextQuery);

        dispatch(BatchesActions.addToList({ batches: batches, next: next }));

        return batches;
    }
    catch (e) {
        dispatch(handleAPIError(e, "loading older batches", BatchesActions.setError));
        return [];
    }
}

/**
 * List some batches from a list of their IDs
 */
const listByIDs = (partnerID: string, batchesIDs: string[]) => async (dispatch: AppDispatch) => {
    dispatch(BatchesActions.startLoadingList());

    const batchesIDsChunks = chunk(batchesIDs, 10); // maximum 10 values for "in" query filter

    const db = getFirestore();
    const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.BATCHES].join("/");
    const collectionRef = collection(db, collectionPath);

    try {
        let batches: Batch[] = [];

        // calculate bounds
        let minLat = 999;
        let maxLat = -999;
        let minLng = 999;
        let maxLng = -999;

        for (let batchesIDsChunk of batchesIDsChunks) {
            let q = query(collectionRef, where(documentId(), "in", batchesIDsChunk));
            const querySnapshot = await getDocs(q);
            querySnapshot.docs.forEach(doc => {
                const batch = fromDbDoc(doc);
                batches.push(batch);

                // calculate map bounds and zoom to view all batches
                const pos = batch.position!;
                if (pos.latitude < minLat) minLat = pos.latitude;
                if (pos.latitude > maxLat) maxLat = pos.latitude;
                if (pos.longitude < minLng) minLng = pos.longitude;
                if (pos.longitude > maxLng) maxLng = pos.longitude;
            });
        }

        const { center, bounds } = getCenterAndBounds(minLat, maxLat, minLng, maxLng);

        dispatch(BatchesActions.addToList({ batches: batches, next: null }));

        if (batches.length > 0) { // center map on batches
            dispatch(BatchesMapActions.setMapBounds({ center, bounds }));
        }

        return batches;
    }
    catch (e) {
        dispatch(handleAPIError(e, "loading batches", BatchesActions.setError));
        return [];
    }
}



/** Aggregate the results of a list of Batches by their address. */
function aggregateBatchesByAddresses(initialAddresses: { [addressKey: string]: BatchesByAddress }, sortingErrors: TrashType[], mergingWasteMapping: MergingWasteMapping, batches: Batch[], selectedErrors: TrashType[], clusterBy: AddressesClusteringLevel, areas: Dictionary<Area>): BatchesByAddress[] {
    const pickAddressFields = (address: NonNullable<Batch["address"]>) => {
        if (clusterBy === AddressesClusteringLevel.HOUSE_NUMBER) return address;
        return { ...address, houseNumber: undefined };
    }

    /** Get an empty BatchesByAddress object for a given address */
    function getEmptyBatchesByAddress(address: NonNullable<Batch["address"]>, areasIDs: string[]): BatchesByAddress {
        return {
            addressKey: formatAddress(pickAddressFields(address)),
            address: pickAddressFields(address),
            areas: areasIDs.map(id => areas[id]?.name).filter(Boolean) as string[], // filter out deleted areas
            collected: 0,
            collectedWithErrors: 0,
            daysCollectedWithError: {},
            recurrence: 0,
            binsCounts: {},
            errorsCount: 0,
            errors: {},
        }
    };

    /** Count the number of different days an address was collected and contained at least 1 error. */
    function countDaysWithError(batchesByAddress: BatchesByAddress): number {
        return Object.values(batchesByAddress.daysCollectedWithError).reduce((acc, hasError) => acc + (hasError ? 1 : 0), 0);
    }

    /** Merge a Batch's results to its BatchesByAddress object for its given address. */
    function sumBatchesByAddress(sortingErrors: TrashType[], mergingWasteMapping: MergingWasteMapping, batchesByAddress: BatchesByAddress, batch: Batch): BatchesByAddress {
        const errors = getDisplayedErrors(sortingErrors, mergingWasteMapping, batch.results);
        const errorsCount = Object.values(errors).reduce((acc, count) => acc + count, 0);
        const hasError = errorsCount > 0;

        batchesByAddress.binsCounts = sumObjects(batchesByAddress.binsCounts, batch.binsCounts ?? {});
        batchesByAddress.errorsCount += errorsCount;
        batchesByAddress.errors = sumTrashCounts(batchesByAddress.errors, errors);
        
        const dayCollected = moment(batch.timestamp).format(DATE_FORMAT);
        batchesByAddress.daysCollectedWithError[dayCollected] = hasError || batchesByAddress.daysCollectedWithError[dayCollected] || false;
        
        batchesByAddress.collected = Object.keys(batchesByAddress.daysCollectedWithError).length;
        batchesByAddress.collectedWithErrors = countDaysWithError(batchesByAddress);
        batchesByAddress.recurrence = batchesByAddress.collectedWithErrors / batchesByAddress.collected;
        
        return batchesByAddress;
    }

    const batchesByAddresses = cloneDeep(initialAddresses); // addresses retrieved from the state are read-only, so we need to clone them

    for (const batch of batches) {
        if (!batch.address) continue;
        const address = pickAddressFields(batch.address);
        const addressKey = formatAddress(pickAddressFields(address));

        const batchesByAddress = batchesByAddresses[addressKey] ?? getEmptyBatchesByAddress(address, batch.areasIDs);
        batchesByAddresses[addressKey] = sumBatchesByAddress(sortingErrors, mergingWasteMapping, batchesByAddress, batch);
    }

    // filter by selected errors
    let addressesList = Object.values(batchesByAddresses);
    if (selectedErrors.length > 0) {
        addressesList = addressesList.filter(batchesByAddress => selectedErrors.some(error => batchesByAddress.errors[error]));
    }

    return addressesList;
}

/**
 * List all the batches from a given partner matching all the selected filters,
 * and aggregate them by address.
 */
const listByAddresses = (partnerID: string, filters: QueryFilter<BatchDbData>[], selectedErrors: TrashType[], order: DbOrder<BatchDbData>, limitCount: number, clusterBy: AddressesClusteringLevel) => async (dispatch: AppDispatch) => {

    dispatch(BatchesByAddressesActions.startLoadingList());
    dispatch(BatchesActions.startLoadingList());

    const db = getFirestore();
    const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.BATCHES].join("/");
    const collectionRef = collection(db, collectionPath);

    let mainQuery = formatListQuery(collectionRef, filters, [], order, limitCount);

    try {
        const querySnapshot = await getDocs(mainQuery);

        let batches: Batch[] = querySnapshot.docs.map(doc => fromDbDoc(doc));

        // prepare for next request in pagination if there may be more objects to load 
        let next = createNextQuery(batches, partnerID, filters, selectedErrors, order, limitCount);

        dispatch(BatchesActions.addToList({ batches, next }));

        const sortingErrors = getSortingErrorClasses();
        const mergingWasteMapping = getMergingWasteMapping();

        const areas = getStoreState().areas.list.entities;

        const batchesByAddresses = aggregateBatchesByAddresses({}, sortingErrors, mergingWasteMapping, batches, selectedErrors, clusterBy, areas);

        dispatch(BatchesByAddressesActions.setList({ batches: batchesByAddresses }));
        return batches;
    } catch (e) {
        dispatch(handleAPIError(e, "loading batches by addresses", BatchesByAddressesActions.setError));
        return [];
    }
}


/**
 * Load older batches and aggregate them to the addresses already loaded
 */
const listOlderByAddresses = () => async (dispatch: AppDispatch) => {
    const nextQuery = getStoreState().batches.list.next;
    if (!nextQuery) return []; // no more batches to list

    dispatch(BatchesActions.startLoading());

    const q = getListOlderQuery(nextQuery);

    try {
        const querySnapshot = await getDocs(q);

        const batches: Batch[] = querySnapshot.docs.map(doc => fromDbDoc(doc));

        let next = getNextQuery(batches, nextQuery);

        dispatch(BatchesActions.setList({ batches: batches, next: next }));

        // aggregate batches by addresses
        const sortingErrors = getSortingErrorClasses();
        const mergingWasteMapping = getMergingWasteMapping();

        const areas = getStoreState().areas.list.entities;

        const initialAddresses = getStoreState().batches.byAddresses.entities as { [addressKey: string]: BatchesByAddress };
        const clusterBy = getStoreState().batches.byAddresses.clusterBy;
        const batchesByAddresses = aggregateBatchesByAddresses(initialAddresses, sortingErrors, mergingWasteMapping, batches, nextQuery.selectedErrors, clusterBy, areas);

        dispatch(BatchesByAddressesActions.setList({ batches: batchesByAddresses }));

        return batches;
    }
    catch (e) {
        dispatch(handleAPIError(e, "loading older batches by addresses", BatchesActions.setError));
        return [];
    }
}

/**
 * Switch the aggregation level of the batches
 * @param selectedErrors The errors selected to filter addresses by
 * @param clusterBy The aggregation level to switch to
 * @returns 
 */
const switchBatchesAggregationLevel = (selectedErrors: TrashType[], clusterBy: AddressesClusteringLevel, areas: Dictionary<Area>) => async (dispatch: AppDispatch) => {
    const batches = selectAllBatches(getStoreState());
    
    dispatch(BatchesByAddressesActions.startLoading());

    const sortingErrors = getSortingErrorClasses();
    const mergingWasteMapping = getMergingWasteMapping();

    const initialAddresses = getStoreState().batches.byAddresses.entities as { [addressKey: string]: BatchesByAddress };
    const batchesByAddresses = aggregateBatchesByAddresses(initialAddresses, sortingErrors, mergingWasteMapping, batches, selectedErrors, clusterBy, areas);

    dispatch(BatchesByAddressesActions.setList({ batches: batchesByAddresses }));
    return batchesByAddresses;
}

/**
 * Retrieve a single batch matching filters, the last one received
 */
const getLast = (partnerID: string, filters: QueryFilter<BatchDbData>[]) => async (dispatch: AppDispatch) => {
    dispatch(SelectedBatchActions.startLoading());

    try {
        const batchesDocs = await listDocs(
            [DbCollection.PARTNERS, partnerID, DbCollection.BATCHES],
            filters,
            { fieldPath: "timestamp", directionStr: "desc" },
            1
        );

        const batch = batchesDocs.length > 0 ? fromDbDoc(batchesDocs[0]) : null;

        if (batch) dispatch(SelectedBatchActions.set(batch));

        return batch;
    }
    catch (e) {
        dispatch(handleAPIError(e, "retrieving batch", SelectedBatchActions.setError));
        return null;
    }
}

/**
 * Retrieve a single batch using its partner's ID and its ID.
 */
const getByID = (partnerID: string, id: string, displayError = true) => async (dispatch: AppDispatch) => {
    dispatch(SelectedBatchActions.startLoading());

    try {
        const docRef = getDocumentReference(id, DbCollection.BATCHES, `${DbCollection.PARTNERS}/${partnerID}`);
        const batchDoc = await getDoc(docRef);
        const batch = batchDoc.exists() ? fromDbDoc(batchDoc) : null;

        dispatch(SelectedBatchActions.set(batch));

        return batch;
    }
    catch (e) {
        if (displayError) dispatch(handleAPIError(e, "retrieving batch", SelectedBatchActions.setError)); // show snackbar
        else dispatch(SelectedBatchActions.setError((e as Error).message)); // don't show snackbar (error is expected)
        return null;
    }
}

const update = (partnerID: string, batchID: string, data: BatchUpdatableFields) => async (dispatch: AppDispatch) => {
    dispatch(BatchesActions.updateItem({ batchID: batchID, data: { loading: true } }));

    let updateData: Omit<Partial<BatchDbData>, "timestamp"> = {
        ...data,
    };

    try {
        // update document in database
        await updateDoc(getRef(partnerID, batchID), updateData);

        dispatch(BatchesActions.updateItem({
            batchID: batchID,
            data: {
                ...updateData,
                bboxes: deserializeBoundingBox(batchID, updateData.bboxes),
                loading: false,
            }
        }));

        return true;
    }
    catch (e) {
        dispatch(handleAPIError(e, "updating batch", BatchesActions.setError));
        dispatch(BatchesActions.updateItem({ batchID: batchID, data: { loading: false } }));

        return false;
    }
}

const batchUpdate = (batchesIDs: { partnerID: string, ID: string }[], data: BatchUpdatableFields) => async (dispatch: AppDispatch) => {
    dispatch(BatchesActions.startLoading());

    // mark all batches to update as loading
    dispatch(BatchesActions.updateItems(batchesIDs.map(({ ID }) => ({ id: ID, changes: { loading: true } }))));

    // batch writes in Firestore are limited to 500 documents at a time
    const batchesChunks = chunk(batchesIDs, 500);

    try {
        // update documents in the database 500 by 500
        for (let batchesChunk of batchesChunks) {
            const batchWrite = writeBatch(getFirestore());

            for (let { partnerID, ID } of batchesChunk) {
                batchWrite.update(getRef(partnerID, ID), data);
            }

            await batchWrite.commit();
        }

        dispatch(BatchesActions.updateItems(
            batchesIDs.map(({ ID }) => ({
                id: ID,
                changes: {
                    ...data,
                    bboxes: deserializeBoundingBox(ID, data.bboxes),
                    loading: false,
                }
            }))
        ));

        dispatch(BatchesActions.stopLoading());

        // show error message
        dispatch(showSuccess(i18next.t("batches.batch_update.success", { ns: Namespace.SNACKS })));

        return true;
    }
    catch (e) {
        dispatch(handleAPIError(e, "updating batches", BatchesActions.setError));
        dispatch(BatchesActions.updateItems(batchesIDs.map(({ ID }) => ({
            id: ID,
            changes: {
                ...data,
                bboxes: deserializeBoundingBox(ID, data.bboxes),
                loading: false
            }
        }))));
        dispatch(BatchesActions.stopLoading());

        return false;
    }

}

/**
 * Filter the Batches displayed on the map.
 * @param filters List of filters to apply on the list of Batches.
 * To filter by a given sorting error, pass the "error" fieldPath with the displayed error type as value (and any opStr)
 */
const applyMapBatchesFilters = (filters: QueryFilter<Batch & { "error"?: TrashType }>[], mapType: BatchesMapType) => (dispatch: AppDispatch) => {
    let batches = selectAllBatches(getStoreState());

    const mergingWasteMapping = getMergingWasteMapping();

    for (let filter of filters) {
        if (filter.fieldPath === "error") { // filter on "has.<error>" fields
            const subErrorTypes = unmergeTrashType(mergingWasteMapping, filter.value as TrashType);
            batches = batches.filter(batch => subErrorTypes.find(errorType => {
                return Boolean(batch.has[errorType]);
            }) !== undefined);
        }
        else { // filter on other Batch attributes
            batches = batches.filter(batch => batch[filter.fieldPath as keyof Batch] === filter.value);
        }
    }

    if (mapType === "collection") {
        dispatch(BatchesMapActions.setMapPoints(formatBatchesForMap(batches, mapType)));
    }
    else { // sorting map
        dispatch(SortingMapActions.setMapPoints({
            ...formatBatchesForMap(batches, mapType),
            totalBatchesCount: batches.length,
        }));
    }
}

/**
 * Filter the Addresses displayed on the map based on their number of errors.
 * @param dangerLevel Threshold for the minimum number of errors per address to be displayed.
 */
const filterAddressesByDangerLevel = (batches: Batch[], dangerLevel: DangerLevel) => {
    let { points } = formatBatchesForMap(batches, "sorting");

    let minLat = 999, minLng = 999, maxLat = -999, maxLng = -999;

    let filteredPoints: typeof points = [];
    let totalBatchesCount = 0;

    for (let point of points) {
        if (point.properties.errorsCount >= Number(dangerLevel)) {
            filteredPoints.push(point);

            totalBatchesCount += point.properties.batchesCount;

            const [lat, lng] = point.geometry.coordinates;

            // calculate map bounds and zoom to view all batches
            if (lat < minLat) minLat = lat;
            if (lat > maxLat) maxLat = lat;
            if (lng < minLng) minLng = lng;
            if (lng > maxLng) maxLng = lng;
        }
    }

    return {
        ...getCenterAndBounds(minLat, maxLat, minLng, maxLng),
        points: filteredPoints,
        totalBatchesCount,
    };
}

const getAIResults = (partnerID: string, batchID: string) => async (dispatch: any) => {
    dispatch(BatchesActions.updateItem({ batchID: batchID, data: { loading: true } }));

    const collectionRef = collection(getRef(partnerID, batchID), "ai_results");
    let q = query(collectionRef);

    try {
        const querySnapshot = await getDocs(q);
        let aiResults: BatchAIResult[] = querySnapshot.docs.map((doc) => {
            const data: BatchAIResultDb = doc.data() as BatchAIResultDb;
            return {
                ID: doc.id,
                class: data.class,
                box: JSON.parse(data.box),
                points: data.points ? JSON.parse(data.points) : undefined,
                score: data.score,
            }
        });

        dispatch(BatchesActions.updateItem({ batchID: batchID, data: { loading: false, aiResults: aiResults, } }));

        return aiResults;
    }
    catch (e) {
        dispatch(handleAPIError(e, "loading batch ai results", BatchesActions.setError));
        dispatch(BatchesActions.updateItem({ batchID: batchID, data: { loading: false } }));
        return [];
    }
}

/** 
 * Object using `addressKey` as keys and storing addresses stats in values 
 */
type SortingPointsByAddressKey = { [addressKey: string]: AddressStatsPointFeature };

/**
 * Format a list of batches in order to be displayed on a map.
 * Return type changes depending if the map is:
 *  - For a single collection: 1 batch is represented as 1 point on the map
 *  - For global sorting: batches are grouped by address
 */
function formatBatchesForMap(batchesItems: Batch[] | QueryDocumentSnapshot[], mapType: "collection"): { batches: Batch[], points: BatchPoint[] } & CenterAndBounds;
function formatBatchesForMap(batchesItems: Batch[] | QueryDocumentSnapshot[], mapType: "sorting"): { batches: Batch[], points: AddressStatsPointFeature[] } & CenterAndBounds;
function formatBatchesForMap(batchesItems: Batch[] | QueryDocumentSnapshot[], mapType: "collection" | "sorting") {
    let batches: Batch[] = [];
    let batchesPoints: BatchPoint[] = [];
    let addressesPointsDict: SortingPointsByAddressKey = {};

    let minLat = 999, minLng = 999, maxLat = -999, maxLng = -999;

    const sortingErrors = getSortingErrorClasses();
    const mergingWasteMapping = getMergingWasteMapping();

    for (const item of batchesItems) {
        const batch = item instanceof QueryDocumentSnapshot ? fromDbDoc(item) : item;
        batches.push(batch);

        const pos = batch.position;
        if (!pos || !batch.address) continue; // skip batches without GPS coordinates

        // cluster by hereID or formatted batch address (if hereID doesn't exist - legacy)
        const addressKey = getBatchKey(batch);

        if (mapType === "collection") { // 1 batch <=> 1 point
            batchesPoints.push({
                batchID: batch.ID,
                addressKey: addressKey,
                dangerLevel: getDangerLevel(getErrorsCount(sortingErrors, batch.results)),
                position: { lat: pos.latitude, lng: pos.longitude },
            });
        }
        else { // cluster by hereID or address
            addressesPointsDict[addressKey] = addBatchToSortingPointsDict(batch as PositionedBatch, addressesPointsDict, sortingErrors, mergingWasteMapping);
        }

        // calculate map bounds and zoom to view all batches
        if (pos.latitude < minLat) minLat = pos.latitude;
        if (pos.latitude > maxLat) maxLat = pos.latitude;
        if (pos.longitude < minLng) minLng = pos.longitude;
        if (pos.longitude > maxLng) maxLng = pos.longitude;
    }

    if (mapType === "collection") {
        return {
            batches,
            points: batchesPoints,
            ...getCenterAndBounds(minLat, maxLat, minLng, maxLng),
        };
    }
    else { // sorting map by addresses
        return {
            batches,
            points: Object.values(addressesPointsDict),
            ...getCenterAndBounds(minLat, maxLat, minLng, maxLng),
        };
    }
}

/**
 * Retrieve the danger level corresponding to a certain number of errors.
 */
const getDangerLevel = (errorsCount: number) => {
    const thresholds = Object.values(DangerLevel);
    thresholds.reverse();
    for (let threshold of thresholds) {
        if (errorsCount >= Number(threshold)) return threshold;
    }
    return DangerLevel.NONE;
}

/**
 * Add batch's data to existing sorting point for all batches at the same address
 */
function addBatchToSortingPointsDict(batch: PositionedBatch, addressesPointsDict: SortingPointsByAddressKey, sortingErrors: TrashType[], mergingWasteMapping: MergingWasteMapping): AddressStatsPointFeature {
    const getEmptyBatchPoint: (batch: PositionedBatch) => AddressStatsPointFeature = (batch) => ({
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [batch.position.latitude, batch.position.longitude],
        },
        properties: {
            ...pick(batch, ["hereID", "address",]),
            batchesCount: 0,
            batchesWithErrorsCount: 0,
            batchesIDs: [],
            errors: {},
            errorsCount: 0,
            dangerLevel: DangerLevel.NONE,
        },
    });
    // cluster by hereID or formatted batch address (if hereID doesn't exist - legacy)
    const addressKey = getBatchKey(batch);

    let existing = addressesPointsDict[addressKey];
    if (!existing) {
        existing = getEmptyBatchPoint(batch);
    }

    const batchErrors = getDisplayedErrors(sortingErrors, mergingWasteMapping, batch.results);
    const errorsCount = sum(Object.values(batchErrors));
    const addressErrorsCount = existing.properties.errorsCount + errorsCount;

    return {
        ...existing,
        properties: {
            ...existing.properties,
            batchesCount: existing.properties.batchesCount + 1,
            batchesWithErrorsCount: existing.properties.batchesWithErrorsCount + (errorsCount > 0 ? 1 : 0),
            batchesIDs: [...existing.properties.batchesIDs, batch.ID],
            errors: sumTrashCounts(existing.properties.errors, batchErrors),
            errorsCount: addressErrorsCount,
            dangerLevel: getDangerLevel(addressErrorsCount),
        }
    };
};

/**
 * Make a request to the API to download a PDF presenting the batch's details
 */
const getBatchPDF = (partnerID: string, batchID: string) => async (dispatch: AppDispatch) => {
    // indicate loading state on batch
    dispatch(BatchesActions.updateItem({
        batchID,
        data: { loading: true, }
    }));

    // format query
    const url = stringifyUrl({
        url: `${urls.REPORTS_API}/errors-pdf`,
        query: { partnerID, batchID, },
    });

    let data = null;
    let filename = "";

    try {
        // query report Excel file
        const res = await fetchAPI(url, {
            headers: {
                'Accept': 'application/pdf',
            }
        })
        data = res.data;
        filename = res.filename;
    }
    catch (e) {
        dispatch(handleAPIError(e, "loading batch PDF", BatchesActions.setError));
    }

    // stop loading batch
    dispatch(BatchesActions.updateItem({
        batchID,
        data: { loading: false, }
    }));

    return { data, filename };
}

const BatchesController = {
    getRef,
    getBatchRef,
    fromDbDoc,
    listPartnerBatchesForMap,
    applyMapBatchesFilters,
    filterAddressesByDangerLevel,
    list,
    listOlder,
    listByIDs,
    listByAddresses,
    listOlderByAddresses,
    switchBatchesAggregationLevel,
    getLast,
    getByID,
    update,
    batchUpdate,
    getAIResults,
    getDangerLevel,
    getBatchPDF,
}

export default BatchesController;