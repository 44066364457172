import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/hooks";
import { usePartnerID } from 'hooks/partners';
import { SearchParam } from "constants/urls";
import ReducerSelect from "./ReducerSelect";
import RoutesController from "controllers/routes";
import Route from "models/Route";
import { selectAllRoutes } from "store/reducers/routes/list";
import { SelectedRouteActions } from "store/reducers/routes/selected";
import RouteIcon from "../Icons/RouteIcon";

type RouteSelectProps = {
    parentLoading: boolean;
    needDefault?: boolean;
}

function RouteSelect(props: RouteSelectProps) {
    const { parentLoading, needDefault } = props;

    const partnerID = usePartnerID();
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.FORMS]);

    /** List routes upon first load and when partner changes */
    const listRoutes = () => {
        if (partnerID) {
            dispatch(RoutesController.list(partnerID, []));
        }
    };

    /** Show route. */
    const formatRouteLabel = (route: Route) => route.label;

    return (
        <ReducerSelect
            id="route-select-menu"
            parentLoading={parentLoading}
            loadingSelector={state => state.routes.list.loading}
            optionsSelector={selectAllRoutes}
            valueSelector={state => state.routes.selected.data}
            selectValue={SelectedRouteActions.selectRoute}
            formatOptionLabel={formatRouteLabel}
            searchParam={SearchParam.ROUTE_ID}
            onInit={{
                effect: listRoutes,
                deps: [partnerID],
            }}
            startIcon={<RouteIcon />}
            needDefault={needDefault}
            selectAllLabel={t("select.all_routes", { ns: Namespace.FORMS })}
            />
    );
}

export default RouteSelect;