import { Box } from "@mui/material";
import PartnersController from "controllers/partners";
import SectionLoader from "components/_include/SectionLoader";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useLayoutEffect } from "react";
import { useLocation, useNavigate, Outlet, useSearchParams, useMatch } from "react-router-dom";
import { useAppDispatch, useAppSelector, useHasPartner, useIsAdmin } from "hooks/hooks";
import { usePartnerID } from 'hooks/partners';
import { showTranslatedMessage } from "store/reducers/snacks";
import Navigator from "./Navigator";
import { SelectedPartnerActions } from "store/reducers/partners/selected";
import CollectionsSchedulesMethods from "controllers/collections_schedules";
import { selectAllPartners } from "store/reducers/partners/list";
import MissionsMethods from "controllers/mission";

export default function LoggedInLayout() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const dispatch = useAppDispatch();

    const isAdmin = useIsAdmin();

    const partnerID = usePartnerID();
    const partnerLoaded = useAppSelector(state => state.user.isFichaAdmin || state.partners.selected !== undefined);
    const partners = useAppSelector(state => selectAllPartners(state));

    const hasPartners = useHasPartner();

    // check if user is logged in
    const auth = getAuth();

    onAuthStateChanged(auth, firebaseUser => {
        if (!firebaseUser) { // user needs to log in
            dispatch(showTranslatedMessage({
                variant: "info",
                messageKey: "session_expired",
            }));
            dispatch(SelectedPartnerActions.setSelected(undefined)); // remove selected partner from store

            navigate(`/login/?next=${pathname}`, { replace: true });
        }
    });

    // scroll to top when navigating to different page
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (!partnerID) return;

        // load partner profile
        dispatch(PartnersController.retrieve(partnerID));

        // load collections schedules (to display circles on calendars)
        dispatch(CollectionsSchedulesMethods.list(partnerID));
        
        //load the list of mission for the selected partner
        dispatch(MissionsMethods.getMissions(partnerID));
    }, [partnerID]);

    useEffect(() => {
        if (hasPartners || isAdmin) { // load partners list
            dispatch(PartnersController.getPartners());
        }
    }, [hasPartners, isAdmin]);

    const [searchParams] = useSearchParams();
    const searchParamPartnerID = searchParams.get("partnerID");

    /** Set partner selected by default. */
    useEffect(() => {
        if (partners.length > 0) {
            let defaultSelected = partners[0]; // select first partner in list by default
            if (searchParamPartnerID) { // select partner from URL param partnerID
                let paramPartner = partners.find(p => p.ID === searchParamPartnerID);
                if (paramPartner) defaultSelected = paramPartner;
            }

            dispatch(PartnersController.select(defaultSelected));
        }
    }, [partners, searchParamPartnerID]);

    const isDemoRoute = useMatch('demo');

    if (!partnerLoaded) {
        return (
            <SectionLoader />
        );
    }

    return (
        <Box
            display="flex"
            width="100%"
        >
            {!isDemoRoute && <Navigator />}

            <Outlet />
        </Box>
    );
}