import ActionButton from 'components/_include/Buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { Download } from '@mui/icons-material';
import { useAppDispatch } from 'hooks/hooks';
import { MissionAddressesActions } from 'store/reducers/missions/addresses/list';

interface DownloadCampaignReportsButtonProps {
}

export default function DownloadCampaignReportsButton({ }: DownloadCampaignReportsButtonProps) {
    const { t } = useTranslation([Namespace.ACTIONS]);
    const dispatch = useAppDispatch();

    const handleOpenDialog = () => {
        dispatch(MissionAddressesActions.openReportsDialog());
    };

    return (
            <ActionButton
                color="secondary"
                component="label"
                startIcon={<Download />}
                onClick={handleOpenDialog}
            >
                {t("download_reports", { ns: Namespace.MISSIONS })}
            </ActionButton>
    );
}