import { Box } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, GridToolbarProps } from "@mui/x-data-grid";

export type DataGridToolbarProps = {
    /**
     * If `true`, hides the "Export" button from the top toolbar.
     * @default false
     */
    disableExport?: boolean;

    /**
     * If `true`, hides the "Quick search" input from the top toolbar.
     * @default false
     */
    disableQuickSearch?: boolean;
}

export default function DataGridToolbar({ disableExport, disableQuickSearch, quickFilterProps, csvOptions }: DataGridToolbarProps & GridToolbarProps) {
    return (
        <GridToolbarContainer>
            {!disableExport && (<GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions={csvOptions} />)}
            <Box sx={{ flexGrow: 1 }} />
            {!disableQuickSearch && (<GridToolbarQuickFilter {...quickFilterProps} />)}
        </GridToolbarContainer>
    );
}
  