import Collaborator from "models/Collaborator";
import { UserData, Permission } from "models/User";

export const convertToUserData = (collaborator: Collaborator, partnerID: string): UserData => ({
    name: `${collaborator.firstName} ${collaborator.lastName}`,
    partnerPermissions: {
        [partnerID]: collaborator.permissions,
    },
    partnerID: partnerID,
    email: collaborator.email,
});

// Filter collaborators with role ambassador and convert to UserData type
export function listAmbassadors(collaborators: Collaborator[], partnerID: string): UserData[] {
    return collaborators
        .filter((collaborator): collaborator is Collaborator =>
            collaborator?.permissions.includes(Permission.GET_ASSIGNED_CAMPAIGNS) && collaborator.email !== undefined)
        .map(collaborator => convertToUserData(collaborator, partnerID));
}