import { DataGridProps } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { FichaDataGrid } from 'components/_include/DataGrid/FichaDataGrid';

interface FichaDataGridProps extends DataGridProps {
  maxHeight?: number | string;
  minHeight?: number | string;
}

const MissionDataGrid: React.FC<FichaDataGridProps> = ({ maxHeight = 500, minHeight = 300, ...props }) => {
  return (
    <Box
      sx={{
        width: '100%',
        maxHeight,
        minHeight,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FichaDataGrid
        {...props}
        sx={{
          backgroundColor: '#ffffff',
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-visible': {
            outline: 'none',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'inherit',
          },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus-visible': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader.Mui-focusVisible': {
            backgroundColor: 'inherit',
          },
          '--DataGrid-overlayHeight': '300px',
          // Merge any additional styles passed via props
          ...props.sx,
        }}
        // // Default props
        disableRowSelectionOnClick
        disableExport
        slotProps={{
          baseCheckbox: {
            color: 'secondary',
          },
          ...props.slotProps,
        }}
      />
    </Box>
  );
};

export default MissionDataGrid;
