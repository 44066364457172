import { CenterAndBounds, ErrorsByAddress } from "constants/types";
import { PositionedBatch } from "models/Batch";
import { DangerLevel } from "constants/stats";
import SavedAddress from "models/SavedAddress";

export interface Coordinates {
    latitude: number;
    longitude: number;
}

/**
 * Additional properties used by GeoJSON softwares to style the markers 
 */
export type GeoJSONStyling = {
    "marker-color": string;
    "marker-size": "small" | "medium" | "large";
}

/**
 * Structure for a single batch displayed as a point on a map (e.g. single collection details)
 */
export type BatchPoint = {
    batchID: string;
    addressKey: string;
    dangerLevel: DangerLevel;
    position: google.maps.LatLngLiteral;
}

/**
 * Properties for a map point representing a single address,
 * as a "cluster" of the batches at this address over time
 */
export type AddressPointProperties = ErrorsByAddress & Pick<PositionedBatch, "address" | "hereID"> & {
    /** List of the IDs of the batches at this address */
    batchesIDs: string[];

    /** Number of batches at this address */
    batchesCount: number;

    /** Number of batches with at least 1 error at this address */
    batchesWithErrorsCount: number;

    /** Indicates how many errors were made at this address */
    dangerLevel: DangerLevel;
};

/**
 * GeoJSON feature representing a single point
 */
export type AddressStatsPointFeature = {
    type: "Feature";
    properties: AddressPointProperties,
    geometry: {
        type: "Point";
        coordinates: number[];
    };
}

type Viewport = {
    northeast: google.maps.LatLngLiteral;
    southwest: google.maps.LatLngLiteral;
};

export type MapView = {
    north: number;
    east: number;
    south: number;
    west: number;
}

export type MapGeometry = {
    location: google.maps.LatLngLiteral;
    viewport: Viewport;
}

export type SimpleAddress = {
    name: string;
    vicinity: string;
}

export const UNNAMED_ROAD = "Unnamed Road";

export const DEFAULT_ZOOM_LEVEL = 15;
export const MAX_ZOOM = 20;

export const GRENOBLE_COORDINATES = { lat: 45.19, lng: 5.72 };

export const DEFAULT_CENTER_AND_BOUNDS: CenterAndBounds = {
    bounds: {
        north: 0,
        east: 0,
        south: 0,
        west: 0,
    },
    center: GRENOBLE_COORDINATES,
};

export const DEFAULT_BOUNDS = {
    north: 0,
    east: 0,
    south: 0,
    west: 0,
};

export const DEFAULT_CENTER_AND_BOUNDS_NEW: CenterAndBoundsNew = {
    bounds: DEFAULT_BOUNDS,
    center: GRENOBLE_COORDINATES,
};


export function getBoundsZoomLevel(bounds: google.maps.LatLngBoundsLiteral, mapDim: { width: number, height: number }) {
    const WORLD_DIM = { height: 360, width: 360 };

    function latRad(lat: number) {
        const sin = Math.sin(lat * Math.PI / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx: number, worldPx: number, fraction: number) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const { north, east, south, west } = bounds;

    const latFraction = (latRad(north) - latRad(south)) / Math.PI;

    const lngDiff = east - west;
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, MAX_ZOOM);
}


/**
 * Get the default options for Google Maps in this app.
 */
export function getMapOptions(mapTypeControl: boolean): google.maps.MapOptions {
    const options: google.maps.MapOptions = {
        maxZoom: MAX_ZOOM,
        mapTypeControl: mapTypeControl,
        mapTypeControlOptions: mapTypeControl ? {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: [
                google.maps.MapTypeId.ROADMAP,
                google.maps.MapTypeId.SATELLITE,
            ],
        } : undefined,
        clickableIcons: false,
        fullscreenControl: false,
        zoomControl: mapTypeControl,
        streetViewControl: mapTypeControl,
        scaleControl: mapTypeControl,
        rotateControl: mapTypeControl,
    };

    return options;
}

export type CenterAndBoundsNew = {
    center: google.maps.LatLngLiteral;
    bounds: google.maps.LatLngBoundsLiteral;
}

export function getPointsBoundsAndCenter(points: google.maps.LatLngLiteral[]): CenterAndBoundsNew {
    let minLat = 999;
    let maxLat = -999;
    let minLng = 999;
    let maxLng = -999;

    for (let point of points) {
        if (point.lat < minLat) minLat = point.lat;
        if (point.lat > maxLat) maxLat = point.lat;
        if (point.lng < minLng) minLng = point.lng;
        if (point.lng > maxLng) maxLng = point.lng;
    }

    return getCenterAndBoundsNew(minLat, maxLat, minLng, maxLng);
}

export function getCenterAndBoundsNew(minLat: number, maxLat: number, minLng: number, maxLng: number): CenterAndBoundsNew {
    if (maxLat >= minLat && maxLng >= minLng) { // at least one point
        const center: google.maps.LatLngLiteral = {
            lat: (maxLat + minLat) / 2,
            lng: (maxLng + minLng) / 2,
        };
        const bounds: google.maps.LatLngBoundsLiteral = {
            north: maxLat,
            east: maxLng,
            south: minLat,
            west: minLng,
        }

        return { center, bounds };
    }
    else { // no point, return default
        return DEFAULT_CENTER_AND_BOUNDS_NEW;
    }
}

export const viewportToBounds = (viewport: Viewport) => {
    const north = viewport.northeast.lat;
    const south = viewport.southwest.lat;
    const east = viewport.northeast.lng;
    const west = viewport.southwest.lng;

    return {
        nw: { lat: north, lng: west, },
        ne: { lat: north, lng: east, },
        se: { lat: south, lng: east, },
        sw: { lat: south, lng: west, },
    };
}

export const mapViewToBounds = (viewport: MapView) => {
    const { north, east, south, west } = viewport;

    return {
        nw: { lat: north, lng: west, },
        ne: { lat: north, lng: east, },
        se: { lat: south, lng: east, },
        sw: { lat: south, lng: west, },
    };
}

export function getCenterAndBounds(minLat: number, maxLat: number, minLng: number, maxLng: number): CenterAndBounds {
    if (maxLat >= minLat && maxLng >= minLng) { // at least one point
        const center: google.maps.LatLngLiteral = {
            lat: (maxLat + minLat) / 2,
            lng: (maxLng + minLng) / 2,
        };
        const bounds: google.maps.LatLngBoundsLiteral = {
            north: maxLat,
            east: maxLng,
            south: minLat,
            west: minLng,
        }

        return { center, bounds };
    }
    else { // no point, return default
        return DEFAULT_CENTER_AND_BOUNDS;
    }
}

export const mvcArrayToCoords = (mvcArray: google.maps.MVCArray) => {
    return mvcArray.getArray().map(p => ({ lat: p.lat(), lng: p.lng() }))
}

/**
 * Check if two coordinates (object with lat and lng fields) are the same at a given level of precision passed as parameter
 */
export function areSameCoordinates(coord1: google.maps.LatLngLiteral, coord2: google.maps.LatLngLiteral, precision: number): boolean {
    const latPrecision = Math.pow(10, precision);
    const lngPrecision = Math.pow(10, precision);
    const roundedLat1 = Math.round(coord1.lat * latPrecision) / latPrecision;
    const roundedLng1 = Math.round(coord1.lng * lngPrecision) / lngPrecision;
    const roundedLat2 = Math.round(coord2.lat * latPrecision) / latPrecision;
    const roundedLng2 = Math.round(coord2.lng * lngPrecision) / lngPrecision;
    return roundedLat1 === roundedLat2 && roundedLng1 === roundedLng2;
}

/**
 * Extract and format the address components for display
 */
export function formatAddress(address: Pick<SavedAddress["address"], "houseNumber" | "street" | "city"> & { postalCode?: SavedAddress["address"]["postalCode"] }): string {
    if (!address) return "";

    const { houseNumber, street, city, postalCode } = address;

    let houseNumberPrefix = houseNumber ? `${houseNumber} ` : ""; // house number and space before street name
    let citySuffix = postalCode ? `${postalCode} ${city}` : city;

    return `${houseNumberPrefix}${street}, ${citySuffix}`;
}

/**
 * Converts a coordinate string to a number, handling both comma and dot decimal separators
 * @param coordinate - The coordinate string (e.g., "2,880514" or "2.880514")
 * @returns The converted coordinate as a number, or null if conversion fails
 */
export const convertCoordinate = (coordinate: string | null | undefined): number | null => {
    // If coordinate is null, undefined, or an empty string, return null
    if (!coordinate) return null;

    try {
        // Trim whitespace
        const trimmedCoordinate = coordinate.trim();

        // If the coordinate already looks like a standard decimal number, parse directly
        if (/^-?\d+\.\d+$/.test(trimmedCoordinate)) {
            return parseFloat(trimmedCoordinate);
        }

        // Replace comma with decimal point and parse as a float
        const convertedCoordinate = parseFloat(trimmedCoordinate.replace(',', '.'));

        // Check if the conversion resulted in a valid number
        return isNaN(convertedCoordinate) ? null : convertedCoordinate;
    } catch (error) {
        // Log any unexpected errors during conversion
        console.error(`Error converting coordinate: ${coordinate}`, error);
        return null;
    }
};
