import { Box } from "@mui/material";
import ExplorerFilters from "./ExplorerFilters";
import { SearchBatchesButton } from "./SearchBatchesButton";
import { useAppSelector } from "hooks/hooks";
import BatchesController from "controllers/batches";

export const LOADING_LIMIT = 9999;

export default function ExplorerFiltersAndActions() {

    const loading = useAppSelector(state => state.batches.list.loading);

    return (
        <Box
            display="flex"
            flexDirection="row"
            mb={2}
        >
            <ExplorerFilters />

            <SearchBatchesButton
                loadingLimit={LOADING_LIMIT}
                loading={loading}
                listBatchesAction={BatchesController.list}
                listOlderBatchesAction={BatchesController.listOlder}
            />
        </Box>
    )
}