import { Box, CircularProgress, IconButton } from '@mui/material';
import { PartnerStatus } from 'models/Partner';
import { useAppSelector } from 'hooks/hooks';
import EditIcon from '@mui/icons-material/EditRounded';
import PartnerStatusAndColor from './PartnerStatusAndColor';

type PartnerStatusTextProps = {
    status: PartnerStatus;
    onStartEdit: () => void;
}

export default function PartnerStatusText({ status, onStartEdit }: PartnerStatusTextProps) {

    const loading = useAppSelector(state => state.partners.selected.loading);

    return (
        <Box
            display="flex"
            alignItems="center"
        >
            <PartnerStatusAndColor
                status={status}
            />

            <IconButton
                onClick={onStartEdit}
                disabled={loading}
            >
                {loading && (
                    <CircularProgress />
                )}
                <EditIcon />
            </IconButton>
        </Box>
    );
}