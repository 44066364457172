import { Grid, IconButton, ListItem, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector, useIsAdmin } from "hooks/hooks";
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import { UserActions } from "store/reducers/user";
import WasteStreamSwitch from "./WasteStreamSwitch/WasteStreamSwitch";
import { getWasteStreamFontColor, getWasteStreamMainColor } from "constants/theme";
import { CollectionType } from "models/Collection";

/** Duration in seconds of css transitions. */
export const SWITCH_TRANSITION_SPEED = 0.2;

export default function DrawerTitle() {
    const isReallyAdmin = useAppSelector(state => state.user.isFichaAdmin);
    const isAdmin = useIsAdmin();
    const partnerName = useAppSelector(state => state.partners.selected.data?.name);

    const dispatch = useAppDispatch();

    const togglePartnerView = () => {
        dispatch(UserActions.togglePartnerView(isAdmin));
    }

    const selectedWasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);

    const fontColor = getWasteStreamFontColor(selectedWasteStream);

    return (
        <ListItem
            sx={{
                py: 2,
                px: 2.5,
                mb: 1,
                backgroundColor: getWasteStreamMainColor(selectedWasteStream),
                transition: `background-color ${SWITCH_TRANSITION_SPEED}s`,
                boxShadow: theme => theme.shadows[4],
                borderRadius: 4,
                position: "relative",
                flexDirection: "column",
                alignItems: "flex-start"
            }}
        >
            <Typography
                fontSize={24}
                fontWeight="bold"
                color={fontColor}
                sx={{
                    transition: `color ${SWITCH_TRANSITION_SPEED}s`,
                }}
            >
                ficha
            </Typography>
            <Grid container alignItems="center">
                <Grid item xs>
                    <Typography
                        color={fontColor}
                        fontSize={14}
                        sx={{
                            transition: `color ${SWITCH_TRANSITION_SPEED}s`,
                        }}
                    >
                        {isAdmin ? (
                            "Admin"
                        ) : (
                            partnerName ? partnerName : ""
                        )}
                    </Typography>
                </Grid>

                <Grid item xs="auto">
                    {isReallyAdmin && (
                        <IconButton
                            color={selectedWasteStream === CollectionType.SORTABLE_WASTE ? "black" : "white"}
                            onClick={togglePartnerView}
                            sx={{ p: .5 }}
                        >
                            <SwapHorizRoundedIcon />
                        </IconButton>
                    )}
                </Grid>

            </Grid>
            
            <WasteStreamSwitch />
        </ListItem>
    )
}