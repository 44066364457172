import { Grid, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BatchesController from "controllers/batches";
import { Shortcut } from "store/reducers/preferences/validation_shortcuts";
import { useEffect } from "react";

export default function ToggleDisplayButton() {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.batches.list.selectedBatch!.loading);
    const partnerID = useAppSelector(state => state.batches.list.selectedBatch!.partnerID);
    const batchID = useAppSelector(state => state.batches.list.selectedBatch!.ID);
    const display = useAppSelector(state => state.batches.list.selectedBatch!.display);

    const key = useAppSelector(state => state.preferences.validation[Shortcut.HIDE_BATCH]);

    /**
     * Bind the "Left arrow" key to go to previous batch
     */
    const handleKeydown = (e: KeyboardEvent) => {
        if (e.key === key) {
            if ((e.target as HTMLElement | null)?.tagName !== "INPUT") { // user not focused on an input
                toggleDisplay();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeydown);

        return () => window.removeEventListener('keydown', handleKeydown);
    }, [key]);

    const toggleDisplay = () => {
        dispatch(BatchesController.update(partnerID, batchID, {
            display: !display,
        }));
    }

    return (
        <Grid
            item
            xs={4}
            textAlign="center"
        >
            <IconButton
                onClick={() => toggleDisplay()}
                color="secondary"
                disabled={loading}
            >
                {display ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
        </Grid>
    )
}