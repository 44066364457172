import { GridRenderCellParams } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import { ReportExportData } from "models/Missions";

export enum ReportColumn {
    HOUSE_NUMBER = "houseNumber",
    STREET = "street",
    CITY = "city",
    INHABITANTS_SPOKEN = "inhabitantsSpoken",
    INHABITANTS_RECEPTIVE = "inhabitantsReceptive",
    SELECTED_TOPICS = "selectedTopics",
    COMMUNICATION_METHODS = "communicationMethods",
    OTHER_COMMENTS = "otherComments",
    ADDITIONAL_INFO = "additionalInfo",
    ASSIGNED_NAME = "assignedName",
    ASSIGNED_EMAIL = "assignedEmail",
}

export const useReportColumns = () => {
    const { t } = useTranslation([Namespace.GLOSSARY, Namespace.MISSIONS]);

    return [
        {
            field: ReportColumn.HOUSE_NUMBER,
            headerName: t("house_number", { ns: Namespace.GLOSSARY }),
            width: 70,
        },
        {
            field: ReportColumn.STREET,
            headerName: t("street", { ns: Namespace.GLOSSARY }),
            minWidth: 160,
        },
        {
            field: ReportColumn.CITY,
            headerName: t("city", { ns: Namespace.GLOSSARY }),
            minWidth: 120,
        },
        {
            field: ReportColumn.INHABITANTS_SPOKEN,
            headerName: t("inhabitants_spoken", { ns: Namespace.MISSIONS }),
            width: 180,
        },
        {
            field: ReportColumn.INHABITANTS_RECEPTIVE,
            headerName: t("receptiveness", { ns: Namespace.MISSIONS }),
            width: 150,
            renderCell: (params: GridRenderCellParams<ReportExportData, string>) => (
                <Box sx={{ whiteSpace: "pre-line" }}>
                    {Array.isArray(params.value) ? params.value.join("\n") : params.value}
                </Box>
            ),
        },
        {
            field: ReportColumn.SELECTED_TOPICS,
            headerName: t("discussed_topics.title", { ns: Namespace.MISSIONS }),
            width: 250,
            renderCell: (params: GridRenderCellParams<ReportExportData, string[]>) => (
                <Box sx={{ whiteSpace: "pre-line" }}>
                    {Array.isArray(params.value) ? params.value.join("\n") : params.value}
                </Box>
            ),
        },
        {
            field: ReportColumn.COMMUNICATION_METHODS,
            headerName: t("communication_methods.title", { ns: Namespace.MISSIONS }),
            width: 250,
        },
        {
            field: ReportColumn.OTHER_COMMENTS,
            headerName: t("other_comments", { ns: Namespace.MISSIONS }),
            width: 250,
        },
        {
            field: ReportColumn.ADDITIONAL_INFO,
            headerName: t("additional_info", { ns: Namespace.MISSIONS }),
            width: 250,
        },
        {
            field: ReportColumn.ASSIGNED_NAME,
            headerName: t("ambassador_name", { ns: Namespace.MISSIONS }),
            width: 180,
        },
        {
            field: ReportColumn.ASSIGNED_EMAIL,
            headerName: t("ambassador_email", { ns: Namespace.MISSIONS }),
            width: 180,
        },
    ];
}; 