import { Box, Paper, Typography } from '@mui/material';
import SectionLoader from 'components/_include/SectionLoader';
import { useMemo } from 'react';
import { useAppSelector, useSortingRules } from 'hooks/hooks';
import { selectAllBatches } from 'store/reducers/batches/list';
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { useSpacing } from 'constants/theme';
import moment from 'moment';
import { DANGER_COLORS } from 'helpers/draw';
import { getErrorsCount } from 'helpers/trash';
import BatchesController from 'controllers/batches';
import { DangerLevel } from 'constants/stats';

type CollectionTimelineProps = {

}

type DataCoordinates = { x: string, y: number };
type PointsPerDangerLevel = { [level in DangerLevel]: DataCoordinates[] };

function CollectionTimeline({ }: CollectionTimelineProps) {
    const { t } = useTranslation([Namespace.CHARTS, Namespace.COMMONS, Namespace.STATS]);

    const { errorsClasses } = useSortingRules();

    const marginTop = useSpacing(2);
    const marginBottom = useSpacing(4);
    const marginLeft = useSpacing(4);
    const marginRight = useSpacing(2);
    const yLegendOffset = useSpacing(-3);

    const loading = useAppSelector(state => state.batches.list.loading);
    const batches = useAppSelector(state => selectAllBatches(state));

    const data = useMemo(() => {
        let categorizedBatches: PointsPerDangerLevel = Object.values(DangerLevel).reduce((acc, level) => ({ ...acc, [level]: [] }), {} as PointsPerDangerLevel);

        [...batches].reverse().forEach(batch => {
            const batchData = {
                x: moment(batch.timestamp).format("YYYY-MM-DD HH:mm:ss"),
                y: Object.values(batch.results).reduce((acc, count) => acc + count, 0), // sum number of instances in batch
            };
            const dangerLevel = BatchesController.getDangerLevel(getErrorsCount(errorsClasses, batch.results));
            categorizedBatches[dangerLevel].push(batchData);
        });

        return Object.entries(categorizedBatches).map(([id, data]) => ({ id, data }));
    }, [batches]);

    return (
        <Box
            width="100%"
            height={theme => theme.spacing(20)}
            mb={8}
            position="relative"
        >
            <Typography variant="h3">{t("timeline", { ns: Namespace.COMMONS })}</Typography>
            {loading ? (
                <SectionLoader />
            ) : (
                <ResponsiveScatterPlot
                    data={data}
                    margin={{ bottom: marginBottom, left: marginLeft, top: marginTop, right: marginRight, }}
                    xScale={{
                        type: "time",
                        format: "%Y-%m-%d %H:%M:%S",
                        precision: "minute",
                        useUTC: false,
                    }}
                    yScale={{
                        type: "linear",
                        min: 0,
                        max: "auto",
                    }}
                    enableGridY={false}
                    xFormat={(value) => t("hours_and_minutes", { ns: Namespace.DATES, time: value })}
                    yFormat={(value) => t("instances_count", { ns: Namespace.STATS, count: Math.round(value * 1000) / 1000 })}
                    axisBottom={{
                        format: "%H:%M",
                        tickValues: "every 30 minutes",
                    }}
                    axisLeft={{
                        legend: t("waste_instances", { ns: Namespace.CHARTS }),
                        legendOffset: yLegendOffset,
                        format: () => "",
                        tickSize: 0,
                    }}
                    tooltip={(props) => {
                        return (
                            <Paper
                                sx={{ px: 2, py: 1 }}>
                                <Typography variant="caption">
                                    {props.node.formattedX}
                                </Typography>
                                <Typography variant="body1">
                                    {props.node.formattedY}
                                </Typography>
                            </Paper>
                        );
                    }}
                    colors={({ serieId }) => DANGER_COLORS[serieId as DangerLevel]}
                />
            )}
        </Box>
    );
}

export default CollectionTimeline;