import ActionButton from "components/_include/Buttons/ActionButton";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffRounded";
import CheckIcon from "@mui/icons-material/CheckRounded";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { usePartnerID } from 'hooks/partners';
import { BatchesActions, selectAllBatches } from "store/reducers/batches/list";
import BatchesController from "controllers/batches";

export default function HideManyImagesButton() {
    const { t } = useTranslation([Namespace.ACTIONS]);

    const partnerID = usePartnerID();

    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.batches.list.loading);

    const selecting = useAppSelector(state => state.batches.list.hidingMany);

    const selectedBatches = useAppSelector(state => selectAllBatches(state).filter(batch => batch.selected));

    /** Hide all the batches that were selected */
    const hideSelectedBatches = () => {
        if (!partnerID) return; 
        
        const batchesToHide = selectedBatches.map(batch => ({
            partnerID,
            ID: batch.ID
        }));

        dispatch(BatchesController.batchUpdate(batchesToHide, { display: false }))
            .then(() => {
                dispatch(BatchesActions.toggleHidingMany(false));
            });
    }

    /** Start/stop selecting batches to hide */
    const toggleSelecting = () => {
        dispatch(BatchesActions.toggleHidingMany(!selecting));
    }

    return (
        <ActionButton 
            color="warning"
            startIcon={selecting ? <CheckIcon /> : <VisibilityOffIcon />}
            size="compact"
            onClick={selecting && selectedBatches.length > 0 ? hideSelectedBatches : toggleSelecting}
            loading={loading}
            style={{
                whiteSpace: "nowrap"
            }}
        >
            {t(selecting ? "hide_count" : "hide_many", { ns: Namespace.ACTIONS, count: selectedBatches.length })}
        </ActionButton>
    )
}