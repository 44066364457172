import { Grid, Typography } from '@mui/material';
import ActionButton from 'components/_include/Buttons/ActionButton';
import { BatchDbData } from 'models/Batch';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { selectAllBatchesToDownload } from 'store/reducers/batches/to_download';
import BatchesController from 'controllers/batches';

type MarkDownloadedImagesProps = {

}

enum DownloadCategory {
    ANNOTATION,
    AUGMENTATION,
};

function MarkDownloadedImages({ }: MarkDownloadedImagesProps) {

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.IMAGES]);

    const batches = useAppSelector(selectAllBatchesToDownload);
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.batches.list.loading);

    const markDownloadedBatches = (category: DownloadCategory) => {
        const batchesIDs = batches.map(batch => ({
            partnerID: batch.partnerID,
            ID: batch.ID,
        }));

        let updatedData: Partial<Pick<BatchDbData, "downloadedToAnnotate" | "downloadedToAugment">> = {};
        
        switch (category) {
            case DownloadCategory.ANNOTATION:
                updatedData.downloadedToAnnotate = true;
                break;

            case DownloadCategory.AUGMENTATION:
                updatedData.downloadedToAugment = true;
                break;
        }

        dispatch(BatchesController.batchUpdate(batchesIDs, updatedData));
    } 

    return (
        <Grid container mb={4}>
            <Grid item xs={12}>
                <Typography
                    variant="h4"
                    >
                    {t("to_annotate.dialog.body.mark_image_as", { ns: Namespace.IMAGES, count: batches.length })}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <ActionButton
                    startIcon={<FormatShapesIcon />}
                    loading={loading}
                    color="secondary"
                    onClick={() => markDownloadedBatches(DownloadCategory.ANNOTATION)}
                    >
                    {t("to_annotate.downloaded_for_annotation", { ns: Namespace.IMAGES, count: batches.length })}
                </ActionButton>
            </Grid>
            <Grid item xs={6}>
                <ActionButton
                    startIcon={<ViewInArIcon />}
                    loading={loading}
                    color="secondary"
                    onClick={() => markDownloadedBatches(DownloadCategory.AUGMENTATION)}
                    >
                    {t("to_annotate.downloaded_for_augmentation", { ns: Namespace.IMAGES, count: batches.length })}
                </ActionButton>
            </Grid>
        </Grid>
    );
}

export default MarkDownloadedImages;