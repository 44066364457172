import { Box, ButtonBase, Modal, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import AddIcon from '@mui/icons-material/AddRounded';
import { useState } from 'react';
import PartnerCard from '../PartnerCard/PartnerCard';
import NewPartnerModal from './NewPartnerModal';

type NewPartnerButtonProps = {

}

export default function NewPartnerButton({ }: NewPartnerButtonProps) {
    // const x = useAppSelector(state => state);
    // const dispatch = useAppDispatch();

    const [dialogOpen, setDialogOpen] = useState(false);

    const { t } = useTranslation([Namespace.PARTNERS]);

    return (
        <>
            <ButtonBase
                sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 4,
                    borderWidth: 4,
                    borderColor: theme => theme.palette.grey[600],
                    borderStyle: "dashed",
                    display: "flex",
                    flexDirection: "column"
                }}
                onClick={() => setDialogOpen(true)}
            >
                <AddIcon
                    sx={{
                        fontSize: 48,
                        color: theme => theme.palette.grey[600],
                    }}
                />
                <Typography
                    fontSize={26}
                    fontWeight="bold"
                    sx={{
                        color: theme => theme.palette.grey[600],
                    }}
                >
                    {t("add_partner", { ns: Namespace.PARTNERS })}
                </Typography>
            </ButtonBase>

            <NewPartnerModal open={dialogOpen} onClose={() => setDialogOpen(false)} />
        </>
    );
}