import { Box, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import ChangeCollectionTypeButton from './ChangeCollectionType/ChangeCollectionTypeButton';
import DownloadCollectionReportButton from './DownloadCollectionReport/DownloadCollectionReportButton';
import { useAppDispatch, useCanWrite } from 'hooks/hooks';
import { usePartnerID } from 'hooks/partners';
import { CollectionRFIDActions } from 'store/reducers/collections/rfid';
import UploadSpreadSheetButton from '../../../_include/Buttons/UploadSpreadSheetButton';
import UploadRFIDDialog from './UploadRFIDExcel/UploadRFIDDialog/UploadRFIDDialog';
import { MissionSpreadsheetActions } from 'store/reducers/missions/addresses/spreadsheet';

type CollectionActionsProps = {

}

function CollectionActions({ }: CollectionActionsProps) {
    const partnerID = usePartnerID();
    const canWrite = useCanWrite(partnerID);

    const { t } = useTranslation([Namespace.COMMONS]);
    const dispatch = useAppDispatch();

    const handleDataParsed = (data: any) => {
        dispatch(CollectionRFIDActions.setData(data));
        dispatch(MissionSpreadsheetActions.stopLoadingSpreadsheet());
    };

    return (
        <Box>
            <Typography
                variant="h3"
                mb={4}
            >
                {t("actions", { ns: Namespace.COMMONS })}
            </Typography>

            <Box
                display="flex"
                justifyContent="flex-start"
                gap={2}
            >
                <DownloadCollectionReportButton />

                {canWrite && (
                    <>
                        <UploadSpreadSheetButton
                            label={t("upload rfid", { ns: Namespace.ACTIONS })}
                            onDataParsed={handleDataParsed}
                        />

                        <UploadRFIDDialog />
                    </>
                )}

                {canWrite && (
                    <ChangeCollectionTypeButton />
                )}
            </Box>
        </Box>
    );
}

export default CollectionActions;