import { LoadingButton } from "@mui/lab";
import { Dialog, DialogTitle, DialogContent, Divider, DialogActions, Button } from "@mui/material";
import DialogIcon from "components/_include/DialogIcon";
import { isValidEmail } from "helpers/strings";
import { Namespace } from "locales/translations";
import { Permission, ROLE_TO_PERMISSIONS_MAP, UserRole } from "models/User";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import { TextInput } from "helpers/forms";
import MailIcon from "@mui/icons-material/MailRounded";
import Collaborator, { NewCollaborator } from "models/Collaborator";
import { RoleAndPermissionSelector } from "./RoleAndPermissionSelector";
import { CollaboratorFormFields } from "./CollaboratorFormFields";
import { useAppSelector } from "hooks/hooks";
import { selectAllCollaborators } from "store/reducers/collaborators/list";

interface CollaboratorDialogProps {
    open: boolean;
    onClose: () => void;
    initialData?: Collaborator;
    onSubmit: (data: Collaborator | NewCollaborator) => void;
}

interface Inputs {
    firstName: TextInput;
    lastName: TextInput;
    email: TextInput;
    role: TextInput;
}


export default function CollaboratorDialog({
    open,
    onClose,
    initialData,
    onSubmit,
}: CollaboratorDialogProps) {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.CONTACTS, Namespace.FORMS]);
    const loading = useAppSelector((state) => state.collaborators.selected.loading);
    const collaborators = useAppSelector(selectAllCollaborators);
    const existingEmails = collaborators.map((c) => c.email);

    const [inputs, setInputs] = useState<Inputs>({
        firstName: { value: initialData?.firstName || "", error: null },
        lastName: { value: initialData?.lastName || "", error: null },
        email: { value: initialData?.email || "", error: null },
        role: { value: "", error: null },
    });
    const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(initialData?.permissions || [Permission.READ_PARTNER_DATA]);

    useEffect(() => {
        if (inputs.role.value) {
            const rolePermissions = ROLE_TO_PERMISSIONS_MAP[inputs.role.value as UserRole] || [];
            setSelectedPermissions(rolePermissions);
        }
    }, [inputs.role.value]);


    const handleInputChange = (field: keyof Inputs, value: string) => {
        setInputs((prev) => ({
            ...prev,
            [field]: { value, error: null },
        }));
    };

    const handleRoleChange = (newRole: string) => {
        handleInputChange("role", newRole);
    };

    const handlePermissionChange = (permission: Permission, checked: boolean) => {
        if (checked) {
            setSelectedPermissions((prev) => [...prev, permission]);
        } else {
            setSelectedPermissions((prev) => prev.filter((p) => p !== permission));
        }
    };

    function validateRole(role: string): Exclude<UserRole, UserRole.ADMIN> {
        const validRoles = [
            UserRole.PARTNER_ADMIN,
            UserRole.PARTNER_MEMBER,
            UserRole.AMBASSADOR
        ];

        return validRoles.includes(role as UserRole)
            ? (role as Exclude<UserRole, UserRole.ADMIN>)
            : UserRole.PARTNER_MEMBER;
    }

    const handleSubmit = () => {
        if (!isValidEmail(inputs.email.value)) {
            setInputs((prev) => ({
                ...prev,
                email: { ...prev.email, error: t("sign_in.invalid_email", { ns: Namespace.FORMS }) },
            }));
            return;
        }
        if (!initialData && existingEmails.includes(inputs.email.value)) {
            setInputs((prev) => ({
                ...prev,
                email: { ...prev.email, error: t("sign_in.email_already_exists", { ns: Namespace.FORMS }) },
            }));
            return;
        }
        // Build the base collaborator data
        const collaboratorData: NewCollaborator | Collaborator = {
            firstName: inputs.firstName.value,
            lastName: inputs.lastName.value,
            email: inputs.email.value,
            role: validateRole(inputs.role.value),
            permissions: selectedPermissions,
        };

        // If initialData exists (edit mode), add the ID and addedAt fields
        if (initialData) {
            Object.assign(collaboratorData, {
                ID: initialData.ID,
                addedAt: initialData.addedAt,
            });
        }

        onSubmit(collaboratorData);
    };


    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogIcon Icon={GroupsRoundedIcon} />
            <DialogTitle align="center">
                {initialData ? t("edit collaborator", { ns: Namespace.CONTACTS }) : t("add collaborator", { ns: Namespace.ACTIONS })}
            </DialogTitle>
            <DialogContent>
                <CollaboratorFormFields inputs={inputs} onInputChange={handleInputChange} loading={loading} />
                <Divider sx={{ my: 2 }} />
                <RoleAndPermissionSelector
                    role={inputs.role.value}
                    onRoleChange={handleRoleChange}
                    selectedPermissions={selectedPermissions}
                    onPermissionChange={handlePermissionChange}
                    loading={loading}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        color: theme => theme.palette.grey[700],
                    }}
                    onClick={onClose}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <LoadingButton
                    variant="text"
                    color="success"
                    onClick={handleSubmit}
                    endIcon={<MailIcon />}
                    disabled={
                        loading ||
                        !(
                            inputs.firstName.value &&
                            inputs.lastName.value &&
                            inputs.email.value &&
                            selectedPermissions.length > 0
                        )
                    }
                >
                    {initialData
                        ? t("save_changes", { ns: Namespace.ACTIONS })
                        : t("send invitation", { ns: Namespace.ACTIONS })}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
