import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { BatchMediaOverlayType, MediaOverlayActions, selectResultBboxesIds } from 'store/reducers/batches/media_overlay';
import BoundingBox from './BoundingBox';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { BBOX_ID_PREFIX, BBOX_LABEL_SUFFIX } from 'models/BoundingBox';
import BoundingBoxesContextMenu, { ContextMenuPosition } from './BoundingBoxesContextMenu/BoundingBoxesContextMenu';

type BoundingBoxesLayerProps = {
    canEditResults?: boolean;
    width: number;
    height: number;
    left?: number;
    top?: number;
}

/**
 * Display all of the bounding boxes on top of the batch's image
 */
export default function BoundingBoxesLayer({ canEditResults, ...containerDims }: BoundingBoxesLayerProps) {

    const hidden = useAppSelector(state => state.batches.mediaOverlay.overlayType !== BatchMediaOverlayType.BBOXES);

    const batchID = useAppSelector(state => state.batches.list.selectedBatch?.ID);
    const aiBboxes = useAppSelector(state => state.batches.list.selectedBatch?.bboxes);

    /**
     * Need to use a ref to get value from event listeners
     */
    const selectedBBoxID = useAppSelector(state => state.batches.mediaOverlay.selectedBoxID);
    const selectedBBoxIDRef = useRef<string | null>(null);
    useEffect(() => {
        selectedBBoxIDRef.current = selectedBBoxID;
    }, [selectedBBoxID]);

    const dispatch = useAppDispatch();

    const bboxesIDs = useAppSelector(selectResultBboxesIds);

    const scaleX = useAppSelector(state => containerDims.width / state.batches.mediaOverlay.imageWidth);
    const scaleY = useAppSelector(state => containerDims.height / state.batches.mediaOverlay.imageHeight);

    /**
     * Update the bounding boxes when the selected batch changes
     */
    useEffect(() => {
        if (batchID) dispatch(MediaOverlayActions.setAll(aiBboxes ?? []));
    }, [batchID]);

    /**
     * Handle clicking inside or outside of a bounding box to select/unselect it
     */
    const handleMouseClick = (e: MouseEvent) => {
        const target = e.target as HTMLElement | null;
        if (!target) return;

        const clickTargetID = target.id;

        if (clickTargetID.startsWith(BBOX_ID_PREFIX)) { // click on a bounding box
            const sanitizedBboxID = clickTargetID.replace(BBOX_LABEL_SUFFIX, "");
            dispatch(MediaOverlayActions.selectBox(sanitizedBboxID));
        }
        else if (selectedBBoxIDRef.current !== null) { // click outside of a bounding box
            dispatch(MediaOverlayActions.selectBox(null));
        }
    }

    /** State for displaying context menu when right clicking on bounding box */
    const [contextMenuPosition, setContextMenuPosition] = useState<ContextMenuPosition | null>(null);

    const openBboxContextMenu = useCallback((e: MouseEvent) => {
        setContextMenuPosition({
            mouseX: e.clientX + 2,
            mouseY: e.clientY - 6,
        });
    }, []);

    /**
     * Handle right click inside or outside of a bounding box to select/unselect it
     */
    const handleMouseRightClick = (e: MouseEvent) => {
        const target = e.target as HTMLElement | null;
        if (!target) return;

        const clickTargetID = target.id;

        if (clickTargetID.startsWith(BBOX_ID_PREFIX)) { // click on a bounding box
            e.preventDefault();
            const sanitizedBboxID = clickTargetID.replace(BBOX_LABEL_SUFFIX, "");

            // select and highlight box to prevent any misunderstanding
            dispatch(MediaOverlayActions.selectBox(sanitizedBboxID));

            // open menu for quick actions on bounding boxes
            openBboxContextMenu(e);
        }
    }

    /**
     * Add mouse events when interacting with bounding boxes
     */
    useEffect(() => {
        document.addEventListener('click', handleMouseClick);
        document.addEventListener('contextmenu', handleMouseRightClick);

        return () => {
            document.removeEventListener('click', handleMouseClick);
            document.removeEventListener('contextmenu', handleMouseRightClick);
        }
    }, []);

    if (hidden) return null;

    return (
        <Box
            position="absolute"
            sx={{
                pointerEvents: "none",
                ...containerDims,
            }}
        >
            {bboxesIDs.map(bboxID => (
                <BoundingBox
                    key={bboxID}
                    bboxID={bboxID.toString()}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    canEditResults={canEditResults}
                />
            ))}

            <BoundingBoxesContextMenu
                position={contextMenuPosition}
                onClose={() => setContextMenuPosition(null)}
            />
        </Box>
    );
}