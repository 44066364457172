import { Box, CircularProgress, FormControl, IconButton, InputLabel, OutlinedInput, TextField, useTheme } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { selectPartnerById } from 'store/reducers/partners/list';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PartnersController from 'controllers/partners';

type PartnerNameInputProps = {
    partnerID: string | null;
    onChange?: (name: string | undefined) => void;
    onClose: () => void;    
}

function PartnerNameInput({ partnerID, onChange, onClose }: PartnerNameInputProps) {
    const partnerName = useAppSelector(state => partnerID ? selectPartnerById(state, partnerID)?.name ?? "" : "");

    const loading = useAppSelector(state => state.partners.selected.loading);

    const [value, setValue] = useState(partnerName);

    const dispatch = useAppDispatch();

    const submitPartnerName = async () => {
        if (partnerID && value !== partnerName) { // name has been changed   
            await dispatch(PartnersController.update(partnerID, { name: value }));
        }

        onClose();
    };

    const { t } = useTranslation([Namespace.FORMS]);

    const inputID = `partner-name-input-${partnerID}`;
    const inputLabel = t("manage_partners.name", { ns: Namespace.FORMS });

    const theme = useTheme();

    const handleChange = (value: string) => {
        setValue(value);
        if (onChange) onChange(value);
    }

    return (
        <FormControl variant="outlined">
            <InputLabel htmlFor={inputID}>{inputLabel}</InputLabel>
            <OutlinedInput
                id={inputID}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                label={inputLabel}
                endAdornment={
                    partnerID && ( // for new partner, no end adornment
                        loading ? ( // updating in progress
                            <Box
                                position="relative"
                                >
                                <CircularProgress 
                                    sx={{ position: "relative" }}
                                    size={theme.spacing(3)} 
                                    />
                            </Box>
                        ) : ( // editing by user
                            <IconButton
                                onClick={submitPartnerName}
                                >
                                <CheckRoundedIcon />
                            </IconButton>
                        )
                    )
                }
            />
        </FormControl>
    );
}

export default PartnerNameInput;