import { Box } from '@mui/material';
import { getFileURL, getPictureURL } from 'helpers/storage';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { selectPartnerById } from 'store/reducers/partners/list';

type PartnerLogoProps = {
    logoURL: string;
}

function PartnerLogo({ logoURL }: PartnerLogoProps) {
    const [imageSrc, setImageSrc] = useState<string>();

    useEffect(() => {
        if (logoURL) {
            getFileURL(`gs://${process.env.REACT_APP_PROJECT_ID}-partners/${logoURL}`)
                .then(imageURL => {
                    if (imageURL) setImageSrc(imageURL);
                });
        }
    }, [logoURL]);

    return (
        <Box 
            width="100%"
            height="100%"
            sx={{
                backgroundImage: `url(${imageSrc})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }}
            />
    );
}

export default PartnerLogo;