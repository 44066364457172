import { Box } from "@mui/material";
import { TrashType } from "constants/trash";
import { useEffect } from "react";
import WastesForm from "./WastesForm/WastesForm";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import BatchCardMedia from "./BatchCardMedia/BatchCardMedia";
import { WasteInputValues, WastesInputsActions } from "store/reducers/batches/wastes_inputs";

type CardProps = {
    canEditResults?: boolean;
}

/** 
 * Content of the validation modal presenting the batch's image
 * and the bounding boxes details on the right
 */
function UpdateBatchCard({ canEditResults, }: CardProps) {

    const batch = useAppSelector(state => state.batches.list.selectedBatch);

    const dispatch = useAppDispatch();

    /**
     * Set the batch's results into the wastes inputs
     * every time the selected batch changes
     */
    useEffect(() => {
        const results: WasteInputValues = Object.values(TrashType).reduce((acc, wasteType) => {
            acc[wasteType] = {
                count: batch?.results[wasteType] || 0,
                open: false,
            };
            return acc;
        }, {} as WasteInputValues);

        dispatch(WastesInputsActions.setAll(results));
    }, [batch?.ID]);

    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="flex-end"
            bgcolor="#fff"
            sx={{
                pointerEvents: "all",
            }}
        >
            <BatchCardMedia
                canEditResults={canEditResults}
            />

            <WastesForm
                canEditResults={canEditResults}
            />
        </Box>
    );
}

export default UpdateBatchCard;