import { Box, Container, Divider, Typography } from '@mui/material';
import SortingMapPage from 'components/Map/SortingMapPage';
import PageTitle from 'components/_include/PageTitle';
import CollaboratorsController from 'controllers/collaborators';
import MissionsMethods from 'controllers/mission';
import { usePrevious } from 'helpers/refs';
import { Namespace } from 'locales/translations';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { usePartnerID } from 'hooks/partners';
import MissionDetailHeader from './MissionDetailheader';
import MissionAddressesMethods from 'controllers/sensitization_addresses';
import MissionAddressesTable from './AddAddressesSection/MissionAddressesTable';
import { MissionStatus } from 'models/Missions';
import ReportStatsCard from '../MissionsReportStats/ReportStatsCard';
import ReportNotAvailableCard from '../MissionsReportStats/ReportNotAvailableCard';
import SectionLoader from 'components/_include/SectionLoader';
import AddAddressesSection from './AddAddressesSection/AddAddressesSection';


type MissionDetailPageProps = {

};

/** Size in spacing units (8px) covered by the page title and mission details on top of the map. */
export const HEADER_HEIGHT_SPACING = 36;

const MissionDetailPage: React.FC<MissionDetailPageProps> = () => {
    const { t } = useTranslation([Namespace.MISSIONS, Namespace.TITLES]);

    const partnerID = usePartnerID();
    const dispatch = useAppDispatch();
    const { missionID } = useParams();

    const loading = useAppSelector(state => state.missions.selectedMission.loading);
    const wasLoading = usePrevious(loading);

    const missionStatus = useAppSelector(state => state.missions.selectedMission.data?.status);
    const missionName = useAppSelector(state => state.missions.selectedMission.data?.name);

    useEffect(() => {
        if (partnerID && missionID) {
            dispatch(CollaboratorsController.list(partnerID));
            dispatch(MissionsMethods.retrieve(partnerID, missionID));
            dispatch(MissionAddressesMethods.getAddresses(partnerID, missionID));
        }
    }, [partnerID, dispatch, missionID]);

    const aggregatedMissionStats = useAppSelector(state => state.missions.missionAddresses.aggregatedStats)!;

    if (loading) {
        return <SectionLoader />
    }

    return (
        <Container>
            {!wasLoading || missionName ? (
                <Box padding={2}>
                    <PageTitle title={t("edit_mission_name", { ns: Namespace.TITLES, name: missionName })} hidePartnerSelect={true} canGoBack={true} />
                    
                    <MissionDetailHeader />

                    <Divider sx={{ marginY: 4 }} />

                    {missionStatus === MissionStatus.COMPLETED ? (
                        <>
                            {aggregatedMissionStats ? (
                                <ReportStatsCard aggregatedStats={aggregatedMissionStats} />
                            ) : (
                                <ReportNotAvailableCard />
                            )
                            }
                            <Box marginY={5}>
                                <SortingMapPage showForMissions={true} />
                            </Box>
                        </>
                    ) : (
                        <AddAddressesSection />
                    )}

                    <Divider sx={{ marginY: 4 }} />

                    <MissionAddressesTable />
                </Box>
            ) : (
                <Box>
                    <PageTitle title={t("edit_mission", { ns: Namespace.TITLES })} hidePartnerSelect={true} canGoBack={true} />
                    <Typography>{t("mission not found", { ns: Namespace.MISSIONS })}</Typography>
                </Box>
            )}
        </Container >
    );
};

export default MissionDetailPage;
