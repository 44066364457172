import { Typography } from "@mui/material";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";

export default function NewTag() {
    const { t } = useTranslation([Namespace.COMMONS]);

    return (
        <Typography 
            fontSize={8} 
            fontWeight={500}
            textTransform="capitalize"
            sx={{
                px: 0.5,
                py: 0.25,
                borderRadius: 1,
                bgcolor: theme => theme.palette.info.main,
            }}
            >
            {t("new", { ns: Namespace.COMMONS })}
        </Typography>
    )
}