import { LoadingButton } from "@mui/lab";
import { Namespace } from "locales/translations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/hooks";
import MarkCollectionAsDoneDialog from "./MarkCollectionAsDone/MarkCollectionAsDoneDialog";
import { selectCollectionById } from "store/reducers/collections/list";

type ButtonProps = {
    loading: boolean;
    collectionID: string;
}

export default function MarkCollectionAsDoneButton({ loading, collectionID }: ButtonProps) {

    const { t } = useTranslation([Namespace.ACTIONS]);

    const [dialogOpen, toggleDialog] = useState(false);

    const processed = useAppSelector(state => selectCollectionById(state, collectionID)?.processed);

    return (
        <>
            <LoadingButton
                color="white"
                loading={loading}
                onClick={() => toggleDialog(true)}
            >
                <span>{t(processed ? "undo" : "done", { ns: Namespace.ACTIONS })}</span>
            </LoadingButton>

            <MarkCollectionAsDoneDialog
                collectionID={collectionID}
                open={dialogOpen}
                onClose={() => toggleDialog(false)}
                />
        </>
    )
}