/** Format used to communicate datetimes with the API. */
export const API_TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm:ss";

/** Unix string to format dates. */
export const UNIX_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const ACCEPTED_EXCEL_DATES_FORMATS = [
    API_TIMESTAMP_FORMAT,
    "DD/MM/YYYY HH:mm:ss",
]

/** Format used to indicate a day, regardless of the time. */
export const DATE_FORMAT = "YYYY-MM-DD";

// HOTFIX: dates when the polygons started being saved on original image dimensions instead of resized ones
export const POLYGONS_DIMENSIONS_UPDATE = "2022-06-07 10:55:00";