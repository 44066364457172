import React from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import DialogHeader from './DialogHeader';
import AddressDataGrid from './AddressDataGrid';
import { MissionSpreadsheetActions } from 'store/reducers/missions/addresses/spreadsheet';
import SpreadSheetDialogHeader from './SpreadsheetDialogHeader';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * A dialog component for importing spreadsheet data for missions.
 * Allows users to upload, preview, and add address data to missions.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const SpreadSheetDialog: React.FC = () => {
    const dispatch = useAppDispatch();
    const isAnalyzedDataDialogOpen = useAppSelector(state => state.missions.spreadSheetAddress.spreadsheetDialogOpen);

    const handleClose = () => {
        dispatch(MissionSpreadsheetActions.closeSpreadsheetDialog());
        dispatch(MissionSpreadsheetActions.clearSpreadsheetData());
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="xl"
                TransitionComponent={Transition}
                open={isAnalyzedDataDialogOpen}
                onClose={handleClose}
                disableEscapeKeyDown
            >
                <DialogContent>
                    <DialogHeader onClose={handleClose} />

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <SpreadSheetDialogHeader />

                        <AddressDataGrid />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default SpreadSheetDialog;