import MissionAddressesMethods from "controllers/sensitization_addresses";
import { format, Locale } from "locales/translations";
import { MissionSpreadsheetActions } from "store/reducers/missions/addresses/spreadsheet";
import { AppDispatch } from "store/store";
import { convertCoordinate } from "./geo";
import moment from "moment";

export interface SpreadsheetRow {
    [key: string]: any;
}

interface AddressData {
    id: number;
    houseNumber: string;
    street: string;
    city: string;
    postalCode: string;
    refusalReason: { [date: string]: string[] };
    /** Formatted string presenting the reasons for refusal. */
    refusalReasonStr: string;
    lat: number | null;
    lng: number | null;
}

export interface AnalysisResult {
    address: {
        label: string | null;
        city: string | null;
        street: string | null;
        houseNumber: string | null;
    };
    addressComponents: {
        combinedFields: string[];
        fieldMapping: {
            [key: string]: string[];
        };
    };
    refusalReasonFields: string[];
    latitudeField: string | null;
    longitudeField: string | null;
    collectionDate: string | null;
}

type RefusalsByAddress = { [key: string]: AddressData; };

const REFUSAL_REASON_DATE_SEPARATOR = ": ";
const REFUSAL_REASON_SEPARATOR = " | ";
const SEVERAL_REASONS_SEPARATOR = ", ";

/**
 * Mutate the refusalReason object to add a refusalReasonStr property.
 */
function convertRefusalReasonsToStrings(data: RefusalsByAddress): RefusalsByAddress {
    for (let address of Object.keys(data)) {
        data[address].refusalReasonStr = Object.entries(data[address].refusalReason)
            .map(([date, reasons]) => date + REFUSAL_REASON_DATE_SEPARATOR + reasons.join(SEVERAL_REASONS_SEPARATOR))
            .join(REFUSAL_REASON_SEPARATOR);
    }

    return data;
}

/**
 * Converts a string of refusal reasons into an object with dates as keys and arrays of reasons as values.
 *
 * @param str - The string containing refusal reasons, separated by specific separators.
 * @returns An object where the keys are dates (or "N/A" if no date is provided) and the values are arrays of refusal reasons.
 */
export function convertStringRefusalReasons(str: string): AddressData["refusalReason"] {
    const refusalReasons: AddressData["refusalReason"] = {};
    const refusalReasonsArray = str.split(REFUSAL_REASON_SEPARATOR);
    for (let refusal of refusalReasonsArray) {
        const dateAndReason = refusal.split(REFUSAL_REASON_DATE_SEPARATOR);
        let date = "N/A";
        let reasons = dateAndReason[0];
        if (dateAndReason.length === 2) {
            date = dateAndReason[0];
            reasons = dateAndReason[1];
        }
        refusalReasons[date] = reasons.split(SEVERAL_REASONS_SEPARATOR);
    }

    return refusalReasons;
}

/**
 * Process spreadsheet data after it's been parsed
 * @param {SpreadsheetRow[]} data - The parsed spreadsheet data
 * @param {Function} dispatch - The Redux dispatch function
 * @param {string} partnerID - The partner ID
 */
export const handleDataParsed = async (data: SpreadsheetRow[], dispatch: AppDispatch, partnerID: string, locale: Locale) => {
    const { headers, firstRow } = extractHeadersAndFirstRow(data);
    const result = await dispatch(MissionAddressesMethods.analyzeSpreadsheetHeaders(partnerID, headers, firstRow));

    if (result?.address) {
        const { houseNumber: houseNumberField, street: streetField, label: labelField, city: cityField } = result.address;

        // Create a map to hold aggregated addresses.
        const addressMap: { [key: string]: AddressData } = {};

        data.forEach((row: SpreadsheetRow) => {
            const houseNumber = houseNumberField
                ? row[houseNumberField]
                : '';

            const street = streetField
                ? row[streetField]
                : labelField
                    ? row[labelField]
                    : '';

            const city = cityField
                ? row[cityField]
                : '';

            // Create an address key by combining key address components.
            const addressKey = `${houseNumber}${street}`.replace(/\s+/g, '');

            // Prepare the refusal reason text for this row.
            const collectionDate = result.collectionDate
                ? row[result.collectionDate]
                : '';
            // Parsing the date string properly before formatting    
            const momentDate = moment(collectionDate, [
                // European formats
                'DD/MM/YYYY HH:mm:ss',
                'DD/MM/YYYY HH:mm:ss A',
                'DD/MM/YYYY',

                // U.S. formats
                'MM/DD/YYYY HH:mm:ss',
                'MM/DD/YYYY HH:mm:ss A',
                'MM/DD/YYYY',

                // ISO formats
                moment.ISO_8601,
                'YYYY-MM-DDTHH:mm:ssZ',

                // Shorthand and two-digit year formats
                'M/D/YY',
                'M/D/YYYY',

                // Formats with full or abbreviated month names
                'MMMM D, YYYY',
                'MMM D, YYYY',

                // Other common numeric formats
                'YYYY/MM/DD',
                'YYYY.MM.DD',
            ]);
            const formattedCollectionDate = momentDate.isValid()
                ? format(momentDate, 'date_short', locale)
                : '';

            let refusalText = '';
            if (result.refusalReasonFields?.length) {
                refusalText = result.refusalReasonFields
                    .map((field: string) => row[field])
                    .filter(Boolean)
                    .join(', ');
            }

            const lat = result.latitudeField ? convertCoordinate(row[result.latitudeField]) : null;
            const lng = result.longitudeField ? convertCoordinate(row[result.longitudeField]) : null;

            if (!addressMap[addressKey]) {
                // Create a new entry if the address doesn't exist
                addressMap[addressKey] = {
                    id: Object.keys(addressMap).length + 1,
                    houseNumber,
                    street,
                    city,
                    postalCode: '', // No postal code in the new type definition
                    refusalReason: {},
                    refusalReasonStr: '',
                    lat,
                    lng,
                };
            }

            // Initialize the date key in refusalReason if it doesn't exist
            if (!addressMap[addressKey].refusalReason[formattedCollectionDate]) {
                addressMap[addressKey].refusalReason[formattedCollectionDate] = [];
            }

            // Push a new refusal reason for the same date
            if (refusalText) {
                addressMap[addressKey].refusalReason[formattedCollectionDate].push(refusalText);
            }
        });

        // convert refusalReason object to string
        convertRefusalReasonsToStrings(addressMap);

        // Convert the addressMap into an array to use as grid data.
        const gridData = Object.values(addressMap);

        // Store the rows in Redux.
        dispatch(MissionSpreadsheetActions.setSpreadsheetPreviewData(gridData));

        // Select all rows by default.
        dispatch(MissionSpreadsheetActions.selectAllSpreadsheetRows());
    }
};

export enum SpreadsheetValidationErrorKey {
    FILE_SIZE_EXCEEDED = 'spreadsheet.error.fileSizeExceeded',
    EMPTY_SPREADSHEET = 'spreadsheet.error.emptySpreadsheet',
    TOO_MANY_ROWS = 'spreadsheet.error.tooManyRows'
}

export const validateSpreadsheet = (data: any[], file: File): SpreadsheetValidationErrorKey | null => {
    if (file.size > 2 * 1024 * 1024) { // 2MB size limit
        return SpreadsheetValidationErrorKey.FILE_SIZE_EXCEEDED;
    }
    if (data.length === 0) {
        return SpreadsheetValidationErrorKey.EMPTY_SPREADSHEET;
    }
    if (data.length > 1000) { // Row limit
        return SpreadsheetValidationErrorKey.TOO_MANY_ROWS;
    }
    return null;
};

export const extractHeadersAndFirstRow = (data: any[]) => {
    if (data.length === 0) return { headers: [], firstRow: [] };

    const headers = Object.keys(data[0]);
    const firstRow = [data[0], data[1]];

    return { headers, firstRow };
};
