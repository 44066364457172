import { useAppSelector } from "hooks/hooks";
import { SensitizationAddresses } from "models/Missions";
import MissionMapMarker from "./MissionMapMarker";
import MissionAddressesMethods from "controllers/sensitization_addresses";

type MissionMarkerProps = {
    address: SensitizationAddresses
    onClick: (addressKey: string) => void;
};

/**
 * Marker for an address added to a mission.
 */
export default function MissionMarker(props: MissionMarkerProps) {
    const { address, onClick } = props;
    const hovered = useAppSelector(state => state.batches.sortingMap.hoveredAddressKey === address.addressKey);
    
    // Check if lat or lng is null or undefined. If so, skip rendering the marker.
    if (address.lat == null || address.lng == null) {
        return null;
    }


    return (
        <MissionMapMarker
            addressKey={address.addressKey}
            lat={address.lat}
            lng={address.lng}
            highlighted={hovered}
            status={MissionAddressesMethods.getAddressStatus(true, false, address.visited, address.report?.inhabitantsSpoken)}
            onClick={() => onClick(address.addressKey)}
        />
    );
}
