import { Box, Typography } from "@mui/material";
import ActionButton from "components/_include/Buttons/ActionButton";
import MissionAddressesMethods from "controllers/sensitization_addresses";
import { formatAddress } from "helpers/geo";
import { Namespace } from "locales/translations";
import { SensitizationAddresses } from "models/Missions";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { usePartnerID } from 'hooks/partners';
import { MissionSpreadsheetActions } from "store/reducers/missions/addresses/spreadsheet";

export default function SpreadSheetDialogHeader() {
    const { t } = useTranslation([Namespace.MISSIONS]);

    const dispatch = useAppDispatch();
    const partnerID = usePartnerID();
    const missionID = useAppSelector(state => state.missions.selectedMission.data?.ID);
    const selectedRows = useAppSelector(state => state.missions.spreadSheetAddress.selectedSpreadsheetRows);

    const loading = useAppSelector(state => state.missions.missionAddresses.loading);

    const handleAddAddress = useCallback(async () => {
        if (!partnerID || !missionID) {
            console.error('Mission ID or Partner ID is missing.');
            return;
        }

        // Prepare addresses without IDs (IDs will be generated in the controller)
        const addressesToAdd: Omit<SensitizationAddresses, 'ID'>[] = selectedRows.map((rowData) => {

            const addressKey = formatAddress({
                houseNumber: rowData.houseNumber || '',
                street: rowData.street || '',
                city: rowData.city || '',
                postalCode: rowData.postalCode || ''
            });

            return {
                address: {
                    houseNumber: rowData.houseNumber || '',
                    street: rowData.street || '',
                    city: rowData.city || '',
                    postalCode: rowData.postalCode || '',
                },
                addressKey,
                lat: rowData.lat,
                lng: rowData.lng,
                visited: false,
                refusalReason: rowData.refusalReason,
                fromExternalSource: true
            };
        });

        await dispatch(
            MissionAddressesMethods.addAddressesToMission(partnerID, missionID, addressesToAdd)
        );

        // Close the dialog after adding addresses
        dispatch(MissionSpreadsheetActions.closeSpreadsheetDialog());
    }, [partnerID, missionID, selectedRows, dispatch]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingBottom: '20px'
        }}>
            <Box>
                <Typography variant="h3" gutterBottom>
                    {t('add_mission_addresses', { ns: Namespace.MISSIONS })}
                </Typography>

                <Typography variant="subtitle1" gutterBottom fontStyle="italic" color="rgba(0, 0, 0, 0.54)">
                    {t('edit_uploaded_addresses', { ns: Namespace.MISSIONS })}
                </Typography>
            </Box>

            <ActionButton
                size="normal"
                color="secondary"
                loading={loading}
                disabled={selectedRows.length === 0}
                onClick={handleAddAddress}
            >
                {selectedRows.length === 0
                    ? t('select_an_address', { ns: Namespace.MISSIONS })
                    : t('add_addresses', {
                        ns: Namespace.MISSIONS,
                        count: selectedRows.length,
                    })}
            </ActionButton>
        </Box>
    );
}