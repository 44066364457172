import { PartnerPermissions, UserClaims } from "models/User";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Locale } from "locales/translations";
import { User as FirebaseUser } from "firebase/auth";

type UserContext = Omit<UserClaims, "role" | "partnerPermissions"> & {
  email: string | null;
  partnerPermissions: PartnerPermissions | null;
  viewAsPartner: boolean;
  loading: boolean;
  creating: boolean;
  error: string | null;
};

const initialState: UserContext = {
  email: null,
  partnerPermissions: null,
  viewAsPartner: false,
  locale: Locale.FRENCH,
  loading: false,
  creating: false,
  error: null,
  isFichaAdmin: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    startCreating: (state) => {
      state.creating = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    stopCreating: (state) => {
      state.creating = false;
    },
    setError: (state, { payload: error }: PayloadAction<string>) => {
      state.loading = false;
      state.creating = false;
      state.error = error;
    },
    resetError: (state) => {
      state.error = null;
    },
    setUser: (
      state,
      { payload: data }: PayloadAction<UserClaims & Pick<FirebaseUser, "email">>
    ) => {
      state.loading = false;
      state.error = null;

      // Set Ficha admin status based on role or existing flag
      state.isFichaAdmin = data.isFichaAdmin;
      state.partnerPermissions = data.partnerPermissions || null;
      state.locale = data.locale;
      state.email = data.email;
    },
    togglePartnerView: (
      state,
      { payload: viewAsPartner }: PayloadAction<boolean>
    ) => {
      state.viewAsPartner = viewAsPartner;
      localStorage.setItem("view-as-partner", viewAsPartner ? "1" : "");
    },
  },
});

export const UserActions = userSlice.actions;

const UserReducer = userSlice.reducer;

export default UserReducer;
