import { GridColDef, GridActionsCellItem, GridRowId, GridRenderCellParams } from '@mui/x-data-grid';
import CollaboratorsController from 'controllers/collaborators';
import { Namespace } from 'locales/translations';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useCanManageCollaborators } from 'hooks/hooks';
import { usePartnerID } from 'hooks/partners';
import { selectAllCollaborators } from 'store/reducers/collaborators/list';
import TrashIcon from '@mui/icons-material/DeleteForeverRounded';
import RemoveCollaboratorDialog from './RemoveCollaboratorDialog';
import { SelectedCollaboratorActions } from 'store/reducers/collaborators/selected';
import { FichaDataGrid } from 'components/_include/DataGrid/FichaDataGrid';
import { Edit } from '@mui/icons-material';
import Collaborator from 'models/Collaborator';
import { EditCollaboratorDialog } from './AddCollaboratorDialog/EditCollaboratorsDialog';
import { Stack } from '@mui/material';
import CollaboratorPermissionChip from './CollaboratorPermissionChip';
import moment from 'moment';

const NB_VISIBLE_PERMISSIONS = 3;

/** Data table to display the list of collaborators. */
export default function CollaboratorsTable() {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.COMMONS, Namespace.CONTACTS, Namespace.DATES]);

    const partnerID = usePartnerID();
    const canDelete = useCanManageCollaborators(partnerID);

    const collaborators = useAppSelector(selectAllCollaborators);
    const dispatch = useAppDispatch();

    // State for edit dialog
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [collaboratorToEdit, setCollaboratorToEdit] = useState<Collaborator | null>(null);

    /** Toggle "Remove collaborator" dialog. */
    const selectCollaboratorToDelete = useCallback((collaboratorID: GridRowId) => {
        dispatch(SelectedCollaboratorActions.setSelected(collaborators.find(c => c.ID === collaboratorID) ?? null));
    }, [dispatch, collaborators]);

    /** Open edit dialog for a collaborator */
    const selectCollaboratorToEdit = useCallback((collaboratorID: GridRowId) => {
        const selectedCollaborator = collaborators.find(c => c.ID === collaboratorID) ?? null;
        if (selectedCollaborator) {
            setCollaboratorToEdit(selectedCollaborator);
            setEditDialogOpen(true);
        }
    }, [collaborators]);

    /** Close edit dialog */
    const handleCloseEditDialog = useCallback(() => {
        setEditDialogOpen(false);
        setCollaboratorToEdit(null);
    }, []);

    /** Render cell for permissions with chips */
    const renderPermissionCell = useCallback((params: GridRenderCellParams<any, string[]>) => {
        const permissions = params.value || [];
        // If more than 3, show first two; otherwise show all.
        const hiddenPermissionsChips = permissions.length > NB_VISIBLE_PERMISSIONS;
        const visibleCount = hiddenPermissionsChips ? NB_VISIBLE_PERMISSIONS - 1 : permissions.length;

        return (
            <Stack direction="column" spacing={0.75} sx={{ py: 1 }}>
                {permissions.slice(0, visibleCount).map((permission) => (
                    <CollaboratorPermissionChip
                        key={permission}
                        label={permission}
                        />
                ))}
                {hiddenPermissionsChips && (
                    <CollaboratorPermissionChip
                        label={t("permissions.plus_more_count", { 
                            ns: Namespace.CONTACTS, 
                            count: permissions.length - visibleCount,
                        })}
                        title={permissions.slice(visibleCount).join(', ')}
                        />
                )}
            </Stack>
        );
    }, [t]);

    /** List of columns and their respective props for the collaborators table. */
    const columns: GridColDef[] = useMemo(() => ([
        {
            field: 'fullName',
            headerName: t('full name', { ns: Namespace.CONTACTS }),
            minWidth: 160,
            disableColumnMenu: true,
        },
        {
            field: 'email',
            headerName: t('email address', { ns: Namespace.CONTACTS }),
            minWidth: 220,
            disableColumnMenu: true,
        },
        {
            field: 'permission',
            headerName: t('permissions.title', { ns: Namespace.CONTACTS }),
            minWidth: 320,
            flex: 1,
            disableColumnMenu: true,
            renderCell: renderPermissionCell
        },
        {
            field: 'addedAt',
            headerName: t('added at', { ns: Namespace.CONTACTS }),
            minWidth: 140,
            disableColumnMenu: true,
            type: 'dateTime',
            valueFormatter: (value) => t("day_month_year", { ns: Namespace.DATES, date: value }),
        },
        {
            field: 'actions',
            headerName: t('actions', { ns: Namespace.COMMONS }),
            minWidth: 120,
            disableColumnMenu: true,
            type: 'actions',
            getActions: ({ id }) => {
                let actions = [];
                if (canDelete) {
                    actions.push(
                        <GridActionsCellItem
                            key="edit"
                            color='secondary'
                            icon={<Edit />}
                            label={t("edit", { ns: Namespace.ACTIONS })}
                            onClick={() => selectCollaboratorToEdit(id)}
                        />
                    );
                    actions.push(
                        <GridActionsCellItem
                            key="delete"
                            color='secondary'
                            icon={<TrashIcon />}
                            label={t("delete", { ns: Namespace.ACTIONS })}
                            onClick={() => selectCollaboratorToDelete(id)}
                        />
                    );
                }
                return actions;
            }
        },
    ]), [t, canDelete, selectCollaboratorToDelete, selectCollaboratorToEdit, renderPermissionCell]);

    /** List of the rows corresponding to each collaborator */
    const rows = useMemo(() => {
        return collaborators.map(collaborator => ({
            id: collaborator.ID,
            fullName: `${collaborator.firstName} ${collaborator.lastName}`,
            email: collaborator.email,
            permission: collaborator.permissions.map(permission => t(`permissions.${permission}`, { ns: Namespace.CONTACTS })),
            addedAt: moment(collaborator.addedAt).toDate(),
        }));
    }, [t, collaborators]);

    useEffect(() => {
        if (partnerID) dispatch(CollaboratorsController.list(partnerID));
    }, [dispatch, partnerID]);

    return (
        <>
            <FichaDataGrid 
                getRowHeight={() => 'auto'} 
                rows={rows} 
                columns={columns} 
                />

            <RemoveCollaboratorDialog />
            
            {collaboratorToEdit && (
                <EditCollaboratorDialog
                    open={editDialogOpen}
                    onClose={handleCloseEditDialog}
                    collaborator={collaboratorToEdit}
                />
            )}
        </>
    )
}