import { Paper, Slide } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { usePartnerID } from 'hooks/partners';
import PlaceBatchesList from './BatchesList/PlaceBatchesList';
import BatchDetailsBody from 'components/Map/SortingMap/DetailsDrawer/BatchDetails/BatchDetailsBody';
import SavedAddressesController from 'controllers/saved_addresses';
import { SelectedSavedAddressActions } from 'store/reducers/saved_addresses/selected';

type DetailsDrawerProps = {

}

function BatchesByPlaceDrawer(props: DetailsDrawerProps) {
    const partnerID = usePartnerID();

    const place = useAppSelector(state => state.batches.places.selectedPlace);
    const batchID = useAppSelector(state => state.batches.list.selectedBatch?.ID);
    const batchAddressID = useAppSelector(state => state.batches.list.selectedBatch?.addressID);

    const dispatch = useAppDispatch();

    /** Load batch's address */
    useEffect(() => {
        if (partnerID && batchAddressID) {
            dispatch(SavedAddressesController.retrieve(partnerID, batchAddressID));
        }

        /** Unselect the place when the batch is not selected anymore */
        const resetPlace = () => { SelectedSavedAddressActions.setData(null) };
        return resetPlace;
    }, [partnerID, batchAddressID]);

    const placeAddress = useAppSelector(state => { // format batches address
        const place = state.savedAddresses.selected.data;
        if (!place || place.ID !== batchAddressID) return "";
        return place.title;
    });

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (place || batchID) {
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    }, [place, batchID]);

    return (
        <Slide
            direction='left'
            in={open}
            mountOnEnter
            unmountOnExit
        >
            <Paper
                sx={{
                    width: theme => theme.spacing(45),
                    borderRadius: 2,
                    overflowY: "scroll",
                    pointerEvents: "all",
                    position: "absolute",
                    top: theme => theme.spacing(0),
                    right: theme => theme.spacing(0),
                    bottom: theme => theme.spacing(0),
                }}
            >
                {batchID ? (
                    <BatchDetailsBody
                        placeAddress={placeAddress}
                    />
                ) : (place && (
                    <PlaceBatchesList
                        batchesByPlace={place}
                    />
                ))}
            </Paper>
        </Slide >
    );
}

export default BatchesByPlaceDrawer;