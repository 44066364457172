import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "hooks/hooks";
import { selectRouteById } from "store/reducers/routes/list";
import CardListItem from "../CardListItem";

type RouteItemProps = {
    routeID: EntityId;
    onEditClicked: (routeID: string) => void;
    onDeleteClicked: (routeID: string) => void;
}

export default function RouteItem(props: RouteItemProps) {
    const { routeID, onEditClicked, onDeleteClicked } = props;

    const route = useAppSelector(state => selectRouteById(state, routeID)!);

    return (
        <CardListItem
            primary={route.label}
            onEditClicked={() => onEditClicked(route.ID)}
            onDeleteClicked={() => onDeleteClicked(route.ID)}
            />
    );
}