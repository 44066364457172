import { CardMedia } from "@mui/material";
import SectionLoader from "components/_include/SectionLoader";
import { getPictureURL, getVideoURL, StorageType } from "helpers/storage";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "hooks/hooks";

type BatchImageOrVideoProps = {
    handleImageRef: (node: HTMLImageElement) => void;
    onImageLoaded: (image: HTMLImageElement) => void;
}

export default function BatchImageOrVideo({ handleImageRef, onImageLoaded }: BatchImageOrVideoProps) {
    /**
     * Batch image
     */
    const selectedBatch = useAppSelector(state => state.batches.list.selectedBatch)!;
    const imageURL = selectedBatch?.imageURL;
    const imageZoom = useAppSelector(state => state.batches.list.selectedBatch?.imageZoom);
    const [pictureURL, setPictureURL] = useState<string | null>(null);    
    /**
     * @deprecated Batch video
     */
    const batchVideoURL = useAppSelector(state => state.batches.list.selectedBatch?.videoURL);
    const [videoURL, setVideoURL] = useState<string | null>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    /**
     * Get batch's image signed URL
     */
    useEffect(() => {
        setPictureURL(null);

        if (imageURL) {
            getPictureURL(imageURL, StorageType.BATCHES)
                .then((picURL) => {
                    setPictureURL(picURL);
                });
        }
    }, [imageURL]);

    /**
     * @deprecated Get batch's video signed URL
     */
    useEffect(() => {
        setVideoURL(null);

        if (batchVideoURL) {
            getVideoURL(batchVideoURL)
                .then((vURL) => {
                    setVideoURL(vURL);
                });
        }
    }, [batchVideoURL]);

    /**
     * @deprecated Load the video when selected changed
     */
    useEffect(() => {
        videoRef.current?.load();
    }, [videoURL]);

    return (
        <>
            <CardMedia
                ref={handleImageRef}
                component="img"
                height="100%"
                image={pictureURL || undefined}
                onLoad={(e) => {
                    onImageLoaded(e.target as HTMLImageElement);
                }}
                sx={{
                    objectFit: "contain",
                    backgroundColor: "#222222",
                    transformOrigin: `0 0`,
                    transform: `translate(${imageZoom?.transform.x}px, ${imageZoom?.transform.y}px) scale(${imageZoom?.zoom})`,
                }}
            />
            <video
                ref={videoRef}
                width="100%"
                height="100%"
                style={{
                    position: "absolute",
                    top: 0,
                }}
                autoPlay
                loop
            >
                {videoURL && (
                    <source
                        src={videoURL}
                        type="video/mp4"
                    />
                )}
            </video>
            {!pictureURL && !videoURL && (
                <SectionLoader />
            )}
        </>
    )
}