import { Box, Typography } from '@mui/material';
import { getGradient } from 'helpers/draw';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { OnboardingCardType } from './OnboardingSetupCard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RouteIcon from 'components/_include/Icons/RouteIcon';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useAppSelector } from 'hooks/hooks';

type OnboardingCardHeaderProps = {
    type: OnboardingCardType;
    completed: boolean;
}

function OnboardingCardHeader(props: OnboardingCardHeaderProps) {
    const { type, completed } = props;

    const { t } = useTranslation([Namespace.FORMS]);

    const Icon = type === "trucks" ? LocalShippingIcon : type === "routes" ? RouteIcon : CalendarMonthIcon;

    const wasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);

    return (
        <Box
            mx={2}
            mt={-4}
            display="flex"
            flexDirection="row"
            alignItems="center"
            minHeight={120}
            borderRadius={2}
            px={2}
            py={3.5}
            boxShadow={4}
            sx={{
                background: theme => {
                    const color1 = completed ? "#71CC88" : theme.palette.info.main;
                    const color2 = completed ? "#339955" : theme.palette.secondary.main;
                    return getGradient("vertical", color1, color2);
                },
            }}
            >
            <Icon 
                sx={{
                    fontSize: theme => theme.spacing(6),
                    color: "#ffffff",
                    mr: 3,
                }}
            />
            <Typography variant="body1" color="#ffffff">
                {t(`onboarding.instructions.${type}`, { 
                    ns: Namespace.FORMS, 
                    context: completed ? "completed" : "",
                    type: wasteStream,
                })}
            </Typography>
        </Box>
    );
}

export default OnboardingCardHeader;