import { TOPICS } from "constants/address_report";
import { SensitizationAddresses, ReportStats, ResponsivenessLevel } from "models/Missions";

export const aggregateAwarenessCampaignStats = (addresses: SensitizationAddresses[]): ReportStats => {
    const aggregatedStats: ReportStats = {
        totalAddressesVisited: 0,
        inhabitantsSpokenCount: 0,
        inhabitantsReceptiveCounts: {
            [ResponsivenessLevel.Responsive]: 0,
            [ResponsivenessLevel.Neutral]: 0,
            [ResponsivenessLevel.NotResponsive]: 0,
        },
        selectedTopicsCounts: {},
        communicationMethodsCounts: {},
    };

    TOPICS.forEach((topic) => {
        aggregatedStats.selectedTopicsCounts![topic] = 0;
    });


    addresses.forEach((address) => {
        if (address.visited) {
            aggregatedStats.totalAddressesVisited += 1;

            const report = address.report;

            if (report) {
                // Sum inhabitantsSpokenCount
                if (report.inhabitantsSpoken) {
                    aggregatedStats.inhabitantsSpokenCount = (aggregatedStats.inhabitantsSpokenCount ?? 0) + 1;
                }

                // Sum inhabitantsReceptiveCounts
                if (report.inhabitantsReceptive !== null && report.inhabitantsReceptive !== undefined) {
                    const mood = report.inhabitantsReceptive;

                    // Use a stricter check for null and undefined
                    if (aggregatedStats.inhabitantsReceptiveCounts == null) {
                        aggregatedStats.inhabitantsReceptiveCounts = {
                            [ResponsivenessLevel.Responsive]: 0,
                            [ResponsivenessLevel.Neutral]: 0,
                            [ResponsivenessLevel.NotResponsive]: 0,
                        };
                    }

                    // Ensure the mood key is initialized
                    if (aggregatedStats.inhabitantsReceptiveCounts[mood] == null) {
                        aggregatedStats.inhabitantsReceptiveCounts[mood] = 0;
                    }

                    aggregatedStats.inhabitantsReceptiveCounts[mood] += 1;
                }

                // Sum selectedTopicsCounts
                if (report.selectedTopics && report.selectedTopics.length > 0) {
                    if (aggregatedStats.selectedTopicsCounts == null) {
                        aggregatedStats.selectedTopicsCounts = {};
                    }

                    report.selectedTopics.forEach((topic) => {
                        aggregatedStats.selectedTopicsCounts![topic] = (aggregatedStats.selectedTopicsCounts![topic] ?? 0) + 1;
                    });
                }

                // Sum communicationMethodsCounts
                if (report.communicationMethods && report.communicationMethods.length > 0) {
                    if (aggregatedStats.communicationMethodsCounts == null) {
                        aggregatedStats.communicationMethodsCounts = {};
                    }

                    report.communicationMethods.forEach((method) => {
                        aggregatedStats.communicationMethodsCounts![method] = (aggregatedStats.communicationMethodsCounts![method] ?? 0) + 1;
                    });
                }
            }
        }
    });

    return aggregatedStats;
};