/**
 * Sum 2 objects into a new object whose values are the sum of each objects values for each key, 
 * considering value is 0 if a key doesn't exist on one of the objects.
 * ---
 * @example
 * const obj1 = { a: 1, b: 2, c: 3 };
 * const obj2 = { b: 4, c: 5, d: 6 };
 * const sumObj = sumObjects(obj1, obj2);
 * console.log(sumObj); // Output: { a: 1, b: 6, c: 8, d: 6 }
 */
export function sumObjects<T extends { [key: string]: number }>(obj1: T, obj2: T): T {
    let result: { [key: string]: number } = { ...obj1 };
  
    Object.entries(obj2).forEach(([key, count]) => { // sum up the count for each type of bin
        result[key] = (result[key] || 0) + count;
    });
  
    return result as T;
}