import { Card, CardActions, CardContent, } from '@mui/material';
import { useIsAdmin } from 'hooks/hooks';
import OnboardingCardHeader from './OnboardingCardHeader';
import AddRouteButton from './Routes/AddRouteButton';
import AddScheduleButton from './Schedule/AddScheduleButton';
import AddTruckButton from './Trucks/AddTruckButton';

export type OnboardingCardType = "trucks" | "routes" | "schedule";

type OnboardingSetupCardProps = {
    type: OnboardingCardType;
    completed: boolean;
    children: JSX.Element | JSX.Element[];
}

const MARGIN_TOP = 4;

function OnboardingSetupCard(props: OnboardingSetupCardProps) {
    const { type, completed, children } = props;

    const isAdmin = useIsAdmin();

    return (
        <Card
            sx={{
                maxWidth: 340,
                width: "100%",
                height: theme => `calc(100% - ${theme.spacing(MARGIN_TOP)})`,
                overflow: "visible",
                mt: MARGIN_TOP,
                flexGrow: 1,
            }}
        >
            <OnboardingCardHeader
                type={type}
                completed={completed}
            />
            <CardContent
                sx={{
                    maxHeight: theme => `calc(100% - ${theme.spacing(MARGIN_TOP + 15)})`, // Card height - space at the top
                    overflow: "scroll",
                }}
                >
                {children}
            </CardContent>
            <CardActions
                sx={{
                    px: 2,
                }}
                >
                {type === "trucks" && isAdmin && (
                    <AddTruckButton completed={completed} />
                )}
                {type === "routes" && (
                    <AddRouteButton completed={completed} />
                )}
                {type === "schedule" && (
                    <AddScheduleButton completed={completed} />
                )}
            </CardActions>
        </Card>
    );
}

export default OnboardingSetupCard;