import React from "react";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import DialogHeader from "../SpreadSheetDialog/DialogHeader";
import { FichaDataGrid } from "components/_include/DataGrid/FichaDataGrid";
import {
    MissionAddressesActions,
    selectAllReportsData,
} from "store/reducers/missions/addresses/list";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Transition } from "components/_include/Transitions/transition";
import { useReportColumns } from "./ReportColumns";
import { GridContainer } from "./ReportGridStyles";
import { useReportData } from "./useReportData";

/**
 * A dialog component for viewing and downloading campaign reports.
 * Displays all visited addresses with their report data.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const DownloadCampaignReportDialog: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation([Namespace.MISSIONS]);
    const isReportsDialogOpen = useAppSelector(
        (state) => state.missions.missionAddresses.reportsDialogOpen
    );
    const reportsData = useAppSelector(selectAllReportsData);
    const loading = useAppSelector(
        (state) => state.missions.missionAddresses.loading
    );

    const handleClose = () => {
        dispatch(MissionAddressesActions.closeReportsDialog());
    };

    const translatedRows = useReportData(reportsData);
    const reportColumns = useReportColumns();

    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            TransitionComponent={Transition}
            open={isReportsDialogOpen}
            onClose={handleClose}
            disableEscapeKeyDown
        >
            <DialogContent>
                <DialogHeader onClose={handleClose} />

                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Box sx={{ width: "100%", mb: 2 }}>
                        <Typography variant="h3">
                            {t("total_visited_address", {
                                ns: Namespace.MISSIONS,
                                count: reportsData.length,
                            })}
                        </Typography>
                    </Box>

                    <GridContainer>
                        <FichaDataGrid
                            rows={translatedRows}
                            columns={reportColumns}
                            getRowId={(row) => row.ID}
                            loading={loading}
                            rowHeight={100}
                            slotProps={{
                                loadingOverlay: {
                                    variant: "skeleton",
                                    noRowsVariant: "skeleton",
                                },
                                toolbar: {
                                    csvOptions: { allColumns: true },
                                },
                            }}
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}
                            density="comfortable"
                        />
                    </GridContainer>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DownloadCampaignReportDialog;
