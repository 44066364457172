import Collaborator, { APICollaborator, CollaboratorDbData, NewCollaborator } from "models/Collaborator";
import { AppDispatch } from "store/store";
import { CollaboratorsActions } from "store/reducers/collaborators/list";
import { formatQuery, getCollectionRef, getDocumentReference } from "helpers/db";
import { DbCollection } from "constants/db";
import { showError, showTranslatedMessage } from "store/reducers/snacks";
import { QueryDocumentSnapshot, DocumentData, DocumentSnapshot, getDocs, getDoc } from "firebase/firestore";
import { SelectedCollaboratorActions } from "store/reducers/collaborators/selected";
import { fetchAPI } from "./actions";
import urls from "constants/urls";
import { stringToTimestamp } from "helpers/dates";

function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): Collaborator;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): Collaborator | null;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as CollaboratorDbData;

    const collaborator: Collaborator = {
        ...data,
        ID: dbDoc.id,
        addedAt: data.addedAt.toMillis(),
    }
    return collaborator;
}

/**
 * List all the collaborators added to a given partner.
 */
const list = (partnerID: string) => async (dispatch: AppDispatch) => {
    dispatch(CollaboratorsActions.startLoadingList());

    const collaboratorPath = [DbCollection.PARTNERS, partnerID, DbCollection.COLLABORATORS];

    const collaboratorRef = getCollectionRef(collaboratorPath);
    const query = formatQuery(collaboratorRef);

    try {
        const collaboratorsSnapshot = await getDocs(query.query);
        const collaborators = collaboratorsSnapshot.docs.map(doc => fromDbDoc(doc));

        dispatch(CollaboratorsActions.setList(collaborators));
        return collaborators;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to load collaborators", error);
        dispatch(showError(error.message));
        dispatch(CollaboratorsActions.setError(error.message));
        return [];
    }
};

/**
 * Update an existing collaborator in Firestore directly and update their Firebase Auth claims.
 * @param partnerID - ID of the partner
 * @param collaboratorID - ID of the collaborator document
 * @param collaboratorData - New data for the collaborator
 */
const update = (partnerID: string, collaboratorID: string, collaboratorData: NewCollaborator) => async (dispatch: AppDispatch) => {
    dispatch(SelectedCollaboratorActions.startLoading());

    try {
        // 1. First update the Firestore document
        const collaboratorRef = getDocumentReference(collaboratorID, DbCollection.COLLABORATORS, `${DbCollection.PARTNERS}/${partnerID}`);

        // 2. Then call the new API endpoint to update the Firebase Auth claims
        if (collaboratorData.permissions) {
            try {
                await fetchAPI(`${urls.API}/partner/${partnerID}/collaborators/${collaboratorID}/update-claims`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userPermissions: collaboratorData.permissions
                    }),
                });
            } catch (claimsError) {
                console.error("Failed to update Firebase Auth claims", claimsError);
            }
        }

        const collaboratorsSnapshot = await getDoc(collaboratorRef);
        const collaborator = fromDbDoc(collaboratorsSnapshot);

        if (collaborator) {
            // Update the Redux state with the updated collaborator
            dispatch(CollaboratorsActions.updateItem({
                ID: collaboratorID,
                data: collaborator,
            }));
        }

        dispatch(SelectedCollaboratorActions.stopLoading());
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "collaborators.update.success",
            context: { email: collaboratorData.email },
        }));

        return true;
    } catch (e) {
        const error = e as Error;
        console.error("Failed to update collaborator", error);
        dispatch(showError(error.message));
        dispatch(SelectedCollaboratorActions.setError(error.message));
        return false;
    }
};
/**
 * Invite a new collaborator to access to a given partner.
 */
const invite = (partnerID: string, collaboratorData: NewCollaborator) => async (dispatch: AppDispatch) => {
    dispatch(SelectedCollaboratorActions.startLoading());

    try {
        const requestBody = {
            firstName: collaboratorData.firstName,
            lastName: collaboratorData.lastName,
            email: collaboratorData.email,
            partnerID,
            partnerPermissions: {
                [partnerID]: collaboratorData.permissions,
            }
        };

        const collaborator: APICollaborator = await fetchAPI(`${urls.API}/partner/${partnerID}/collaborators`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody),
        });

        // add to list
        dispatch(CollaboratorsActions.addOne({
            ...collaboratorData,
            ID: collaborator.ID,
            addedAt: stringToTimestamp(collaborator.addedAt).toMillis(),
        }));

        dispatch(SelectedCollaboratorActions.stopLoading());

        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "collaborators.invite.success",
            context: { email: collaborator.email },
        }));

        return collaborator;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to invite collaborator", error);
        dispatch(showError(error.message));
        dispatch(SelectedCollaboratorActions.setError(error.message));
        return null;
    }
};

/**
 * Remove a given collaborator from accessing a partner's data.
 * Note that it doesn't delete the Firebase user of the collaborator.
 */
const removeFromPartner = (partnerID: string, collaborator: Collaborator) => async (dispatch: AppDispatch) => {
    dispatch(SelectedCollaboratorActions.startLoading());

    try {
        await fetchAPI(
            `${urls.API}/partner/${partnerID}/collaborators/${collaborator.ID}`,
            {
                method: "DELETE",
            }
        );

        // remove from list
        dispatch(CollaboratorsActions.removeOne(collaborator.ID));

        dispatch(SelectedCollaboratorActions.setSelected(null));

        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "collaborators.remove.success",
            context: { email: collaborator.email },
        }));

        return true;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to remove collaborator", error);
        dispatch(showError(error.message));
        dispatch(SelectedCollaboratorActions.setError(error.message));
        return false;
    }
};

const CollaboratorsController = {
    list,
    invite,
    update,
    removeFromPartner,
};

export default CollaboratorsController;