import { Box, ButtonBase, Typography } from "@mui/material";
import { hexToRgba } from "helpers/draw";
import { Namespace } from "locales/translations";
import { upperFirst } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { ShortcutKey, ValidationShortcutsActions } from "store/reducers/preferences/validation_shortcuts"
import CustomizeShortcutTooltip from "./CustomizeShortcutTooltip";

type ButtonProps = {
    shortcut: ShortcutKey;
}

/**
 * A button displaying the current key associated to a shortcut.
 * Users can click on it to edit the key.
 */
export default function CustomizeShortcutButton({ shortcut }: ButtonProps) {
    const { t, i18n } = useTranslation([Namespace.PREFERENCES]);

    const key = useAppSelector(state => state.preferences.validation[shortcut]);

    const dispatch = useAppDispatch();

    const translationKey = `keys.${key}`;

    const label = i18n.exists(translationKey, { ns: Namespace.PREFERENCES }) ? t(translationKey, { ns: Namespace.PREFERENCES }) : upperFirst(key || "");

    const [recording, setRecording] = useState(false);

    useEffect(() => {
        if (recording) { // start recording keyboard events
            window.addEventListener("keydown", associateNewKey);
            return () => { window.removeEventListener("keydown", associateNewKey); }
        }
        else { // stop recording keyboard events
            window.removeEventListener("keydown", associateNewKey); 
            dispatch(ValidationShortcutsActions.setError(null));
        }
    }, [recording]);

    /** Handle keyboard event when recording */
    const associateNewKey = (e: KeyboardEvent) => {
        dispatch(ValidationShortcutsActions.updateOne({
            shortcut,
            key: e.key,
        }));
    }

    /** Close tooltip when key changes. */
    useEffect(() => {
        if (recording) setRecording(false);
    }, [key]);

    return (
        <CustomizeShortcutTooltip
            open={recording}
            onClose={() => setRecording(false)}
        >
            <Box
                display="inline-flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
            >
                <ButtonBase
                    onClick={() => setRecording(true)}
                    sx={{
                        width: theme => theme.spacing(8),
                        height: theme => theme.spacing(4),
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 2,
                        backgroundColor: theme => hexToRgba(theme.palette.primary.main, 0.1),
                        "&:hover": {
                            backgroundColor: theme => hexToRgba(theme.palette.primary.main, 0.15),
                        }
                    }}
                >
                    <Box
                        width={theme => theme.spacing(4.5)}
                        height={theme => theme.spacing(2.5)}
                        bgcolor={theme => theme.palette.primary.main}
                        borderRadius={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            component="span"
                            textAlign="center"
                            color="#fff"
                            fontSize={label.length > 3 ? 10 : 16}
                        >
                            {label}
                        </Typography>
                    </Box>
                </ButtonBase>
            </Box>
        </CustomizeShortcutTooltip>
    )
}