import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import CollectionsController from 'controllers/collections';
import ActionButton from 'components/_include/Buttons/ActionButton';
import { Namespace } from 'locales/translations';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';

export default function ClearAllButton() {

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.PARTNERS]);

    const loading = useAppSelector(state => state.collections.list.loading);

    const dispatch = useAppDispatch();

    const partner = useAppSelector(state => state.partners.selected.data);

    const handleClearClicked = () => {
        if (partner) dispatch(CollectionsController.markAllAsHousehold(partner.ID, partner.name));
        toggleConfirmDialog(false); // close dialog
    }

    const [showConfirmDialog, toggleConfirmDialog] = useState(false);

    return (
        <>
            <ListItem>
                <ListItemText 
                    primaryTypographyProps={{
                        variant: "h6"
                    }}
                    primary={t("danger_zone.clear_all.title", { ns: Namespace.PARTNERS })}
                    secondary={t("danger_zone.clear_all.description", { ns: Namespace.PARTNERS })}
                    />
                <ListItemSecondaryAction>
                    <ActionButton
                        color="error"
                        loading={loading}
                        onClick={() => toggleConfirmDialog(true)}
                        size="compact"
                        >
                        {t("danger_zone.clear_all.button", { ns: Namespace.PARTNERS })}
                    </ActionButton>
                </ListItemSecondaryAction>
            </ListItem>

            <Dialog
                open={showConfirmDialog}
                onClose={() => toggleConfirmDialog(false)}>
                <DialogTitle>
                    {t("danger_zone.clear_all.title", { ns: Namespace.PARTNERS })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("danger_zone.clear_all.confirmation_description", { ns: Namespace.PARTNERS })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="secondary"
                        onClick={() => toggleConfirmDialog(false)}
                        size="small"
                        >
                        {t("cancel", { ns: Namespace.ACTIONS })}
                    </Button>
                    <Button
                        color="error"
                        onClick={handleClearClicked}
                        sx={{
                            fontWeight: "normal"
                        }}
                    >
                        {t("confirm", { ns: Namespace.ACTIONS })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}