import { Box, Card, CardContent, CardMedia } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import OnboardingCardHeader from './OnboardingCardHeader';
import OnboardingDisabledHeader from './OnboardingDisabledHeader';
import { OnboardingCardType } from './OnboardingSetupCard';

type OnboardingSetupDisabledProps = {
    type: OnboardingCardType;
    children: JSX.Element | JSX.Element[];
}

function OnboardingSetupDisabled(props: OnboardingSetupDisabledProps) {
    const { type, children } = props;

    // const x = useAppSelector(state => state);
    // const dispatch = useAppDispatch();

    return (
        <Box 
            sx={{
                maxWidth: 340,
                width: "100%",
                mt: 4,
                flexGrow: 1,
                borderRadius: 4,
                border: "2px dashed rgba(0, 0, 0, 0.4)",
                px: 4,
                py: 3,
            }}
            >
            <OnboardingDisabledHeader 
                type={type}
                />
            <Box>
                {children}
            </Box>
        </Box>
    );
}

export default OnboardingSetupDisabled;