import { useEffect } from "react";
import BoundingBoxPainter from "./BoundingBoxes/BoundingBoxPainter";
import BoundingBoxesLayer from "./BoundingBoxes/BoundingBoxesLayer";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { MediaOverlayActions } from "store/reducers/batches/media_overlay";
import BatchAIMasksOverlay from "./BatchAIMasksOverlay";
import { DEFAULT_ZOOM } from "controllers/batches";
import { Shortcut } from "store/reducers/preferences/validation_shortcuts";


type BatchDrawingOverlayProps = {
    canEditResults?: boolean;
    image: HTMLImageElement;
}

/**
 * Layers on top on the batch's media, 
 * displaying the bounding boxes and allowing to draw new ones
 */
export default function BatchDrawingOverlay({ canEditResults, image }: BatchDrawingOverlayProps) {

    const imageZoom = useAppSelector(state => state.batches.list.selectedBatch?.imageZoom) ?? DEFAULT_ZOOM;

    const selectedTrashType = useAppSelector(state => state.batches.mediaOverlay.selectedTrashType);

    const drawLastSelectedClassKey = useAppSelector(state => state.preferences.validation[Shortcut.REDRAW_LAST_CLASS]);

    const dispatch = useAppDispatch();

    const canvasDimensions = (image: HTMLImageElement) => {
        const initialAspectRatio = image.naturalWidth / image.naturalHeight;
        const currentAspectRatio = image.width / image.height;

        let realWidth = image.width;
        let realHeight = image.height;

        if (currentAspectRatio >= initialAspectRatio) { // width now larger, black bands on the sides
            realWidth = initialAspectRatio * realHeight;
        }
        else { // height now larger, black bands on top and bottom
            realHeight = realWidth / initialAspectRatio;
        }

        return {
            width: realWidth * imageZoom.zoom,
            height: realHeight * imageZoom.zoom,
            left: (image.width - realWidth) / 2  * imageZoom.zoom + imageZoom.transform.x,
            top: (image.height - realHeight) / 2 * imageZoom.zoom + imageZoom.transform.y,
        };
    }

    const canvasDims = image ? canvasDimensions(image) : { width: 0, height: 0 };


    /**
     * Handle keyboard shortcuts
     */
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === drawLastSelectedClassKey) { // select the previously selected class to draw again
            dispatch(MediaOverlayActions.selectLastTrashType());
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => { window.removeEventListener("keydown", handleKeyDown); };
    }, [drawLastSelectedClassKey]);

    return (
        <>
            {canEditResults && selectedTrashType && (
                /* Canvas to draw new bounding boxes */
                <BoundingBoxPainter
                    trashType={selectedTrashType}
                    {...canvasDims}
                />
            )}

            {/* Existing bounding boxes overlay */}
            <BoundingBoxesLayer
                canEditResults={canEditResults}
                {...canvasDims}
            />

            {/* AI masks overlay */}
            <BatchAIMasksOverlay
                {...canvasDims}
                />
        </>
    );
}