
import { Alert, Box, Divider, Typography, List, IconButton, darken, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import SelectBatchItemButton from './SelectBatchItemButton';
import SectionLoader from 'components/_include/SectionLoader';
import { useCallback } from 'react';
import { BatchesActions } from 'store/reducers/batches/list';
import { SortingMapActions, selectSortingPointByAddressKey } from 'store/reducers/batches/sorting_map';
import TrashColorSquare from 'components/_include/TrashColorSquare';
import { TrashType } from 'constants/trash';
import CloseIcon from '@mui/icons-material/Close';
import { DANGER_COLORS } from 'helpers/draw';

type PlaceBatchesListProps = {
    addressKey: string;
}

function PlaceBatchesList({ addressKey }: PlaceBatchesListProps) {

    const { t } = useTranslation([Namespace.GLOSSARY, Namespace.SNACKS, Namespace.WASTES]);

    const sortingPointsLoading = useAppSelector(state => state.partners.selected.loading || state.batches.sortingMap.loading);
    const sortingPointFeature = useAppSelector(state => selectSortingPointByAddressKey(state, addressKey));
    const addressStats = sortingPointFeature?.properties;
    const batchesIDs = addressStats?.batchesIDs;

    const batchesLoading = useAppSelector(state => state.batches.list.loading);

    const dispatch = useAppDispatch();

    /**
     * Handle click on a batch Item
     */
    const handleBatchClick = useCallback((batchID: string) => {
        // save in local cache to display link as "visited"
        localStorage.setItem(`visited-batch-${batchID}`, "1");

        // set search param to navigate to view
        dispatch(BatchesActions.selectBatch(batchID));
    }, [dispatch]);

    const handleBatchHover = useCallback((batchID: string) => {
        // set search param to navigate to view
        dispatch(BatchesActions.hoverBatch(batchID));
    }, [dispatch]);

    const handleBatchLeave = useCallback((batchID: string) => {
        // set search param to navigate to view
        dispatch(BatchesActions.leaveBatch(batchID));
    }, [dispatch]);

    const handleCloseClick = () => {
        dispatch(SortingMapActions.selectAddressKey(null));
    }

    if (!addressStats) {
        if (sortingPointsLoading) {
            return (
                <Box>
                    <SectionLoader />
                </Box>
            );
        }
        else {
            return (
                <Box
                    my={2}
                    px={3}
                >
                    <Alert variant="outlined" severity="error">
                        {t("invalid_link", { ns: Namespace.SNACKS })}
                    </Alert>
                </Box>
            );
        }
    }

    const { errors } = addressStats;

    const headerColor = DANGER_COLORS[addressStats.dangerLevel];

    return (
        <Box>
            <Box
                py={2}
                px={3}
                color="#ffffff"
                bgcolor={darken(headerColor, .2)}
            >
                {/* <Box> */}
                    <Box
                        mb={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Typography variant="h6" flexGrow={1}>
                            {t("sorting_error", { ns: Namespace.GLOSSARY, count: 2 })}
                        </Typography>
                        <IconButton
                            color="white"
                            onClick={handleCloseClick}
                            sx={{ flexShrink: 1 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        {Object.keys(errors).length > 0 ? (
                            Object.entries(errors).map(([trashType, count]) => (
                                count > 0 ? (
                                    <Typography
                                        key={trashType}
                                        variant="body2"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <TrashColorSquare
                                            key={`${trashType}`}
                                            trashType={trashType as TrashType}
                                        />
                                        <span style={{ marginLeft: ".5rem" }}>
                                            {`${t(trashType, { ns: Namespace.WASTES })}: ${count}`}
                                        </span>
                                    </Typography>
                                ) : null
                            ))
                        ) : ( // no sorting error
                            <Typography variant="body2">
                                {t("no_sorting_error", { ns: Namespace.GLOSSARY })}
                            </Typography>
                        )}
                    </Box>
                {/* </Box> */}
            </Box>

            <Divider />

            {batchesLoading ? (
                <SectionLoader />
            ) : (
                <List sx={{ py: 0 }}>
                    {batchesIDs?.map((batchID) => (
                        <SelectBatchItemButton
                            key={batchID}
                            batchID={batchID}
                            onClick={handleBatchClick}
                            onMouseEnter={handleBatchHover}
                            onMouseLeave={handleBatchLeave}
                        />
                    ))}
                </List>
            )}
        </Box>
    );
}

export default PlaceBatchesList;