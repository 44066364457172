import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import { ReportExportData } from "models/Missions";

export const useReportData = (reportsData: ReportExportData[]) => {
    const { t } = useTranslation([Namespace.MISSIONS, Namespace.ACTIONS]);

    return useMemo(() => 
        reportsData.map(row => ({
            ...row,
            inhabitantsSpoken: t(row.inhabitantsSpoken === true ? 'yes' : 'no', { ns: Namespace.ACTIONS }),
            communicationMethods: row.communicationMethods.map(method => 
                t(`communication_methods.${method}`, { ns: Namespace.MISSIONS })
            ),
            selectedTopics: row.selectedTopics.map(topic => 
                t(`discussed_topics.${topic}`, { ns: Namespace.MISSIONS })
            ),
            inhabitantsReceptive: row.inhabitantsReceptive ? t(`responsiveness.${row.inhabitantsReceptive}`, { ns: Namespace.MISSIONS }) : ''
        }))
    , [reportsData, t]);
}; 