import { useMemo, useState } from "react";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import SectionLoader from "components/_include/SectionLoader";
import MultiSelectDropdown from "components/_include/MultiSelectDropdown";
import { useSortingRules } from "hooks/hooks";
import { SearchParam } from "constants/urls";
import { TrashType } from "constants/trash";

export type Option = {
    label: string;
    value: string;
}

type OptionsSelectProps = {
    parentLoading: boolean;
    closeOnSelect?: boolean;
}

function ErrorsSelect({ parentLoading, closeOnSelect = false }: OptionsSelectProps) {
    const loading = parentLoading;

    const { displayedErrors } = useSortingRules();
    
    const theme = useTheme();
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.COMMONS, Namespace.GLOSSARY, Namespace.WASTES,]);

    const [searchParams, setSearchParams] = useSearchParams();

    // selected errors
    let selectedOptions = searchParams.getAll(SearchParam.SELECTED_ERRORS)
        .filter(option => displayedErrors.includes(option as TrashType));
    
    // menu status
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);

    const handleOptionSelected = (option: string) => {
        let updatedErrors: string[] = selectedOptions;
        // remove error
        if (selectedOptions.includes(option)) updatedErrors = updatedErrors.filter(e => e !== option);
        // add error
        else updatedErrors.push(option);

        // update URL search params
        searchParams.delete(SearchParam.SELECTED_ERRORS); // clean previous values
        for (let error of updatedErrors) {
            if (error !== "") searchParams.append(SearchParam.SELECTED_ERRORS, error); // add each new value
        }   
        if (!searchParams.has(SearchParam.SELECTED_ERRORS)) { // set empty errors param if none selected
            searchParams.set(SearchParam.SELECTED_ERRORS, "");
        }     
        setSearchParams(searchParams);

        // close menu
        if (closeOnSelect) handleClose();
    };

    const toggleAllOptions = (selected: boolean) => {
        let updatedErrors: string[] = [];

        if (selected) { // select all errors
            updatedErrors = [...displayedErrors];
        }

        // update URL search params
        searchParams.delete(SearchParam.SELECTED_ERRORS); // clean previous values
        for (let error of updatedErrors) {
            searchParams.append(SearchParam.SELECTED_ERRORS, error); // add each new value
        }      
        if (!searchParams.has(SearchParam.SELECTED_ERRORS)) { // set empty errors param if none selected
            searchParams.set(SearchParam.SELECTED_ERRORS, "");
        }     
        setSearchParams(searchParams);

        // close menu
        handleClose();
    };

    const getMenuLabel = () => {
        if (selectedOptions.length === 1) {
            if (selectedOptions[0] === "") return t("sorting_error", { ns: Namespace.GLOSSARY, count: 2 });
            else return t(selectedOptions[0], { ns: Namespace.WASTES });
        }
        return t("nb_selected", { ns: Namespace.COMMONS, count: selectedOptions.length });
    }

    const allOptionsSelected = selectedOptions.length === displayedErrors.length;

    const options: Option[] = useMemo(
        () => displayedErrors.map(error => ({
            label: t(error, { ns: Namespace.WASTES }), // using translation function for label
            value: error  // using error as value
        })), 
        [displayedErrors, t]
    );

    return (
        <Box
            display="inline-block"
            position="relative"
            sx={{
                pointerEvents: "all",
            }}
        >
            {loading && (
                <SectionLoader size={theme.spacing(3)} />
            )}
            <MultiSelectDropdown
                id="errors-select-menu"
                loading={loading}
                selectedOptions={selectedOptions}
                options={options}
                allOptionsSelected={allOptionsSelected}
                isOpen={isOpen}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleClose={handleClose}
                toggleAllOptions={toggleAllOptions}
                handleOptionsSelected={handleOptionSelected}
                getMenuLabel={getMenuLabel}
            />
        </Box>
    );
}

export default ErrorsSelect;