import { useEffect } from "react";

/**
 * This hook is used to run a callback when a component is mounted.
 * @param onMountCallback - The callback to run when the component is mounted.
 * @param onUnmountCallback - The callback to run when the component is unmounted.
 */
export const useOnMount = (onMountCallback: () => void, onUnmountCallback?: () => void) => useEffect(() => {
    onMountCallback();

    return () => {
        onUnmountCallback?.();
    };
}, []);