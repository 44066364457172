import { Box, Grid, Typography } from "@mui/material";
import SortingPointDataTable from "./SortingPointDataTable";
import { useTranslation } from "react-i18next";
import { Locale, Namespace } from "locales/translations";
import UploadSpreadSheetButton from "components/_include/Buttons/UploadSpreadSheetButton";
import ActionButton from "components/_include/Buttons/ActionButton";
import { handleDataParsed } from 'helpers/spreadsheet';
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { usePartnerID } from 'hooks/partners';
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { SensitizationAddresses } from "models/Missions";
import { selectFilteredSortingPoints } from "store/selectors";
import { getBatchKey } from "store/reducers/batches/places";
import MissionAddressesMethods from "controllers/sensitization_addresses";
import MissionAddressesFilters from "../MissionMap/MissionAddressesFilters";
import SortingMapPage from "components/Map/SortingMapPage";

export default function AddAddressesSection() {
    const { t, i18n: { language } } = useTranslation([Namespace.ACTIONS, Namespace.COMMONS, Namespace.MISSIONS]);

    const dispatch = useAppDispatch();

    const partnerID = usePartnerID();
    const { missionID } = useParams();
    const selectedRowsIds = useAppSelector((state) => state.missions.missionAddresses.selectedSortingPointsIds);

    const filteredPoints = useAppSelector(selectFilteredSortingPoints);

    const rows = useMemo(() => {
        return filteredPoints.map((sortingPoint) => {
            const addressKey = getBatchKey({
                hereID: sortingPoint.properties.hereID,
                address: sortingPoint.properties.address,
            });

            const batchesCount = sortingPoint.properties.batchesCount ?? 0;

            const addressData = {
                addressKey: addressKey,
                house: sortingPoint.properties.address.houseNumber,
                street: sortingPoint.properties.address.street,
                city: sortingPoint.properties.address.city,
                postalCode: sortingPoint.properties.address.postalCode,
                errorDetail: sortingPoint.properties.errors,
                errorsCount: sortingPoint.properties.errorsCount,
                lat: sortingPoint.geometry.coordinates[0],
                lng: sortingPoint.geometry.coordinates[1],
                batchesCount: batchesCount,
            };
            return addressData;
        });
    }, [filteredPoints]);

    const handleSpreadsheetData = async (data: any) => {
        if (partnerID) {
            await handleDataParsed(data, dispatch, partnerID, language as Locale);
        }
    };

    const handleAddAddress = useCallback(async () => {
        if (!missionID || !partnerID) {
            console.error('Mission ID or Partner ID is missing.');
            return;
        }

        // Map selectionModel IDs to corresponding addresses
        const selectedAddresses = selectedRowsIds
            .map((id) => rows.find((row) => row.addressKey === id))
            .filter(
                (addressData): addressData is typeof rows[number] => addressData !== undefined
            );

        if (selectedAddresses.length === 0) {
            // No addresses selected
            return;
        }

        // Prepare addresses without IDs (IDs will be generated in the controller)
        const addressesToAdd: Omit<SensitizationAddresses, 'ID'>[] = selectedAddresses.map((addressRowData) => ({
            address: {
                houseNumber: addressRowData.house,
                street: addressRowData.street,
                city: addressRowData.city,
                postalCode: addressRowData.postalCode,
            },
            addressKey: addressRowData.addressKey,
            errors: addressRowData.errorDetail,
            errorsCount: addressRowData.errorsCount,
            lat: addressRowData.lat,
            lng: addressRowData.lng,
            batchesCount: addressRowData.batchesCount,
            visited: false,
            fromExternalSource: false,
        }));

        // Call the new controller method
        dispatch(
            MissionAddressesMethods.addAddressesToMission(partnerID, missionID, addressesToAdd)
        );

    }, [missionID, partnerID, selectedRowsIds, rows, dispatch]);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" marginBottom={2} justifyContent="space-between" alignItems="center">
                <Typography variant="h3">
                    {t('add_mission_addresses', { ns: Namespace.MISSIONS })}
                </Typography>
                <Box display="flex" gap={2} flexShrink={0} alignItems="center">
                    <UploadSpreadSheetButton 
                        label={t("upload_refusals", { ns: Namespace.ACTIONS })}
                        onDataParsed={handleSpreadsheetData} 
                        variant="outlined"
                        sx={{
                            alignSelf: "stretch"
                        }}
                    />

                    <Typography variant="body1" color="rgba(32, 16, 16, 0.54)">
                        {t("or", { ns: Namespace.COMMONS })}
                    </Typography>

                    <ActionButton
                        color="secondary"
                        disabled={selectedRowsIds.length === 0}
                        onClick={handleAddAddress}
                    >
                        {selectedRowsIds.length === 0
                            ? t('select_an_address', { ns: Namespace.MISSIONS })
                            : t('add_addresses', {
                                ns: Namespace.MISSIONS,
                                count: selectedRowsIds.length,
                            })}
                    </ActionButton>
                </Box>
            </Box>

            <MissionAddressesFilters />

            <Grid container spacing={2}>
                <Grid item xs={12} lg={7}>
                    <SortingPointDataTable rows={rows} />
                </Grid>
                <Grid item xs={12} lg={5}>
                    <SortingMapPage showForMissions={true} />
                </Grid>
            </Grid>
        </Box>
    );
}