import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DialogHeaderProps {
    onClose: () => void;
}

const DialogHeader: React.FC<DialogHeaderProps> = ({ onClose }) => {
    return (
        <IconButton
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                zIndex: 1
            }}
            onClick={onClose}
            aria-label="close"
        >
            <CloseIcon />
        </IconButton>
    );
};

export default DialogHeader;