import SectionLoader from "../_include/SectionLoader";
import { Box, Container, ImageList, } from "@mui/material";
import BatchImageItem from "components/ManageBatches/BatchImageItem";
import BatchesFilters from "components/_include/Filters/BatchesFilters";
import UpdateBatchDialog from "./UpdateBatchDialog/UpdateBatchDialog";
import { useEffect, useState } from "react";
import { usePrevious } from "helpers/refs";
import { useAppSelector } from "hooks/hooks";
import { selectBatchesIds } from "store/reducers/batches/list";
import LoadMoreBatches from "components/_include/LoadMoreBatches";
import PageTitle from "components/_include/PageTitle";
import HideManyImagesButton from "./HideManyImagesButton";

type ManageBatchesProps = {

}

export const BATCH_IMAGE_HEIGHT = 164;
const LOADING_LIMIT = 50;

function ManageBatchesPage(props: ManageBatchesProps) {

    const batchesLoading = useAppSelector(state => state.batches.list.loading);
    const batchesIDs = useAppSelector(state => selectBatchesIds(state));
    const canLoadMore = useAppSelector(state => state.batches.list.next !== null);

    const [hasLoaded, setHasLoaded] = useState(false);

    const wasLoading = usePrevious(batchesLoading);
    useEffect(() => {
        if (!hasLoaded && wasLoading && !batchesLoading) {
            setHasLoaded(true);
        }
    }, [batchesLoading]);

    return (
        <Container
            sx={{
                // bgcolor: "#EFEFEF",
            }}
        >
            <PageTitle />
            <Box>
                <Box
                    position="sticky"
                    display="flex"
                    flexDirection="row"
                    top={0}
                    py={2}
                    zIndex={1000}
                    borderBottom="1px solid #fff"
                    sx={{
                        bgcolor: (theme) => theme.palette.background.default,
                    }}
                >
                    <BatchesFilters
                        key="manage"
                        loadingLimit={LOADING_LIMIT}
                    />

                    <HideManyImagesButton />
                </Box>

                {batchesLoading && (
                    <SectionLoader />
                )}

                {hasLoaded && (
                    <Box>
                        <ImageList cols={4} rowHeight={BATCH_IMAGE_HEIGHT}>
                            {batchesIDs.map(batchID => (
                                <BatchImageItem
                                    key={batchID}
                                    batchID={batchID}
                                />
                            ))}
                        </ImageList>

                        {canLoadMore && (
                            <LoadMoreBatches />
                        )}
                    </Box>
                )}
            </Box>

            <UpdateBatchDialog
                canEditResults={true}
            />
        </Container>
    );
};

export default ManageBatchesPage;
