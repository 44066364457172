import { ParsedToken } from "firebase/auth";
import { Locale } from "locales/translations";

/**
 * Permissions that a user can have across Camia services.
 */
export enum Permission {
    /** Read access to partner data */
    READ_PARTNER_DATA = "read_partner_data",
    /** Write access to partner data */
    WRITE_PARTNER_DATA = "write_partner_data",
    /** Manage partner users */
    MANAGE_PARTNER_USERS = "manage_partner_users",
    /** Read and write access to mission campaigns */
    MANAGE_CAMPAIGNS = "manage_campaigns",
    /** Read and write access to ambasador app assigned mission report*/
    GET_ASSIGNED_CAMPAIGNS = "get_assigned_campaigns",
    /** should receive the weekly reports*/
    GET_WEEKLY_REPORTS = "get_weekly_reports",
}

/** 
 * @deprecated List of possible roles for a user, 
 * managing their access throughout the Camia services. 
 */
export enum UserRole {
    /** Ficha admin with full rights. */
    ADMIN = "admin",
    /** @deprecated Single common account for a given partner. */
    PARTNER = "partner",
    /** Client with admin rights on the partner's account. */
    PARTNER_ADMIN = "partner_admin",
    /** Client with readonly rights on the partner's account. */
    PARTNER_MEMBER = "partner_member",
    /** Ambassador that can access to the partner's data. */
    AMBASSADOR = "ambassador",
};

/**
 * Maps legacy roles to new permissions for backward compatibility
 * Will be removed after full migration
 */
export const ROLE_TO_PERMISSIONS_MAP: Record<UserRole, Permission[]> = {
    [UserRole.ADMIN]: [
        Permission.READ_PARTNER_DATA,
        Permission.WRITE_PARTNER_DATA,
        Permission.MANAGE_PARTNER_USERS,
        Permission.GET_ASSIGNED_CAMPAIGNS,
        Permission.MANAGE_CAMPAIGNS
    ],
    [UserRole.PARTNER]: [
        Permission.READ_PARTNER_DATA,
        Permission.WRITE_PARTNER_DATA
    ],
    [UserRole.PARTNER_ADMIN]: [
        Permission.READ_PARTNER_DATA,
        Permission.WRITE_PARTNER_DATA,
        Permission.MANAGE_PARTNER_USERS,
        Permission.MANAGE_CAMPAIGNS,
        Permission.GET_WEEKLY_REPORTS
    ],
    [UserRole.PARTNER_MEMBER]: [
        Permission.READ_PARTNER_DATA,
        Permission.MANAGE_CAMPAIGNS,
        Permission.GET_WEEKLY_REPORTS
    ],
    [UserRole.AMBASSADOR]: [
        Permission.READ_PARTNER_DATA,
        Permission.GET_ASSIGNED_CAMPAIGNS,
        Permission.MANAGE_CAMPAIGNS
    ],
};


export type FirebaseUserClaims = ParsedToken & UserClaims;


/** Object presenting the access level of a user for each partner it has access to. */
export type PartnersAccess = {
    [partnerID: string]: UserRole;
}

/** Object presenting the permissions of a user for each partner it has access to. */
export type PartnerPermissions = {
    [partnerID: string]: Permission[];
}

/** 
 * Extra information stored in the Firebase auth user's custom claims. 
 */
export type UserClaims = {
    /** Preferred language of the user. */
    locale: Locale;
    /** Whether the user is a Ficha admin with full system access */
    isFichaAdmin: boolean;
    /** @deprecated Legacy role field, maintained for backward compatibility */
    role: UserRole;
    /** Permissions-based access control for partners */
    partnerPermissions?: PartnerPermissions;
}


export type UserData = {
    name: string;
    partnerPermissions: PartnerPermissions;
    partnerID: string;
    email: string;
}