import { Button } from "@mui/material";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/hooks";
import { ValidationShortcutsActions } from "store/reducers/preferences/validation_shortcuts";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import EditValidationShortcutsDialog from "./EditShortcutsDialog/EditValidationShortcutsDialog";

export default function ToggleValidationShortcutsDialogButton() {
    const { t } = useTranslation([Namespace.PREFERENCES]);

    const dispatch = useAppDispatch();

    return (
        <>
            <Button 
                variant="outlined" 
                color="secondary" 
                onClick={() => dispatch(ValidationShortcutsActions.toggleDialog(true))}
                startIcon={<SettingsIcon />}
                sx={{
                    width: "100%"
                }}
                disableFocusRipple
                >
                {t("shortcuts_preferences", { ns: Namespace.PREFERENCES })}
            </Button>

            <EditValidationShortcutsDialog />
        </>
    )
}