import FormatShapesIcon from '@mui/icons-material/FormatShapesRounded';
import ModelTrainingIcon from '@mui/icons-material/ModelTrainingRounded';
import ViewInArIcon from '@mui/icons-material/ViewInArRounded';
import { ButtonBase, ImageListItem, ImageListItemBar, Stack, Tooltip } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import { getPictureURL, StorageType } from "helpers/storage";
import { getDisplayedErrors } from "helpers/trash";
import { Namespace } from 'locales/translations';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useSortingRules } from "hooks/hooks";
import { BatchesActions, selectBatchById } from "store/reducers/batches/list";
import SectionLoader from '../_include/SectionLoader';
import SelectForDownloadCheckbox from "./SelectForDownloadCheckbox";

type ImageItemProps = {
    batchID: EntityId;
}

function ImageToAnnotateItem(props: ImageItemProps) {
    const { batchID } = props;

    const batch = useAppSelector(state => selectBatchById(state, batchID)!);

    const { errorsClasses, mergingMapping } = useSortingRules();

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.DATES, Namespace.IMAGES, Namespace.STATS, Namespace.WASTES]);

    const [imageURL, setImageURL] = useState<string | null>(null);

    useEffect(() => {
        getPictureURL(batch.imageURL, StorageType.BATCHES)
            .then(url => {
                setImageURL(url || "");
            });
    }, [batch.imageURL]);

    const getErrorsLabel = () => {
        const errors = getDisplayedErrors(errorsClasses, mergingMapping, batch.results);
        const errorsString = Object.entries(errors)
            .map(([error, count]) => `${t(error, { ns: Namespace.WASTES })}: ${count}`)
            .join(", ");
        return errorsString;
    }

    const handleImageClick = () => {
        dispatch(BatchesActions.selectBatch(batch.ID));
    }

    return (
        <ImageListItem>
            {(imageURL === null || batch.loading) && (
                <SectionLoader />
            )}
            {imageURL !== null && (
                <ButtonBase
                    onClick={handleImageClick}
                    sx={{
                        width: "100%",
                        height: "100%"
                    }}
                >
                    <img
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                        src={imageURL}
                        // alt={item.title}
                        loading="lazy"
                    />
                </ButtonBase>
            )}
            <ImageListItemBar
                title={t("datetime_full_seconds", { ns: Namespace.DATES, datetime: batch.timestamp })}
                subtitle={getErrorsLabel()}
                sx={{
                    "& .MuiImageListItemBar-subtitle": {
                        whiteSpace: "unset", // prevent wrapping of subtitle
                    }
                }}
                actionIcon={
                    <Stack
                        direction="row"
                        px={2}
                        spacing={2}
                    >
                        {batch.aiResultsOff && ( // AI results are different from manual results
                            <Tooltip
                                title={t("to_annotate.ai_results_off", { ns: Namespace.IMAGES })}
                            >
                                <ModelTrainingIcon
                                    sx={{
                                        color: "#ffffff",
                                    }}
                                />
                            </Tooltip>
                        )}
                        {batch.downloadedToAnnotate && ( // batch was downloaded for annotation
                            <Tooltip
                                title={t("to_annotate.downloaded_for_annotation", { ns: Namespace.IMAGES })}
                            >
                                <FormatShapesIcon
                                    sx={{
                                        color: "#ffffff",
                                    }}
                                />
                            </Tooltip>
                        )}
                        {batch.downloadedToAugment && ( // batch was downloaded for augmentation
                            <Tooltip
                                title={t("to_annotate.downloaded_for_augmentation", { ns: Namespace.IMAGES })}
                            >
                                <ViewInArIcon
                                    sx={{
                                        color: "#ffffff",
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Stack>
                }
            />

            <SelectForDownloadCheckbox 
                batchID={batchID.toString()} 
                partnerID={batch.partnerID}
                imageURL={batch.imageURL}
                />
        </ImageListItem >
    );
}

export default ImageToAnnotateItem;