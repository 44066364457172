import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { selectPartnerById } from 'store/reducers/partners/list';
import PartnerStatusMenu from './PartnerStatusMenu';
import { useEffect, useRef, useState } from 'react';
import PartnerStatusText from './PartnerStatusText';
import { PartnerStatus as PartnerStatusEnum } from 'models/Partner';
import PartnersController from 'controllers/partners';

type PartnerStatusProps = {
    partnerID: string | null;
    onChange?: (status: PartnerStatusEnum) => void;
}

function PartnerStatus({ partnerID, onChange }: PartnerStatusProps) {
    const [menuOpen, toggleMenu] = useState(false);

    const menuAnchor = useRef<HTMLElement>(null);

    const [status, setStatus] = useState(PartnerStatusEnum.ACTIVE);

    const partnerStatus = useAppSelector(state => partnerID ? selectPartnerById(state, partnerID)?.status : undefined);

    useEffect(() => {
        if (partnerStatus) setStatus(partnerStatus);
    }, [partnerStatus]);

    const dispatch = useAppDispatch();

    const onStatusSelected = async (status: PartnerStatusEnum) => {
        if (partnerID && status !== partnerStatus) { // existing partner
            await dispatch(PartnersController.update(partnerID, { status }));
        }
        else { // new partner
            setStatus(status);
        }
    };

    useEffect(() => {
        // parent callback
        if (onChange) onChange(status);
    }, [status]);

    return (
        <Box
            ref={menuAnchor}
            >
            <PartnerStatusText 
                status={status}
                onStartEdit={() => toggleMenu(true)}
                />

            <PartnerStatusMenu
                open={menuOpen}
                anchor={menuAnchor.current} 
                value={status} 
                onChange={(s) => onStatusSelected(s)}
                onClose={() => toggleMenu(false)}
                />
        </Box>
    );
}

export default PartnerStatus;