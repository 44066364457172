import { Grid, GridProps } from '@mui/material';
import CollectionsController from 'controllers/collections';
import SingleDateSelect from 'components/_include/Filters/SingleDateSelect';
import TruckSelect from 'components/_include/Filters/TruckSelect';
import { DbOrder, QueryFilter } from 'constants/types';
import { Timestamp } from 'firebase/firestore';
import { CollectionDbData } from 'models/Collection';
import moment from 'moment';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { usePartnerID } from 'hooks/partners';
import ClearCollectionsFiltersButton from './ClearCollectionsFiltersButton';
import { SearchParam } from 'constants/urls';

const FilterWrapper = (props: GridProps) => (
    <Grid
        item
        justifyContent="center"
        display="flex"
        {...props}
    />
);


type CollectionsFiltersProps = {

}

function CollectionsFilters({ }: CollectionsFiltersProps) {
    const partnerID = usePartnerID();

    const [searchParams] = useSearchParams();
    const truckID = searchParams.get(SearchParam.TRUCK_ID);
    const date = searchParams.get("date");

    const loading = useAppSelector(state => state.collections.list.loading);

    const wasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);

    const minDate = useAppSelector(state => state.trucks.selected.data?.firstCollection);
    const maxDate = useAppSelector(state => state.trucks.selected.data?.lastCollection);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!partnerID || !wasteStream) return;

        // load the latest 10 collections
        const sorting: DbOrder<CollectionDbData> = { fieldPath: "startAt", directionStr: "desc" };
        const loadLimit = 10; 

        let filters: QueryFilter<CollectionDbData>[] = [
            { fieldPath: "type", opStr: "==", value: wasteStream, }, // sortable waste collections only 
        ];

        if (date) { // only select given day collections
            const dayStart = Timestamp.fromDate(moment(date).startOf("day").toDate());
            const dayEnd = Timestamp.fromDate(moment(date).endOf("day").toDate());

            filters.push(
                { fieldPath: "startAt", opStr: ">=", value: dayStart, },
                { fieldPath: "startAt", opStr: "<", value: dayEnd, },
            );
        }
        
        if (truckID) { // filter by truck
            dispatch(CollectionsController.list(partnerID, truckID, filters, sorting, loadLimit));
        }
        else { // all trucks together
            dispatch(CollectionsController.listAll(partnerID, filters, sorting, loadLimit));
        }
    }, [partnerID, truckID, date, wasteStream]);

    return (
        <Grid
            container
            display="flex"
            columnSpacing={1}
            mx={3}
            mb={2}
            >
            <FilterWrapper>
                <TruckSelect parentLoading={loading} />
            </FilterWrapper>

            <FilterWrapper>
                <SingleDateSelect 
                    parentLoading={loading} 
                    minDate={minDate ? moment(minDate).toDate() : undefined}
                    maxDate={maxDate ? moment(maxDate).toDate() : moment().toDate()}
                    />
            </FilterWrapper>

            <FilterWrapper>
                <ClearCollectionsFiltersButton />
            </FilterWrapper>
        </Grid>
    );
}

export default CollectionsFilters;