import { GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import ClusteringLevelSwitch from "./ClusteringLevelSwitch";
import { Box } from "@mui/material";
import { Locale, Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";

export default function AddressesExplorerToolbar() {
    const { t, i18n: { language } } = useTranslation([Namespace.ACTIONS]);

    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{
                    delimiter: language === Locale.ENGLISH ? "," : ";",
                    utf8WithBom: true,
                }}
                printOptions={{ disableToolbarButton: true }}
            />
            <Box>
                <ClusteringLevelSwitch />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <GridToolbarQuickFilter placeholder={t("quick_search", { ns: Namespace.ACTIONS })} />
        </GridToolbarContainer>
    )
}