import SectionLoader from "components/_include/SectionLoader";
import { useRef } from "react";
import { useAppSelector } from "hooks/hooks";
import SortingMap from "./SortingMap";
import { Box } from "@mui/material";
import { DRAWER_WIDTH } from 'constants/theme';

type SortingMapProps = {
    showForMissions: boolean;
}

function SortingMapLayout({ showForMissions }: SortingMapProps) {
    const loading = useAppSelector(state => state.batches.sortingMap.loading);

    const mapWrapperRef = useRef<HTMLDivElement>(null);

    return (
        <Box 
            ref={mapWrapperRef} 
            height='100%'
            width={showForMissions ? '100%' : `calc(100% + ${DRAWER_WIDTH}px)`}
            left={ showForMissions ? 0: -DRAWER_WIDTH}
            position='relative'
            >
            {loading && (
                <SectionLoader />
            )}
            <SortingMap
                showForMissions={showForMissions}
                width={mapWrapperRef.current?.offsetWidth}
                height={mapWrapperRef.current?.offsetHeight}
                />
        </Box>
    );
}

export default SortingMapLayout;