import { useEffect, useState } from "react";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import TimeSelect from "../../../../_include/Filters/TimeSelect/TimeSelect";
import { Box, IconButton } from "@mui/material";
import { ArrowDownward, Delete } from "@mui/icons-material";
import { Weekday } from "models/CollectionSchedule";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SelectedCollectionScheduleActions } from "store/reducers/collections_schedules/selected";

type CollectionTimeRangeSelectProps = {
    weekday: Weekday;
}

function CollectionTimesRangeSelect({ weekday }: CollectionTimeRangeSelectProps) {

    const loading = useAppSelector(state => state.collectionsSchedules.selected.loading);

    const { t } = useTranslation([Namespace.COMMONS,]);

    const times = useAppSelector(state => {
        const editingCalendar = state.collectionsSchedules.selected.editingCalendar;
        return editingCalendar ? editingCalendar[weekday] : undefined;
    });
    const start = times?.start;
    const end = times?.end;

    const dispatch = useAppDispatch();

    /** Update the value in the Redux store when times change */
    const updateWeekday = (s: string, e: string) => dispatch(SelectedCollectionScheduleActions.setWeekday({ weekday, times: { start: s, end: e }}));
    const handleChange = (value: string, position: "start" | "end") => {
        if (position === "start") {
            updateWeekday(value, end!);
        } else {
            updateWeekday(start!, value);
        }
    };

    /** Remove the entry from the Redux store when removed */
    const removeWeekday = () => dispatch(SelectedCollectionScheduleActions.removeWeekday(weekday));

    const [rangeValue, setRangeValue] = useState<[string, string]>([times?.start ?? "", times?.end ?? ""]);

    // handle automatic changes in the search params
    useEffect(() => {
        if (start !== rangeValue[0] || end !== rangeValue[1]) {
            setRangeValue([start ?? "00:00", end ?? "23:59"]);
        }
    }, [start, end]);

    return (
        <Box
            display="flex"
            alignItems="center"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1}
            >
                <TimeSelect
                    label={t("start_time", { ns: Namespace.COMMONS })}
                    value={rangeValue[0]}
                    onChange={(v) => handleChange(v, "start")}
                    loading={loading}
                />
                <ArrowDownward />
                <TimeSelect
                    label={t("end_time", { ns: Namespace.COMMONS })}
                    value={rangeValue[1]}
                    onChange={(v) => handleChange(v, "end")}
                    loading={loading}
                />
            </Box>
            <IconButton onClick={removeWeekday} aria-label="delete">
                <Delete />
            </IconButton>
        </Box>

    );
}

export default CollectionTimesRangeSelect;