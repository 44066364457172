import { useAppDispatch } from "hooks/hooks";
import { usePartnerID } from 'hooks/partners';
import CollaboratorsController from "controllers/collaborators";
import { NewCollaborator } from "models/Collaborator";
import CollaboratorDialog from "../CollaboratorDialog";

export default function AddCollaboratorDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
    const dispatch = useAppDispatch();
    const partnerID = usePartnerID();

    const handleSubmit = (data: NewCollaborator) => {
        if (!partnerID) return;
        dispatch(CollaboratorsController.invite(partnerID, data)).then((collaborator: any) => {
            if (collaborator) {
                onClose();
            }
        });
    };

    return <CollaboratorDialog open={open} onClose={onClose} onSubmit={handleSubmit} />;
}