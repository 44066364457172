import { Box, Stack } from '@mui/material';
import AreasMethods from 'controllers/areas';
import Area from 'models/Area';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { selectAreasIds } from 'store/reducers/areas/list';
import AreaCard from './AreaCard/AreaCard';
import { SearchParam } from 'constants/urls';

type AreasListProps = {

}

function AreasList({ }: AreasListProps) {
    const areasIDs = useAppSelector(selectAreasIds);

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useAppDispatch();

    const handleCardClicked = useCallback((area: Area) => {
        dispatch(AreasMethods.select(area));

        searchParams.set(SearchParam.AREA_ID, area.ID);
        setSearchParams(searchParams);
    }, [areasIDs]);

    return (
        <Box 
            width={theme => theme.spacing(32)}
            overflow="scroll"
            pr={2}
            sx={{ 
                pointerEvents: "all",
            }}
            >
            {areasIDs.map(areaID => (
                <AreaCard
                    key={areaID}
                    areaID={areaID}
                    onClick={handleCardClicked}
                    />
            ))}
        </Box>
    );
}

export default AreasList;