import { Container, Typography } from "@mui/material";
import PageTitle from "components/_include/PageTitle";
import { ExplorerDataGrid } from "./ExplorerDataGrid";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import ExplorerFiltersAndActions from "./FiltersAndActions/ExplorerFiltersAndActions";
import { useAppDispatch } from "hooks/hooks";
import { BatchesActions } from "store/reducers/batches/list";
import { useOnMount } from "hooks/layout";
import { useOnPartnerChange } from "hooks/partners";

/**
 * Page presenting raw data to the user, with possibility to filter, search and export.
 */
function ExplorerPage() {

    const { t } = useTranslation([Namespace.STATS]);

    const dispatch = useAppDispatch();

    const cleanupBatches = () => {
        dispatch(BatchesActions.resetList());
    };

    /**
     * Cleanup batches when the page is mounted and unmounted.
     */
    useOnMount(cleanupBatches, cleanupBatches);

    /**
     * Cleanup batches when the selected partner changes.
     */
    useOnPartnerChange(cleanupBatches);

    return (
        <Container
            maxWidth="xl"
        >
            <PageTitle />

            <Typography 
                variant="subtitle1"
                fontStyle="italic"
                color="rgba(0,0,0,0.5)"
                marginBottom={2}
                >
                {t("explorer_intro", { ns: Namespace.STATS })}
            </Typography>

            <ExplorerFiltersAndActions />

            <ExplorerDataGrid />
        </Container>
    );
};

export default ExplorerPage;
