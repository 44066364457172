import { useAppSelector } from "hooks/hooks";
import { AddressStatsPointFeature } from "helpers/geo";
import { getBatchKey } from "store/reducers/batches/places";
import MissionMapMarker from "./MissionMapMarker";
import MissionAddressesMethods from "controllers/sensitization_addresses";

type MissionSvgSortingMarkerProps = {
    point: AddressStatsPointFeature;
    onClick: (addressKey: string) => void;
};

/**
 * Marker displayed on the map representing an address that can be selected for a mission.
 */
export default function MissionSortingPointMarker({ point, onClick }: MissionSvgSortingMarkerProps) {
    const addressKey = getBatchKey(point.properties);
    const hovered = useAppSelector(state => state.batches.sortingMap.hoveredAddressKey === addressKey);
    const selectedRowsIds = useAppSelector((state) => state.missions.missionAddresses.selectedSortingPointsIds);
    const [lat, lng] = point.geometry.coordinates;
    const isSelected = selectedRowsIds.includes(addressKey);

    return (
        <MissionMapMarker 
            addressKey={addressKey} 
            lat={lat} 
            lng={lng} 
            highlighted={hovered}
            status={MissionAddressesMethods.getAddressStatus(false, isSelected, false)} 
            onClick={() => onClick(addressKey)} 
            />
    );
}
