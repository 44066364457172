import SwitchLabel from "components/_include/Switch/SwitchLabel";
import { getWasteStreamFontColor } from "constants/theme";
import { Namespace } from "locales/translations";
import { CollectionType } from "models/Collection"
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/hooks";

type LabelProps = {
    wasteStream: CollectionType;
    onClick: (wasteStream: CollectionType) => void;
}

export default function WasteStreamLabel({ wasteStream, onClick }: LabelProps) {
    const { t } = useTranslation([Namespace.GLOSSARY]);

    const selectedWasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);

    const isSelected = selectedWasteStream === wasteStream;

    return (
        <SwitchLabel
            label={t(`waste_stream.${wasteStream}`, { ns: Namespace.GLOSSARY, context: "short" })}
            color={getWasteStreamFontColor(selectedWasteStream)}
            selected={isSelected}
            onClick={() => onClick(wasteStream)}
        />
    )
}