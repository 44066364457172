import { LoadingButton } from "@mui/lab";
import { Button, DialogActions } from "@mui/material";
import CollectionsSchedulesMethods from "controllers/collections_schedules";
import { Timestamp } from "firebase/firestore";
import { Namespace } from "locales/translations";
import { CollectionScheduleDbData } from "models/CollectionSchedule";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { usePartnerID } from 'hooks/partners';

type ActionsProps = {
    onClose: () => void;
}

export default function SaveScheduleActions({ onClose }: ActionsProps) {

    const { t } = useTranslation([Namespace.ACTIONS]);

    const partnerID = usePartnerID();

    const loading = useAppSelector(state => state.collectionsSchedules.selected.loading);

    const existingSchedule = useAppSelector(state => state.collectionsSchedules.selected.data);

    const truck = useAppSelector(state => state.collectionsSchedules.selected.editingTruck);
    const route = useAppSelector(state => state.collectionsSchedules.selected.editingRoute);
    const calendar = useAppSelector(state => state.collectionsSchedules.selected.editingCalendar);
    const frequency = useAppSelector(state => state.collectionsSchedules.selected.editingFrequency);

    const dispatch = useAppDispatch();

    /** Handle click on save by creating schedule if it's a new one, or updating existing one */
    const handleSaveScheduleClick = () => {
        if (!partnerID || !truck || !route || !calendar) return null;

        const saveData = async () => {
            const frequencyProps: Pick<CollectionScheduleDbData, "frequency"> = frequency ? {
                frequency: {
                    ...frequency,
                    start: Timestamp.fromMillis(frequency.start),
                }
            } : {};
    
            if (!existingSchedule) { // create new schedule
                return dispatch(CollectionsSchedulesMethods.create(partnerID, {
                    label: `${truck.identificationNumber} - ${route.label}`,
                    truck,
                    route,
                    weekdays: Object.keys(calendar).map(day => Number(day)),
                    calendar,
                    ...frequencyProps,
                }));
            }
            else { // update existing schedule
                return dispatch(CollectionsSchedulesMethods.update(existingSchedule, {
                    label: `${truck.identificationNumber} - ${route.label}`,
                    truck,
                    route,
                    weekdays: Object.keys(calendar).map(day => Number(day)),
                    calendar,
                    ...frequencyProps,
                }))
            }
        }

        saveData()
            .then(schedule => {
                if (schedule) { // schedule successfully added
                    onClose();
                    return true;
                }
                else { // something went wrong
                    return false;
                }
            });
    }

    return (
        <DialogActions>
            <Button
                sx={{
                    color: "#4F4F4F",
                }}
                onClick={onClose}
            >
                {t("cancel", { ns: Namespace.ACTIONS })}
            </Button>
            <LoadingButton
                color="success"
                onClick={handleSaveScheduleClick}
                loading={loading}
                disabled={!route || !truck || !calendar || !Object.keys(calendar).length}
            >
                <span>{t("save", { ns: Namespace.ACTIONS })}</span>
            </LoadingButton>
        </DialogActions>
    )
}