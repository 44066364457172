import { Box, Tooltip, Typography } from "@mui/material";
import { GridColumnHeaderParams } from "@mui/x-data-grid";
import InfoIcon from '@mui/icons-material/InfoOutlined';

export type ColumnTooltip = {
    tooltip?: string;
};

export type TooltipGridColumnHeaderParams = GridColumnHeaderParams & ColumnTooltip;

export default function ColumnHeaderWithTooltip({ tooltip, ...params }: TooltipGridColumnHeaderParams) {

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                maxWidth: "100%"
            }}
        >
            <Typography
                fontSize="0.875rem"
                fontWeight={500}
                sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    lineHeight: "normal",
                }}
            >
                {params.colDef.headerName ?? ""}
            </Typography>
            {tooltip && (
                <Tooltip title={tooltip}>
                    <InfoIcon sx={{ width: 16, fontSize: 16, color: 'rgba(0, 0, 0, 0.65)', cursor: 'help' }} />
                </Tooltip>
            )}
        </Box>
    );
}