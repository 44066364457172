import { FichaDataGrid } from "components/_include/DataGrid/FichaDataGrid";
import AddressesExplorerToolbar from "./AddressesExplorerToolbar/AddressesExplorerToolbar";
import { useAddressesExplorerColumns, useAddressesExplorerData } from "hooks/addressesExplorer";


export function AddressesExplorerDataGrid() {

    const columns = useAddressesExplorerColumns();

    const rows = useAddressesExplorerData();

    return (
        <FichaDataGrid
            columns={columns}
            rows={rows}
            disableColumnFilter={false}
            slots={{
                toolbar: AddressesExplorerToolbar,
            }}
        />
    )
}