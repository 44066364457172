import { Grid, Typography, FormControlLabel, Checkbox } from "@mui/material";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import { Namespace } from "locales/translations";
import { Permission, UserRole } from "models/User";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import { TFunction } from "i18next";


type RoleAndPermissionSelectorProps = {
    role: string;
    onRoleChange: (newRole: string) => void;
    selectedPermissions: Permission[];
    onPermissionChange: (permission: Permission, checked: boolean) => void;
    loading: boolean;
}

function getPermissionLabel(permission: Permission, t: TFunction): string {
    switch (permission) {
        case Permission.READ_PARTNER_DATA:
            return t("permissions.read_partner_data", { ns: Namespace.CONTACTS });
        case Permission.WRITE_PARTNER_DATA:
            return t("permissions.write_partner_data", { ns: Namespace.CONTACTS });
        case Permission.MANAGE_PARTNER_USERS:
            return t("permissions.manage_partner_users", { ns: Namespace.CONTACTS });
        case Permission.MANAGE_CAMPAIGNS:
            return t("permissions.manage_campaigns", { ns: Namespace.CONTACTS });
        case Permission.GET_ASSIGNED_CAMPAIGNS:
            return t("permissions.get_assigned_campaigns", { ns: Namespace.CONTACTS });
        case Permission.GET_WEEKLY_REPORTS:
            return t("permissions.get_weekly_reports", { ns: Namespace.CONTACTS });
        default:
            return permission;
    }
}

export function RoleAndPermissionSelector({
    role,
    onRoleChange,
    selectedPermissions,
    onPermissionChange,
    loading,
}: RoleAndPermissionSelectorProps) {
    const { t } = useTranslation([Namespace.CONTACTS, Namespace.FORMS]);

    const rolesOptions: Option[] = useMemo(
        () =>
            [UserRole.PARTNER_ADMIN, UserRole.PARTNER_MEMBER, UserRole.AMBASSADOR].map((r) => ({
                label: t(r, { ns: Namespace.CONTACTS }),
                value: r,
            })),
        [t]
    );

    return (
        <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">{t("permissions.title", { ns: Namespace.CONTACTS })}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="body1">{t("select_permissions_from_role", { ns: Namespace.CONTACTS })}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} display="flex" alignItems="center">
                <DropdownMenu
                    id="collaborator-role-select-menu"
                    title={role ? t(role, { ns: Namespace.CONTACTS }) : t("select.a_role", { ns: Namespace.FORMS })}
                    loading={loading}
                    values={rolesOptions}
                    defaultValue={role}
                    startIcon={<AssignmentIndRoundedIcon />}
                    onChange={onRoleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">{t("or_select_permissions_one_by_one", { ns: Namespace.CONTACTS })}</Typography>
            </Grid>
            {Object.values(Permission).map((permission) => (
                <Grid item xs={12} sm={6} key={permission}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedPermissions.includes(permission)}
                                onChange={(e) => {
                                    onPermissionChange(permission, e.target.checked);
                                }}
                                color={permission === Permission.READ_PARTNER_DATA ? "secondary": "primary"}
                                disabled={loading || permission === Permission.READ_PARTNER_DATA} // all collaborators can read partner data
                            />
                        }
                        label={getPermissionLabel(permission, t)}
                    />
                </Grid>
            ))}
        </Grid>
    );
}