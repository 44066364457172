import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useAppSelector } from "hooks/hooks";


/** Get the ID of the currently selected partner. */
export const usePartnerID = () => useAppSelector(state => getAuth().currentUser === null ? undefined : state.partners.selected.data?.ID);

/**
 * Call the callback passed as parameter when the ID of the selected partner changes.
 */
export const useOnPartnerChange = (onPartnerChange: (partnerID: string | undefined) => void) => {
    const partnerID = usePartnerID();

    const [previousPartnerID, setPreviousPartnerID] = useState<string | undefined>(undefined);
    
    useEffect(() => {
        if (partnerID !== previousPartnerID) {
            setPreviousPartnerID(partnerID);
            onPartnerChange(partnerID);
        }
    }, [partnerID]);
}