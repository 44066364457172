import { Divider, Menu, MenuItem } from "@mui/material";
import { LESS_COMMON_ERRORS, MOST_COMMON_TRASH_TYPES, TRASH_TYPES_ICONS, TrashType } from "constants/trash";
import BoundingBoxesContextMenuItem from "./BoundingBoxesContextMenuItem";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useCallback } from "react";
import { MediaOverlayActions, selectAllResultsBboxes } from "store/reducers/batches/media_overlay";
import { changeBoundingBoxTrashType, deleteBoundingBox } from "models/BoundingBox";
import NestedMenuItem from "components/_include/NestedMenuItem";
import DeleteIcon from "@mui/icons-material/DeleteForeverRounded";
import HideIcon from "@mui/icons-material/VisibilityOffRounded";

export type ContextMenuPosition = {
    mouseX: number;
    mouseY: number;
}

type MenuProps = {
    position: ContextMenuPosition | null;
    onClose: () => void;
}

/**
 * Popover menu opening when right-clicking on a bounding box.
 * It offers several quick actions to interact with the bbox.
 */
export default function BoundingBoxesContextMenu({ position, onClose }: MenuProps) {

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.DIALOGS, Namespace.WASTES]);

    const selectedBoxID = useAppSelector(state => state.batches.mediaOverlay.selectedBoxID);
    const allBboxes = useAppSelector(selectAllResultsBboxes);
    const selectedBbox = allBboxes.find(bbox => bbox.ID === selectedBoxID);

    const open = Boolean(selectedBoxID) && position !== null;

    const imageWidth = useAppSelector(state => state.batches.mediaOverlay.imageWidth);
    const imageHeight = useAppSelector(state => state.batches.mediaOverlay.imageHeight);

    const dispatch = useAppDispatch();

    /**
     * Handle changing the type of a bounding box from context menu
     */
    const handleChangeBBoxTrashType = useCallback((newType: TrashType) => {
        if (selectedBbox) {
            dispatch(changeBoundingBoxTrashType(selectedBbox, newType, imageWidth, imageHeight));
        }
    }, [selectedBbox, imageWidth, imageHeight, dispatch]);


    /**
     * Handle clicking on "Hide" to hide the bounding box
     */
    const handleHideBbox = () => {
        if (selectedBoxID) {
            dispatch(MediaOverlayActions.updateOne({
                id: selectedBoxID,
                changes: { hidden: true },
            }));
        }
    }

    /**
     * Handle clicking on "Delete" to remove the bounding box
     */
    const handleDeleteBbox = () => {
        if (selectedBbox) {
            dispatch(deleteBoundingBox(selectedBbox, imageWidth, imageHeight));
        }
    }

    return (
        <Menu
            open={open}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={open ? {
                top: position.mouseY,
                left: position.mouseX
            } : undefined}
            sx={{
                // zIndex: theme => theme.zIndex.modal + 1,
            }}
        >
            <MenuItem
                key="change-class-optgroup"
                disabled
                sx={{
                    fontWeight: 600
                }}
            >
                {t("bounding_boxes.change_class_to", { ns: Namespace.DIALOGS })}
            </MenuItem>
            {MOST_COMMON_TRASH_TYPES
                .filter(type => type !== selectedBbox?.class)
                .map((trashType: TrashType) => (
                    <BoundingBoxesContextMenuItem
                        key={trashType}
                        value={trashType}
                        label={`${TRASH_TYPES_ICONS[trashType]} ${t(trashType, { ns: Namespace.WASTES, context: "small", count: 1 })}`}
                        onClick={(value: string) => handleChangeBBoxTrashType(value as TrashType)}
                    />
                ))}
            <Divider light />
            <NestedMenuItem
                label="Less common classes"
                sx={{
                    position: "relative",
                    gap: 1,
                }} 
                parentMenuOpen={open}                
            >
                {LESS_COMMON_ERRORS
                    .filter(type => type !== selectedBbox?.class)
                    .map((trashType: TrashType) => (
                        <BoundingBoxesContextMenuItem
                            key={trashType}
                            value={trashType}
                            label={`${TRASH_TYPES_ICONS[trashType]} ${t(trashType, { ns: Namespace.WASTES, context: "small", count: 1 })}`}
                            onClick={(value: string) => handleChangeBBoxTrashType(value as TrashType)}
                        />
                    ))}
            </NestedMenuItem>

            <Divider light sx={{ my: 1 }} />

            <MenuItem
                onClick={handleHideBbox}
                sx={{
                    color: theme => theme.palette.secondary.main,
                    gap: 1,
                }}
                >
                    <HideIcon fontSize="small" />
                    {t("hide", { ns: Namespace.ACTIONS })} 
            </MenuItem>

            <Divider light sx={{ my: 1 }} />

            <MenuItem
                onClick={handleDeleteBbox}
                sx={{
                    color: theme => theme.palette.error.main,
                    gap: 1,
                }}
                >
                    <DeleteIcon fontSize="small" />
                    {t("delete", { ns: Namespace.ACTIONS })} 
            </MenuItem>
        </Menu>
    );
}