import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import DocumentScannerIcon from '@mui/icons-material/DocumentScannerRounded';
import * as XLSX from 'xlsx';
import { showError } from 'store/reducers/snacks';
import { validateSpreadsheet } from 'helpers/spreadsheet';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { MissionSpreadsheetActions } from 'store/reducers/missions/addresses/spreadsheet';
import { LoadingButton } from '@mui/lab';
import ActionButton from './ActionButton';
import { SxProps } from '@mui/material';

interface UploadSpreadSheetButtonProps {
    label: string
    variant?: "outlined" | "contained";
    sx?: SxProps;
    onDataParsed: (data: {}[]) => void;
}

export default function UploadSpreadSheetButton({
    label, variant, sx,
    onDataParsed,
}: UploadSpreadSheetButtonProps) {

    const { t } = useTranslation([Namespace.ACTIONS]);
    const spreadsheetLoading = useAppSelector(state => state.missions.spreadSheetAddress.loadingSpreadsheet);
    const dispatch = useAppDispatch();


    const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(MissionSpreadsheetActions.startLoadingSpreadsheet());
        const selectedFiles = e.target.files;
        if (!selectedFiles || selectedFiles.length < 1) return;

        const file = selectedFiles[0];

        // read files content
        const reader = new FileReader();
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target?.result;
            const wb = XLSX.read(bstr, { type: 'binary' });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert Excel sheet to JSON */
            const data = XLSX.utils.sheet_to_json(ws, { raw: false }) as {}[];

            const validationErrorKey = validateSpreadsheet(data, file);
            if (validationErrorKey) {
                // Translate the error message using the key
                const translatedError = t(validationErrorKey, { ns: Namespace.FORMS });
                dispatch(showError(translatedError));
                dispatch(MissionSpreadsheetActions.stopLoadingSpreadsheet());
                return;
            }

            onDataParsed(data);
        };
        reader.readAsArrayBuffer(file);

        e.target.value = ''; // allow several uploads
    }

    const Button = variant === "outlined" ? LoadingButton : ActionButton;

    return (
        <Button
            color="secondary"
            component="label"
            startIcon={<DocumentScannerIcon />}
            loading={spreadsheetLoading}
            variant={variant}
            {...(sx && { sx })}
        >
            {label}
            <input type="file" style={{ display: "none" }} disabled={spreadsheetLoading} onChange={handleFileSelected} accept='.csv, .xlsx' />
        </Button>
    );
}