import { IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { ShortcutKey, ValidationShortcutsActions } from "store/reducers/preferences/validation_shortcuts"
import CloseIcon from "@mui/icons-material/CloseRounded";

type ButtonProps = {
    shortcut: ShortcutKey;
}

/**
 * A button displaying the current key associated to a shortcut.
 * Users can click on it to edit the key.
 */
export default function ClearShortcutButton({ shortcut }: ButtonProps) {

    const key = useAppSelector(state => state.preferences.validation[shortcut]);

    const dispatch = useAppDispatch();

    const removeShortcut = () => {
        dispatch(ValidationShortcutsActions.removeOne(shortcut));
    }

    if (!key) return <></>;

    return (
        <IconButton 
            onClick={removeShortcut}
            aria-label="remove" 
            sx={{
                width: theme => theme.spacing(2),
                height: theme => theme.spacing(2),
                bgcolor: "rgba(0,0,0,.1)",
                "&:hover": {
                    bgcolor: "rgba(0,0,0,.2)"
                }
            }}
            >
            <CloseIcon 
                sx={{ 
                   fontSize: 12, 
                   color: "rgba(0,0,0,.54)"
                }}
                />
        </IconButton>
    )
}