import AddIcon from '@mui/icons-material/AddRounded';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import TruckSortingExceptionsItem from './TruckSortingExceptionItem';
import { TrashType } from 'constants/trash';
import { useCallback, useMemo } from 'react';
import { SelectedTruckActions } from 'store/reducers/trucks/selected';

type TruckSortingExceptionsListProps = {

}

export default function TruckSortingExceptionsList({ }: TruckSortingExceptionsListProps) {

    const { t } = useTranslation([Namespace.FORMS]);

    const exceptions = useAppSelector(state => state.trucks.selected.exceptionsForm);

    const dispatch = useAppDispatch();

    /** Handle users selecting trash types for exceptions */
    const handleTypesChange = useCallback((index: number, fromType: TrashType | null, toType: TrashType | null, description: string) => {
        dispatch(SelectedTruckActions.updateExceptions({
            index,
            exception: [fromType, toType, description],
        }));
    }, [dispatch]);

    /** Handle users deleting an exception */
    const handleDelete = useCallback((index: number) => {
        dispatch(SelectedTruckActions.deleteException(index));
    }, [dispatch]);

    /** Handle clicking on "Add an exception" to register a new one */
    const addExceptionItem = () => {
        dispatch(SelectedTruckActions.updateExceptions({
            index: exceptions.length,
            exception: [null, null, ""],
        }));
    }

    const lastItem = exceptions[exceptions.length - 1];
    const addButtonDisabled = lastItem && (!lastItem[0] || !lastItem[1]);

    /** Prevent selecting trash types that are already part of an exception */
    const availableValues = useMemo(() => ({
        from: Object.values(TrashType).filter(type => { 
            // cannot mark an exception on detected errors if:
            // - already excepted
            // - another class is merged into it
            return !exceptions.some(([from, to]) => {
                return type === from || type === to;
            })
        }),
        to: Object.values(TrashType).filter(type => {
            // cannot mark an exception on detected errors if:
            // - already excepted
            return !exceptions.some(([from, _]) => {
                return type === from;
            })
        }),
    }), [exceptions]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={1}
        >
            <Typography variant="h5">
                {t("onboarding.trucks.exceptions.title", { ns: Namespace.FORMS })}
            </Typography>
            <Typography variant="body2">
                {t("onboarding.trucks.exceptions.explanation", { ns: Namespace.FORMS })}
            </Typography>

            <Stack direction="column">
                {exceptions.map(([fromType, toType, description], index) => (
                    <TruckSortingExceptionsItem
                        key={`${fromType}-${toType}`}
                        index={index}
                        fromType={fromType}
                        toType={toType}
                        description={description}
                        availableValues={availableValues}
                        onChange={handleTypesChange}
                        onDelete={handleDelete}
                    />
                ))}

                <Box textAlign="center" mt={1}>
                    <Button
                        variant="text"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={addExceptionItem}
                        disabled={addButtonDisabled}
                    >
                        {t("onboarding.trucks.exceptions.add", { ns: Namespace.FORMS })}
                    </Button>
                </Box>
            </Stack>
        </Box>
    );
}