import { Box } from "@mui/material";

export const GridContainer = ({ children }: { children: React.ReactNode }) => (
    <Box
        sx={{
            width: "100%",
            height: "100%",
            maxHeight: "calc(100vh - 300px)",
            '& .MuiDataGrid-root': {
                border: 'none',
                '& .MuiDataGrid-cell': {
                    py: 2,
                },
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'background.paper',
                    '& .MuiDataGrid-columnHeader': {
                        py: 2,
                        px: 2,
                        fontWeight: 'bold',
                    },
                },
            },
        }}
    >
        {children}
    </Box>
);