import { Grid, GridProps, TextField } from "@mui/material";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, useIsAdmin } from "hooks/hooks";
import { SelectedTruckActions } from "store/reducers/trucks/selected";

const GridItem = (props: GridProps) => (
    <Grid item xs={12} sm={6} md={4} {...props} />
)

/**
 * Inputs to enter general details about the Truck and the Camia sensor deployed on it
 */
export default function TrucksDetailsInputs() {
    const isAdmin = useIsAdmin();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS]);

    const identificationNumber = useAppSelector(state => state.trucks.selected.data?.identificationNumber);
    const licensePlate = useAppSelector(state => state.trucks.selected.data?.licensePlate);
    const teltonikaSerialNumber = useAppSelector(state => state.trucks.selected.data?.teltonikaSerialNumber);
    const make = useAppSelector(state => state.trucks.selected.data?.make);
    const sensorNumber = useAppSelector(state => state.trucks.selected.data?.sensorNumber);

    const dispatch = useAppDispatch();

    return (
        <Grid container spacing={2} >
            <GridItem>
                <TextField
                    margin="normal"
                    label={t("identification_number", { ns: Namespace.GLOSSARY })}
                    value={identificationNumber}
                    onChange={(event) => dispatch(SelectedTruckActions.update({ identificationNumber: event.currentTarget.value }))}
                />
            </GridItem>

            <GridItem>
                <TextField
                    margin="normal"
                    label={t("license_plate", { ns: Namespace.GLOSSARY })}
                    value={licensePlate}
                    onChange={(event) => dispatch(SelectedTruckActions.update({ licensePlate: event.currentTarget.value }))}
                />
            </GridItem>

            <GridItem>
                <TextField
                    margin="normal"
                    label={t("make", { ns: Namespace.GLOSSARY })}
                    value={make}
                    onChange={(event) => dispatch(SelectedTruckActions.update({ make: event.currentTarget.value }))}
                />
            </GridItem>

            {isAdmin && (
                <>
                    <GridItem>
                        <TextField
                            margin="normal"
                            label={t("manage_trucks.teltonikaSerialNumber", { ns: Namespace.FORMS })}
                            value={teltonikaSerialNumber}
                            onChange={(event) => dispatch(SelectedTruckActions.update({ teltonikaSerialNumber: event.currentTarget.value }))}
                        />
                    </GridItem>

                    <GridItem>
                        <TextField
                            margin="normal"
                            label={t("sensor_number", { ns: Namespace.GLOSSARY })}
                            value={sensorNumber}
                            onChange={(event) => dispatch(SelectedTruckActions.update({ sensorNumber: event.currentTarget.value }))}
                        />
                    </GridItem>
                </>
            )}
        </Grid>
    )
}