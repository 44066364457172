import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { usePartnerID } from 'hooks/partners';
import LoadingButton from '@mui/lab/LoadingButton';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import TrucksMethods from 'controllers/trucks';
import { selectTruckById } from 'store/reducers/trucks/list';
import { showSuccess } from 'store/reducers/snacks';

type AddTruckDialogProps = {
    open: boolean;
    onClose: () => void;
}

function AddTruckDialog({ open, onClose }: AddTruckDialogProps) {

    const partnerID = usePartnerID();

    const loading = useAppSelector(state => state.trucks.selected.loading);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS, Namespace.SNACKS]);

    const [identificationNumber, setIdentificationNumber] = useState("");
    const [make, setMake] = useState("");
    const [teltonikaSerialNumber, setTeltonikaSerialNumber] = useState("");
    const [sensorNumber, setSensorNumber] = useState("");

    const submitForm = () => {
        if (partnerID && teltonikaSerialNumber !== undefined) {  // Ensure teltonikaId is not undefined
            dispatch(TrucksMethods.create(partnerID, {
                identificationNumber: identificationNumber.trim(),
                make: make.trim(),
                teltonikaSerialNumber: teltonikaSerialNumber.trim(),
                sensorNumber: sensorNumber.trim(),
            }))
                .then((truck) => {
                    if (truck) { // display success message
                        dispatch(showSuccess(t("trucks.create.success", { ns: Namespace.SNACKS, truck: truck.identificationNumber })));
                    }
                    onClose();
                });
        }
    };

    const formIsValid = identificationNumber.length > 0 && make.length > 0;

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogIcon
                Icon={LocalShippingIcon}
            />
            <DialogTitle align='center'>
                {t("onboarding.trucks.add_truck", { ns: Namespace.FORMS })}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            label={t("identification_number", { ns: Namespace.GLOSSARY })}
                            value={identificationNumber}
                            onChange={(event) => setIdentificationNumber(event.currentTarget.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            label={t("manage_trucks.teltonikaSerialNumber", { ns: Namespace.FORMS })}
                            value={teltonikaSerialNumber}
                            onChange={(event) => setTeltonikaSerialNumber(event.currentTarget.value)}  
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            label={t("make", { ns: Namespace.GLOSSARY })}
                            value={make}
                            onChange={(event) => setMake(event.currentTarget.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            label={t("sensor_number", { ns: Namespace.GLOSSARY })}
                            value={sensorNumber}
                            onChange={(event) => setSensorNumber(event.currentTarget.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        color: "#4F4F4F",
                    }}
                    onClick={onClose}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <LoadingButton
                    color="success"
                    sx={{
                    }}
                    onClick={submitForm}
                    loading={loading}
                    disabled={!formIsValid}
                >
                    <span>{t("save", { ns: Namespace.ACTIONS })}</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AddTruckDialog;