import { Box, List, ListItem, Typography } from '@mui/material';
import CollectionsController from 'controllers/collections';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import ClearAllButton from './ClearAllButton';

export default function DangerZoneSection() {

    const { t } = useTranslation([Namespace.PARTNERS]);

    const loading = useAppSelector(state => state.collections.list.loading);

    const dispatch = useAppDispatch();

    const partner = useAppSelector(state => state.partners.selected.data);

    const handleClearClicked = () => {
        if (partner) dispatch(CollectionsController.markAllAsHousehold(partner.ID, partner.name));
    }

    return (
        <Box>
            <Typography variant="h3">{t("danger_zone.title", { ns: Namespace.PARTNERS })}</Typography>
            
            <List
                sx={{
                    border: theme => `1px solid ${theme.palette.error.main}`,
                    borderRadius: 2,
                    py: 1,
                }}
                >
                <ClearAllButton />
            </List>
        </Box>
    );
}