import { Dialog, DialogTitle } from '@mui/material';
import DownloadReportDialogContent from 'components/Statistics/DownloadReport/DownloadReportDialogContent';
import { Namespace } from 'locales/translations';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/hooks';

type DownloadReportDialogProps = {
    open: boolean;
    onClose: () => void;
}

function DownloadReportDialog({ open, onClose }: DownloadReportDialogProps) {

    const { t } = useTranslation([Namespace.DIALOGS]);

    const collection = useAppSelector(state => state.collections.selected.data);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                {t("download_report.title", {
                    ns: Namespace.DIALOGS,
                    collectionName: collection?.schedule.label,
                    collectionDate: moment(collection?.startAt),
                    context: "collection"
                })}
            </DialogTitle>

            <DownloadReportDialogContent parentPage="collection" />
        </Dialog>
    );
}

export default DownloadReportDialog;