import { Paper, Slide } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import PlaceBatchesList from './BatchesList/PlaceBatchesList';
import BatchDetailsBody from './BatchDetails/BatchDetailsBody';
import { SortingMapActions, selectSortingPointByAddressKey } from 'store/reducers/batches/sorting_map';
import { formatAddress } from 'helpers/geo';

type DetailsDrawerProps = {

}

function BatchesDrawer(props: DetailsDrawerProps) {
    const addressKey = useAppSelector(state => state.batches.sortingMap.selectedAddressKey);
    const selectedAddress = useAppSelector(state => {
        const sortingPoint = selectSortingPointByAddressKey(state, addressKey || "");
        if (!sortingPoint) return "";
        else return formatAddress(sortingPoint.properties.address);
    });
    const batchID = useAppSelector(state => state.batches.list.selectedBatch?.ID);

    const [open, setOpen] = useState(false);

    /** Handle open/close drawer when a sorting point is selected */
    useEffect(() => {
        if (addressKey) {
            setOpen(true);
        }
        else {
            handleClose();
        }
    }, [addressKey]);

    const dispatch = useAppDispatch();

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            dispatch(SortingMapActions.selectAddressKey(null));
        }, 300);
    }

    useEffect(() => {
    }, [addressKey]);

    return (
        <Slide
            direction='left'
            in={open}
            mountOnEnter
            unmountOnExit
        >
            <Paper
                sx={{
                    width: theme => theme.spacing(45),
                    borderRadius: (theme) => theme.spacing(.5),
                    overflowY: "scroll",
                    pointerEvents: "all",
                    position: "relative",
                    flexShrink: 0,
                }}
            >
                {batchID ? (
                    <BatchDetailsBody
                        placeAddress={selectedAddress}
                    />
                ) : (addressKey && (
                    <PlaceBatchesList
                        addressKey={addressKey}
                    />
                ))}
            </Paper>
        </Slide >
    );
}

export default BatchesDrawer;