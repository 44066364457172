import { Box, Divider, Switch, Typography } from "@mui/material";
import SwitchLabel from "components/_include/Switch/SwitchLabel";
import { TrashType } from "constants/trash";
import { SearchParam } from "constants/urls";
import BatchesController from "controllers/batches";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector, useSortingRules } from "hooks/hooks";
import { AddressesClusteringLevel, BatchesByAddressesActions } from "store/reducers/batches/by_addresses";

export default function ClusteringLevelSwitch() {

    const { t } = useTranslation([Namespace.EXPLORER]);

    const clusterByStreet = useAppSelector(state => state.batches.byAddresses.clusterBy === AddressesClusteringLevel.STREET);

    const { displayedErrors } = useSortingRules();
    const [searchParams] = useSearchParams();
    const errors = searchParams.getAll(SearchParam.SELECTED_ERRORS)
        .filter(e => displayedErrors
            .includes(e as TrashType)) as TrashType[];

    const areas = useAppSelector(state => state.areas.list.entities);

    const loading = useAppSelector(state => state.batches.byAddresses.loading || state.batches.list.loading);

    const dispatch = useAppDispatch();

    const setClusteringLevel = (level: AddressesClusteringLevel) => {
        dispatch(BatchesByAddressesActions.setClusteringLevel(level));
        dispatch(BatchesController.switchBatchesAggregationLevel(errors, level, areas));
    }

    const toggleClusteringLevel = () => {
        setClusteringLevel(
            clusterByStreet ? AddressesClusteringLevel.HOUSE_NUMBER : AddressesClusteringLevel.STREET
        );
    }

    return (
        <Box
            display="flex"
            alignItems="center"
        >
            <Divider
                orientation="vertical"
                flexItem
                sx={{
                    marginLeft: 1,
                    marginRight: 2
                }}
            />
            <Typography variant="h5" marginRight={2}>
                {t("clustering_level", { ns: Namespace.EXPLORER })}
            </Typography>
            <SwitchLabel
                label={t("house_number", { ns: Namespace.EXPLORER, context: "full" })}
                color="rgba(0,0,0,.7)"
                selected={!clusterByStreet}
                onClick={() => setClusteringLevel(AddressesClusteringLevel.HOUSE_NUMBER)}
            />
            <Switch
                disabled={loading}
                checked={clusterByStreet}
                onChange={toggleClusteringLevel}
                sx={{
                    '& .MuiSwitch-switchBase': {
                        color: theme => theme.palette.secondary.main,
                        '&.Mui-checked': {
                            '& + .MuiSwitch-track': {
                                opacity: 1,
                                backgroundColor: '#aab4be',
                            },
                        },
                    },
                    '& .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: '#aab4be',
                    },
                }}
            />
            <SwitchLabel
                label={t("street", { ns: Namespace.EXPLORER })}
                color="rgba(0,0,0,.7)"
                selected={clusterByStreet}
                onClick={() => setClusteringLevel(AddressesClusteringLevel.STREET)}
            />
        </Box>
    )
}