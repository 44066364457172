import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, TextField } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { selectCollectionScheduleByID } from 'store/reducers/collections_schedules/list';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CollectionsSchedulesMethods from 'controllers/collections_schedules';

type DeleteScheduleDialogProps = {
    scheduleID: string | null;
    onClose: () => void;
}

function DeleteScheduleDialog(props: DeleteScheduleDialogProps) {
    const { scheduleID, onClose } = props;

    const scheduleIdentifier = scheduleID || "";

    const schedule = useAppSelector(state => selectCollectionScheduleByID(state, scheduleIdentifier));
    const loading = useAppSelector(state => state.collectionsSchedules.list.loading);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS]);

    const handleConfirmClick = () => {
        if (schedule) {
            dispatch(CollectionsSchedulesMethods.delete(schedule.partnerID, schedule.ID))
                .then((success) => {
                    if (success) { // schedule successfully added
                        onClose();
                    }
                });
        }
    }

    return (
        <Dialog
            open={schedule !== undefined}
            onClose={onClose}
        >
            <DialogIcon
                Icon={CalendarMonthIcon}
            />
            <DialogTitle align='center'>
                {t("onboarding.schedules.delete_schedule", { ns: Namespace.FORMS })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("onboarding.schedules.delete_confirmation", { ns: Namespace.FORMS })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        color: "#4F4F4F",
                    }}
                    onClick={onClose}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <LoadingButton
                    color="error"
                    sx={{
                    }}
                    onClick={handleConfirmClick}
                    loading={loading}
                >
                    <span>{t("confirm", { ns: Namespace.ACTIONS })}</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteScheduleDialog;