import AreasMethods from "controllers/areas";
import SearchBar from "components/_include/Inputs/SearchBar";
import { Namespace } from "locales/translations";
import { OsmPolygon } from "models/Area";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { OsmAreasActions, selectAllOsmResults } from "store/reducers/areas/osm";

const MIN_SEARCH_LENGTH = 3;

export default function OsmSearchBar() {

    const { t } = useTranslation([Namespace.AREAS]);

    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.areas.osm.loading);
    const selectedOSMName = useAppSelector(state => state.areas.osm.selected?.name);
    const osmResults = useAppSelector(selectAllOsmResults); 

    const search = (searchText: string) => {
        if (searchText !== selectedOSMName) {
            if (searchText.length >= MIN_SEARCH_LENGTH) { // require at least 3 characters to make the search
                dispatch(AreasMethods.lookupOSMArea(searchText));
            }
        }
    };

    return (
        <SearchBar<OsmPolygon>
            value={selectedOSMName ?? ""}
            loading={loading}
            placeholder={t("search.placeholder", { ns: Namespace.AREAS })}
            autocompleteOptions={osmResults}
            getOptionLabel={(option) => `${option.name}, ${option.postcode}`}
            search={search}
            minSearchLength={MIN_SEARCH_LENGTH}
            onOptionSelected={value => {
                dispatch(OsmAreasActions.setSelected(value));
            }}
        />
    )
}