import { Timestamp } from "firebase/firestore";
import { TrashCount, TrashType } from "../constants/trash";

export type ClassificationException = {
    to: TrashType;
    description: string;
}

export enum TruckStatus {
    ACTIVE = "active",
    ARCHIVED = "archived",
}

export type TruckDbData = {
    /** Identifier of the truck in the partner's system */
    identificationNumber?: string;

    /** License plate number of the truck */
    licensePlate?: string;

    /** Brand of the truck (e.g. "Terberg", "Semat", "Faun"...) */
    make: string;

    /** Model of the truck */
    model?: string;

    /** Year the truck was produced */
    year?: number;    

    /** @deprecated ID of the registered dumpster equipping this truck */
    dumpsterID?: string;

    /** @deprecated Cumulated results for this truck */
    results?: TrashCount;

    /** Timestamp of the start of the first official collection for this truck with Ficha (after calibration) */
    firstCollection?: Timestamp;

    /** Timestamp of the start of the last collection for this truck with Ficha */
    lastCollection?: Timestamp;

    /** Unique identifier used to authenticate requests from this truck to Ficha's API */
    tokenID?: string;

    /** Serial number of the Teltonika's RUT device installed on the Camia sensor. Used to authenticate sending GPS coordinates. */
    teltonikaSerialNumber?: string;

    /** Number of the Camia sensor used internally by Ficha (format T0000) */
    sensorNumber?: string;

    /** Status of the truck (active or archived). */
    status: TruckStatus;

    /** 
     * Dictionary describing classes that should be saved differently by the AI for this truck 
     * @example 
     * ```
     * { [TrashType.GARBAGE_BAG]: { to: TrashType.RECYCLABLE_WASTE, description: "Garbage bags are allowed on this truck." } }
     * ```
     * means that the AI should saved the detected garbage bags as recyclable waste
     * */
    exceptions?: Partial<{ [key in TrashType]: ClassificationException }>;
}

type MutatedTruck = {
    ID: string;
    partnerID: string;
    displayID: string;
    firstCollection?: number;
    lastCollection?: number;
}

type Truck = Omit<TruckDbData, keyof MutatedTruck> & MutatedTruck;

export type NewTruckData = Pick<TruckDbData, "identificationNumber" | "make" | "teltonikaSerialNumber" | "sensorNumber">;

export default Truck;